import React, { useState, useRef } from 'react'
import BaseApp from '../BaseApp';
import TinyTextarea from '../TinyTextarea';
import axios from 'axios';
import Swal from 'sweetalert2';

function CreateBroadcast() {

   const heading = useRef({});
   const content = useRef({});
   const [type, setType] = useState(null);
   const [subject, setSubject] = useState(null);
   const [imageUrl, setImageUrl] = useState(null);
   const [linkEvent, setLinkEvent] = useState(null);

   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
         break;
   }

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      const AcceptAbleExtention = ["png", "jpeg", "jpg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            setImageUrl(resp.data.code);
         }).catch(error => {
            console.log(error.response)
         })
   }

   const submitBroadcast = () => {

      const dataHeading = heading.current.getContent();
      const dataContent = content.current.getContent();

      const data = { type, subject, dataHeading, dataContent, imageUrl, linkEvent }

      console.log(data);
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center mb-5">
                  <h3 className="mb-0">Create Broadcast</h3>
                  <button onClick={() => window.history.go(-1)} className="btn btn-sm btn-dark"><i className="bi bi-arrow-left-circle-fill mr-2"></i>Back to Broadcast</button>
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Type Broadcast</h6>
                  <select className="form-control" onChange={e => setType(e.target.value)}>
                     <option></option>
                     <option value="promotion">Promotion</option>
                     <option value="newsletter">Newsletter</option>
                     <option value="notification">Notification</option>
                     <option value="maintenance">Maintenance</option>
                  </select>
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Subject</h6>
                  <input type="text" className="form-control" onChange={e => setSubject(e.target.value)} />
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Heading</h6>
                  <TinyTextarea editorRef={heading} />
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Image</h6>
                  <input type="file" className="form-control" onChange={e => handleImageChange(e)} />
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Content</h6>
                  <TinyTextarea editorRef={content} />
               </div>
               <div className="form-group">
                  <h6 className="mb-2">Link Event</h6>
                  <input type="text" className="form-control" onChange={e => setLinkEvent(e.target.value)} />
               </div>
               <div className="d-flex justify-content-between mt-4">
                  <button className="btn btn-danger mr-2">Reset</button>
                  <button onClick={submitBroadcast} className="btn btn-success">Submit</button>
               </div>
            </div>
         </div>
      </BaseApp>
   )
}

export default CreateBroadcast
