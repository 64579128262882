import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Banner from '../components/banner/Banner';
import { Switch, Route, useRouteMatch, NavLink } from "react-router-dom";
import Category from '../components/category/Category';
import PremiumEvent from '../components/premium/PremiumEvent';
import Bank from '../components/bank/Bank';


function Website() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   let { path, url } = useRouteMatch();

   const [banners, setBanners] = useState(0);
   const [categories, setCategories] = useState(0);
   const [premiums, setPremiums] = useState(0);
   const [banks, setBanks] = useState(0);
   const [isLoading, setIsLoading] = useState(true);


   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/website')
            .then(resp => {
               // console.log(resp.data);
               setBanners(resp.data.banner);
               setCategories(resp.data.category);
               setPremiums(resp.data.premium);
               setBanks(resp.data.banks);
               setIsLoading(false);
            }).catch(error => {
               console.log(error);
            })
      }
      getData();
   }, []);


   return (
      <BaseApp>
         <Row>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <div className="block active">
                        <NavLink to={`${url}/banner`} activeClassName="active" className="block-content d-flex justify-content-between align-items-center">
                           <h4 className="mb-0">Banner</h4>
                           <span>{banners}</span>
                        </NavLink>
                     </div>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <div className="block">
                        <NavLink to={`${url}/category`} activeClassName="active" className="block-content d-flex justify-content-between align-items-center">
                           <h4 className="mb-0">Category</h4>
                           <span>{categories}</span>
                        </NavLink>
                     </div>
                  )
               }
            </Col>
         </Row>
         <Row className="mt-3">
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <div className="block">
                        <NavLink to={`${url}/event-premium`} activeClassName="active" className="block-content d-flex justify-content-between align-items-center">
                           <h4 className="mb-0">Premium Event</h4>
                           <span>{premiums}</span>
                        </NavLink>
                     </div>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <div className="block">
                        <NavLink to={`${url}/bank`} activeClassName="active" className="block-content d-flex justify-content-between align-items-center">
                           <h4 className="mb-0">Bank</h4>
                           <span>{banks}</span>
                        </NavLink>
                     </div>
                  )
               }
            </Col>
         </Row>

         <Switch>
            <Route path={`${path}/banner`}>
               <Banner />
            </Route>
            <Route path={`${path}/category`}>
               <Category />
            </Route>
            <Route path={`${path}/event-premium`}>
               <PremiumEvent />
            </Route>
            <Route path={`${path}/bank`}>
               <Bank />
            </Route>
         </Switch>


      </BaseApp>
   )
}

export default Website
