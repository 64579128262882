import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseApp from '../BaseApp';
import { Table } from 'react-bootstrap'
import Rupiah from '../Rupiah';
import Swal from 'sweetalert2';

function Reject() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/payment/status/reject')
            .then(resp => {
               console.log(resp.data);
               setData(resp.data);
               setIsLoading(false);
            }).catch(err => console.log(err.response));
      }

      getData();
   })

   const showImage = (url) => {
      Swal.fire({
         imageUrl: url.includes('firebase') ? url : process.env.REACT_APP_CDN_URL + '/get/' + url,
         imageWidth: 300,
         imageAlt: 'prof of payment',
      })
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <Table bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              <th>Invoice</th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Payment Type</th>
                              <th>Total Amount</th>
                              <th className="text-center">Image</th>
                              <th className="text-center" style={{ width: '180px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {data.length === 0 && (
                              <tr>
                                 <td colSpan="7" className="text-center">Belum ada Pembayaran</td>
                              </tr>
                           )}

                           {
                              data.map((p, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{p.oid}</td>
                                       <td>{p.contact.name}</td>
                                       <td>{p.contact.phone}</td>
                                       <td>
                                          {p.transaction.paymentType === "bank_transfer" ? `${p.transaction.bankName} - ${p.transaction.accountName}` : ''}

                                          {p.transaction.paymentType === "qris" ? `QRIS` : ''}
                                       </td>
                                       <td>Rp. <Rupiah value={p.payment.total} /></td>
                                       <td className="text-center">
                                          <button onClick={() => {
                                             showImage(p.profOfPayment);
                                          }} className="btn btn-sm btn-dark">
                                             <i className="bi bi-image"></i>
                                          </button>
                                       </td>
                                       <td className="text-center">
                                          <a href={`https://wa.me/${p.contact.phone.substring(1)}?text=Upload Bukti Bayar: https://tiketseminar.com/payment/pending?order_id=${p.oid}`} target="_blank" className="btn btn-success btn-sm text-white">Send WA</a>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>

      </BaseApp>
   )
}

export default Reject
