import React, { useEffect } from 'react';
import { useState } from 'react';
import { Table, Button, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import Swal from 'sweetalert2';

function PremiumEvent() {

   moment.locale('id')
   const [events, setEvents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [updated, setUpdated] = useState(true);
   const [showModal, setShowModal] = useState(false);
   const [idEvent, setIdEvent] = useState(null);
   const [basicEvents, setBasicEvents] = useState([]);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/event/premium')
            .then(resp => {
               // console.log(resp.data);
               setEvents(resp.data);
               setIsLoading(false);
            }).catch(err => {
               console.log(err);
            })
      }
      getData();
   }, [updated]);

   const removePremium = (id) => {
      Swal.fire({
         title: 'Remove this Event?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.put('/event/premium/' + id, { premium: false })
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Event Removed!',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdated(!updated);
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err.response.data)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   };

   const handleCloseModal = () => {
      setShowModal(false);
      setIdEvent(null);
   }

   const handleShowModal = async () => {
      setShowModal(true);
      setIdEvent(null);
      setIsLoading(true);

      return await axios.get('/event/basic')
         .then(resp => {
            console.log(resp.data);
            setBasicEvents(resp.data);
            setIsLoading(false);
         }).catch(err => {
            console.log(err.response.data);
         })

   }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (idEvent === null) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Error',
            text: "Event Required",
            showConfirmButton: false,
            timer: 1500
         });
      } else {
         Swal.fire({
            title: 'Please Waiting..',
            allowOutsideClick: false,
            didOpen: () => {
               Swal.showLoading()
            }
         });
         axios.put('/event/premium/' + idEvent, { premium: true })
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Event Added!',
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
               setShowModal(false);
            })
            .catch(err => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Error',
                  text: err.mesage,
                  showConfirmButton: false,
                  timer: 1500
               });
               console.log(err.response.data)
            });
      }
   }

   return (
      <>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Premium Event</h5>
               <div className="block-options">
                  <Button onClick={handleShowModal} size="sm" variant="success"><i className="bi bi-plus-circle-fill text-white mr-2"></i> Add Event</Button>
               </div>
            </div>
            <div className="block-content">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <Table bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              <th>Title Event</th>
                              <th>Author</th>
                              <th>Created At</th>
                              <th className="text-center" style={{ width: '150px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              events.map((e, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{e.title}</td>
                                       <td>{e.author.name} <i className="bi bi-exclamation-circle-fill text-danger ml-1"></i></td>
                                       <td>{moment(e.createdAt).format('LLLL')}</td>
                                       <td className="text-center">
                                          <Button onClick={() => removePremium(e._id)} variant="danger" size="sm">Remove Event</Button>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                           {
                              events.length === 0 && (
                                 <tr>
                                    <td colSpan="5" className="text-center">
                                       Belum ada Event Premium
                                    </td>
                                 </tr>
                              )
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>


         <Modal show={showModal} onHide={handleCloseModal}>
            <form onSubmit={handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title>Add Premium Event</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {
                     isLoading && (
                        <div className="text-center">
                           <Spinner animation="border" />
                        </div>
                     )
                  }
                  {
                     !isLoading && (
                        <div className="form-group">
                           <label>Select Event</label>
                           <select className="form-control form-control-sm" onChange={(e) => setIdEvent(e.target.value)}>
                              <option defaultValue="">-</option>
                              {
                                 basicEvents.map((e, i) => {
                                    return <option key={i} value={e._id}>{e.title} - {e.author.name}</option>
                                 })
                              }
                           </select>
                        </div>
                     )
                  }
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" size="sm" onClick={handleCloseModal}>
                     Close
                  </Button>
                  <Button variant="primary" size="sm" type="submit">
                     Add Event
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

      </>
   )
}

export default PremiumEvent
