import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp';
import { Table, Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

function Reminder() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true)
   const [modalAddReminder, setmodalAddReminder] = useState(false);
   const [eventId, setEventId] = useState(null);
   const [eventPublish, setEventPublish] = useState([]);
   const [events, setEvents] = useState([]);
   const [modalTesting, setModalTesting] = useState(false);
   const [nameTesting, setNameTesting] = useState(null);
   const [emailTesting, setEmailTesting] = useState(null);
   const [categoryTesting, setCategoryTesting] = useState(null);
   const [isOnlineTesting, setIsOnlineTesting] = useState(false);
   const [isOfflineTesting, setIsOfflineTesting] = useState(false);
   const [categories, setCategories] = useState([]);
   const [reminderId, setReminderId] = useState(null);

   useEffect(() => {
      const getReminder = () => {
         return axios.get('/reminder')
            .then(resp => {
               setIsLoading(false);
               setData(resp.data)
            })
            .catch(err => console.log(err.message));
      }

      const getEventPublish = () => {
         return axios.get('/event/publish')
            .then(resp => {
               // console.log(resp.data);
               setEventPublish(resp.data)
            })
            .catch(err => console.log(err.message));
      }

      const getEvent = () => {
         return axios.get('/event')
            .then(resp => {
               // console.log(resp.data);
               setEvents(resp.data)
            })
            .catch(err => console.log(err.message));
      }

      getReminder();
      getEventPublish();
      getEvent();
   }, [])

   const reminderNow = (id) => {
      Swal.fire({
         title: 'Remind this Event?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            setmodalAddReminder(false);
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.get('/reminder/event/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Reminder Success',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const deleteReminder = (id) => {

      Swal.fire({
         title: 'Delete this Event?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            setmodalAddReminder(false);
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/reminder/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const submitReminder = (e) => {
      e.preventDefault();
      axios.post('/reminder', { event: eventId, date: '' })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Created',
               showConfirmButton: false,
               timer: 1500
            })
            setTimeout(() => {
               document.location.reload();
            }, 1000);
         })
         .catch(err => console.log(err.message));
   }

   const submitTesting = (e) => {
      e.preventDefault();
      axios.post('/reminder/test/' + reminderId, { name: nameTesting, email: emailTesting, category: categoryTesting, isOnline: isOnlineTesting, isOffline: isOfflineTesting })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Send Email',
               showConfirmButton: false,
               timer: 1500
            })
            setTimeout(() => {
               document.location.reload();
            }, 1000);
         })
         .catch(err => console.log(err.message));
   }

   const testReminder = (reminderId, eventId) => {
      const event = events.find(e => e._id == eventId);
      if (event === undefined) return false;
      let cat = [];
      event.tickets.forEach(t => {
         cat.push(t.name);
      })
      setCategories(cat);
      setReminderId(reminderId);
      setModalTesting(true);
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Reminder Event</h4>
                  <button onClick={() => setmodalAddReminder(true)} className="btn btn-sm btn-success">Add Event</button>
               </div>
               <div className="form-group mt-3 mb-3">
                  <input type="text" className="form-control" />
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Event Name</th>
                        <th>Author</th>
                        <th style={{ width: '150px' }} className="text-center">Total Reminder</th>
                        <th className="text-center" style={{ width: '300px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>

                     {
                        isLoading && (
                           <tr>
                              <td colSpan="5" className="text-center">
                                 <Spinner animation="border" />
                              </td>
                           </tr>
                        )
                     }

                     {
                        !isLoading && (
                           <>
                              {
                                 data.length === 0 && (
                                    <tr>
                                       <td colSpan="5" className="text-center">Belum ada Event Reminder</td>
                                    </tr>
                                 )
                              }
                              {
                                 data.map((item, key) => {
                                    return (
                                       <tr key={key}>
                                          <td>{key + 1}</td>
                                          <td>{item.event.title}</td>
                                          <td>{item.event.author.name}</td>
                                          <td className="text-center">{item.count} x</td>
                                          <td className="text-center">
                                             <button onClick={() => testReminder(item._id, item.event._id)} className="btn btn-sm btn-dark mr-3">Test</button>
                                             <button onClick={() => reminderNow(item._id)} className="btn btn-sm btn-info mr-3">Reminder Now</button>
                                             <button onClick={() => deleteReminder(item._id)} className="btn btn-sm btn-danger">Delete</button>
                                          </td>
                                       </tr>
                                    )
                                 })
                              }
                           </>
                        )
                     }
                  </tbody>
               </Table>
            </div>
         </div>

         <Modal show={modalAddReminder} onHide={() => setmodalAddReminder(false)}>
            <form onSubmit={submitReminder}>
               <Modal.Header closeButton>
                  <Modal.Title>Create Reminder</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <select className="form-control" onChange={(e) => setEventId(e.target.value)}>
                        <option value="">-</option>
                        {
                           eventPublish.map((event, i) => {
                              return <option value={event._id} key={i}>{event.title}</option>
                           })
                        }
                     </select>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setmodalAddReminder(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

         <Modal show={modalTesting} onHide={() => setModalTesting(false)}>
            <form onSubmit={submitTesting}>
               <Modal.Header closeButton>
                  <Modal.Title>Testing Reminder</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>Name</label>
                     <input type="text" className="form-control" onChange={e => setNameTesting(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label>Email</label>
                     <input type="email" className="form-control" onChange={e => setEmailTesting(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label>Category Ticket</label>
                     <select className="form-control" onChange={e => setCategoryTesting(e.target.value)}>
                        <option value="">-</option>
                        {
                           categories.map((cat, i) => {
                              return <option value={cat} key={i}>{cat}</option>
                           })
                        }
                     </select>
                  </div>
                  <div className="form-group">
                     <label>Ticket Online</label>
                     <select className="form-control" onChange={e => {
                        if (e.target.value === "Yes") {
                           setIsOnlineTesting(true);
                        } else {
                           setIsOnlineTesting(false);
                        }
                     }}>
                        <option value="">-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                     </select>
                  </div>
                  <div className="form-group" onChange={e => {
                     if (e.target.value === "Yes") {
                        setIsOfflineTesting(true);
                     } else {
                        setIsOfflineTesting(false);
                     }
                  }}>
                     <label>Ticket Offline</label>
                     <select className="form-control">
                        <option value="">-</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                     </select>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setModalTesting(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

      </BaseApp>
   )
}

export default Reminder
