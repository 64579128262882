import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Rupiah from '../Rupiah';
import { Table } from 'react-bootstrap';
import BaseApp from '../BaseApp';
import Swal from 'sweetalert2';
import moment from 'moment';
import parse from "html-react-parser";

function DetailPayment() {

   let { id } = useParams();
   const [order, setOrder] = useState({});
   const [isLoading, setIsLoading] = useState(true);
   const [subTotal, setSubTotal] = useState(0);
   const [tickets, setTickets] = useState([]);

   useEffect(() => {
      const getData = async (id) => {
         return await axios.get('/order/detail/' + id)
            .then(resp => {
               setOrder(resp.data.order);
               let sub = 0;
               if (resp.data.order.payment.subtotal) {
                  sub = resp.data.order.payment.subtotal;
               } else {
                  resp.data.order.payment.items.forEach(item => {
                     let total = parseInt(item.quantity) * parseInt(item.price);
                     sub += total;
                  })
               }
               setSubTotal(sub);
               setIsLoading(false);
            }).catch(error => console.log(error.response))
      }
      function getTicket(id) {
         return axios.get('/ticket/order/' + id)
            .then(resp => {
               setTickets(resp.data);
            }).catch(error => console.log(error.response))
      }
      getTicket(id);
      getData(id);
   }, [id])

   const approve = (id) => {
      Swal.fire({
         title: 'Approve this Payment?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/backoffice/payment/' + id + '/approve')
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Order Paid',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setTimeout(() => {
                     window.location = "/payment";
                  }, 1000);
               }).catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     toast: true,
                     text: err.message,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const reject = (id) => {
      Swal.fire({
         title: 'Reject this Payment?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/backoffice/payment/' + id + '/reject')
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Payment Reject',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setTimeout(() => {
                     window.location = "/payment";
                  }, 1000);
               }).catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     toast: true,
                     text: err.message,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const showImage = (url) => {
      Swal.fire({
         imageUrl: url.includes('firebase') ? url : process.env.REACT_APP_CDN_URL + '/get/' + url,
         imageWidth: 300,
         imageAlt: 'prof of payment',
      })
   }

   const detailParticipant = (data) => {
      let html = "<hr />";
      for (const property in data) {
         if (data[property].includes("live-")) {
            html += `
            <p className="mb-0">${property}</p>
            <a href="${process.env.REACT_APP_CDN_URL + '/get/' + data[property]}" target="_blank" className="text-muted text-small mb-2">${data[property]}</a>
         `;
         } else {
            html += `
            <p className="mb-0">${property}</p>
            <p className="text-muted text-small mb-2">${data[property]}</p>
         `;
         }

      }
      return html;
   }

   return (
      <BaseApp>
         {
            !isLoading && (
               <>
                  <div className="block">
                     <div className="block-content">
                        <div className="d-flex justify-content-between mb-4">
                           <div className="left">
                              <h5>Tiketseminar</h5>
                              <p className="text-muted text-small mb-1">Jalan Bias No. 31, Bendolole<br />Sardonoharjo, Ngaglik, Sleman<br />DI Yogyakarta 55583</p>
                              <p className="text-muted text-small mb-0">cs@tiketseminar.com | +6281325162156</p>
                           </div>
                           <div className="text-right">
                              <h5>No Order #{order.oid}</h5>
                              <p className="text-muted text-small mb-0">{order.contact.name}</p>
                              <p className="text-muted text-small mb-0">{order.contact.phone}</p>
                              <p className="text-muted text-small mb-2">{order.contact.email}</p>
                              <p className="text-muted text-small mb-0">Order dibuat: {moment(order.createdAt).format('LLL')}</p>
                           </div>
                        </div>
                        <Table bordered>
                           <thead>
                              <tr>
                                 <th style={{ width: '25px' }}>#</th>
                                 {/* <th>Invoice</th> */}
                                 <th>Kategori Tiket</th>
                                 <th className="text-center" style={{ width: '100px' }}>Jumlah</th>
                                 <th className="text-center" style={{ width: '200px' }}>Harga</th>
                                 <th className="text-center" style={{ width: '200px' }}>Total</th>
                              </tr>
                           </thead>
                           <tbody>
                              {order.payment.items.map((item, key) => {
                                 return (
                                    <tr key={key}>
                                       <td>{key + 1}</td>
                                       <td>{item.name}</td>
                                       <td className="text-center">{item.quantity}</td>
                                       <td className="text-center">Rp. <Rupiah value={item.price} /></td>
                                       <td className="text-center">Rp. <Rupiah value={item.price * item.quantity} /></td>
                                    </tr>
                                 )
                              })}
                           </tbody>
                        </Table>
                        <div className="row mt-4">
                           <div className="col-12 col-md-6">
                              {/* <h5>Daftar Peserta Event</h5>
                              {(order.status === "complete" && order.isPaid) ? (
                                 <>
                                    <ul>
                                       {tickets.map((p, i) => {
                                          return (
                                             <li className="text-small" key={i}>{i + 1}. {p.name} <span style={{ margin: '0 7px' }}>|</span> {p.category}</li>
                                          )
                                       })}
                                    </ul>
                                 </>
                              ) : (
                                 <>
                                    <ul>
                                       {order.participants.map((p, i) => {
                                          return (
                                             <li className="text-small" key={i}>{i + 1}. {p.name}</li>
                                          )
                                       })}
                                    </ul>
                                 </>
                              )} */}
                           </div>
                           <div className="col-12 align-self-end col-md-6">
                              <div className="text-right">
                                 <p className="text-small mb-1">Sub Total: Rp. <Rupiah value={subTotal} /></p>
                                 <p className="text-small mb-1">Platform Fee: Rp. <Rupiah value={order.payment.fee} /></p>
                                 <p className="text-small mb-3">Kode Unik: Rp. <Rupiah value={Number.isInteger(order.payment.uniqueCode) ? order.payment.uniqueCode : parseInt(order.payment.uniqueCode[0])} /></p>
                                 {order.payment.voucher !== undefined && <p className="text-small mb-3">Voucher/Promo: - Rp. <Rupiah value={typeof order.payment.voucher === 'object' ? order.payment.voucher.amount : order.payment.voucher} /></p>}
                                 <h5 className="mb-0 mt-1">Total: Rp. <span style={{ fontWeight: 'bold' }}><Rupiah value={order.payment.total} /></span></h5>
                              </div>
                           </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                           <button onClick={() => window.location = "/payment"} className="btn btn-sm btn-secondary">Kembali</button>
                           <div>
                              <button onClick={() => {
                                 showImage(order.profOfPayment);
                              }} className="btn btn-sm btn-dark mr-4">
                                 <i className="bi bi-image mr-2"></i> Bukti Bayar
                              </button>
                              <button onClick={() => {
                                 approve(id)
                              }} className="btn btn-sm btn-success mr-2">Approve</button>
                              <button onClick={() => {
                                 reject(id)
                              }} className="btn btn-sm btn-danger">Reject</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="block mt-4">
                     <div className="block-content">
                        <h5>Daftar Peserta</h5>
                        {order.participants.map((p, i) => {
                           return (
                              <div key={i}>
                                 {parse(detailParticipant(p))}
                              </div>
                           )
                        })}

                     </div>
                  </div>
               </>

            )
         }
      </BaseApp>
   )
}

export default DetailPayment
