import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import BroadcastList from '../components/broadcast';
import CreateBroadcast from '../components/broadcast/create'

function Broadcast() {

   let { path } = useRouteMatch();

   return (
      <Switch>
         <Route exact path={`${path}`}>
            <BroadcastList />
         </Route>
         <Route exact path={`${path}/create`}>
            <CreateBroadcast />
         </Route>
      </Switch>
   )
}

export default Broadcast
