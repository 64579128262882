import axios from 'axios';
import React, { useState, useEffect } from 'react';
import BaseApp from "../components/BaseApp";

function Dashboard() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   const [summary, setSummary] = useState({ event: 0, ticket: 0, transaction: 0, account: 0 });
   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/dashboard')
            .then(resp => {
               setSummary(resp.data);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <div className="cards">
            <div className="card-single">
               <div>
                  <h1>{summary.event}</h1>
                  <span>Event</span>
               </div>
               <div>
                  <span><i className="bi bi-file-richtext-fill"></i></span>
               </div>
            </div>
            <div className="card-single">
               <div>
                  <h1>{summary.ticket}</h1>
                  <span>Ticket</span>
               </div>
               <div>
                  <span><i className="bi bi-phone-landscape-fill"></i></span>
               </div>
            </div>
            <div className="card-single">
               <div>
                  <h1>{summary.transaction}</h1>
                  <span>Transaction</span>
               </div>
               <div>
                  <span><i className="bi bi-cart-fill"></i></span>
               </div>
            </div>
            <div className="card-single">
               <div>
                  <h1>{summary.account}</h1>
                  <span>Account</span>
               </div>
               <div>
                  <span><i className="bi bi-people-fill"></i></span>
               </div>
            </div>
         </div>
      </BaseApp>
   )
}

export default Dashboard
