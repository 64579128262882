import React, { useEffect, useState } from 'react';
import BaseApp from '../components/BaseApp';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';

function Config() {

   let history = useHistory();
   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   moment.locale('id');
   const [insertConfigPayment, setInsertConfigPayment] = useState(true);
   const [idConfigPayment, setIdConfigPayment] = useState(null);
   const [configPayment, setConfigPayment] = useState({ platformFee: 0, paymentUniqueCode: 3, paymentMethodBankTransfer: false, paymentMethodVirtualAccount: false, paymentMethodEWallet: false, firstDigit: 5 });
   const [updated, setUpdated] = useState(false);
   const [modalInputRequirement, setModalInputRequirement] = useState(false);
   const [label, setLabel] = useState(null);
   const [type, setType] = useState(null);
   const [isFixed, setIsFixed] = useState(false);
   const [listInputRequirement, setListInputRequirement] = useState([]);
   const [listOption, setListOption] = useState([{ name: "" }]);

   const [maintenance, setMaintenance] = useState([]);
   const [modalMaintenance, setModalMaintenance] = useState(false);
   const [webName, setWebName] = useState(null);
   const [webDomain, setWebDomain] = useState(null);

   const [broadcastWa, setBroadcastWa] = useState([]);
   const [events, setEvents] = useState([]);
   const [event, setEvent] = useState({ id: null, label: null });


   const handleCloseInputModal = () => {
      setModalInputRequirement(false);
      setModalMaintenance(false);
   }

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/config')
            .then(resp => {
               // console.log(resp.data);
               let list = []; let web = [];
               resp.data.forEach(element => {
                  if (element.name === "payment") {
                     setIdConfigPayment(element._id);
                     setConfigPayment(element.value);
                     setInsertConfigPayment(false);
                  }

                  if (element.name === "event_input_requirement") {
                     list.push(element);
                  }

                  if (element.name === "website_maintenance") {
                     web.push(element);
                  }
               })
               setMaintenance(web)
               setListInputRequirement(list);
            }).catch(err => console.log(err.response));
      }

      const getListEventBroadcastWa = () => {
         axios.get('/backoffice/config/broadcast_whatsapp')
            .then(resp => {
               setBroadcastWa(resp.data);
            }).
            catch(err => console.log(err.response));
      }

      function getEvent() {
         axios.get('/event')
            .then(resp => {
               setEvents(resp.data);
            })
            .catch(error => console.log(error));
      }

      getEvent();
      getListEventBroadcastWa();
      getData();
   }, [updated]);

   const updateConfigPayment = (obj) => {
      Swal.fire({
         title: 'Please Waiting...',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });

      if (insertConfigPayment) {
         axios.post('/config', { name: "payment", value: configPayment })
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'Config Updated',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
               setInsertConfigPayment(false);
            }).catch(error => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  text: error.response,
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      } else {
         axios.put('/config/' + idConfigPayment, obj === undefined ? configPayment : obj)
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'Config Updated',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
            }).catch(error => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  text: error.response,
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      }
   }

   const submitInput = (e) => {
      e.preventDefault();

      let name = label.toLowerCase();

      if (label !== null && type !== null && name !== "") {

         let data;
         if (type === "radio" || type === "select") {
            data = { name: "event_input_requirement", value: { label, type, status: "active", name: name.split(' ').join('_'), isFixed, option: listOption } }
         } else {
            data = { name: "event_input_requirement", value: { label, type, status: "active", name: name.split(' ').join('_'), isFixed } }
         }

         axios.post('/config', data)
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'Config Created',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
               setModalInputRequirement(false);
            }).catch(error => {
               console.log(error.response)
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  text: error.response.data.message,
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      } else {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: "Please fill Label & Type",
            toast: true,
            showConfirmButton: false,
            timer: 1500
         });
      }
   }

   const inactiveInput = (label) => {
      axios.put('/config/input/status', { label: label, status: "inactive" })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const activeInput = (label) => {
      axios.put('/config/input/status', { label: label, status: "active" })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const deleteInput = (label) => {
      axios.delete('/config/input/' + label)
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Deleted',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            console.log(error.response.data)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const handleChangeOption = (i, value) => {
      let option = [...listOption];
      option[i]['name'] = value;
      setListOption(option);
   }

   const deleteOption = (i) => {
      let option = [...listOption];
      option.splice(i, 1);
      setListOption(option);
   }

   const submitWebMaintenance = (e) => {
      e.preventDefault();
      if (webName !== null && webDomain !== null) {
         let data = { name: "website_maintenance", value: { name: webName, domain: webDomain, active: false } }

         axios.post('/config', data)
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'Config Created',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
               setModalMaintenance(false);
            }).catch(error => {
               console.log(error.response)
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  text: error.response.data.message,
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      } else {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            text: 'Please Fill Website Name & URL',
            toast: true,
            showConfirmButton: false,
            timer: 1500
         });
      }
   }

   const enableMaintenance = (item) => {
      axios.put('/config/' + item._id, { ...item.value, active: true })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const disableMaintenance = (item) => {
      axios.put('/config/' + item._id, { ...item.value, active: false })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const submitEventList = () => {
      if (event.id == null && event.label == null) return alert("Select Event!");
      axios.post('/config', { name: "broadcast_whatsapp", value: event })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Created',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const deleteEventList = (i) => {
      axios.delete('/config/' + i)
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Config Deleted',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(error => {
            console.log(error.response.data)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }



   return (
      <BaseApp>
         <div className="block mt-4">
            <div className="block-content">
               <div className="row justify-content-center">
                  <div className="col-12 col-md-5">
                     <h4>Payment Configuration</h4>
                  </div>
                  <div className="col-12 col-md-7">
                     <h6 style={{ fontWeight: '500' }}>Platform Fee</h6>
                     <input className="form-control form-control-sm" type="number" onChange={(e) => setConfigPayment({ ...configPayment, platformFee: parseInt(e.target.value) })} value={parseInt(configPayment.platformFee)} />
                     <small className="form-text text-muted">*per transaction</small>
                     <button onClick={() => updateConfigPayment()} className="btn btn-sm btn-success mt-2">Update Fee</button>

                     <div className="dash"></div>

                     <div className="row">
                        <div className="col-12 col-md-6">
                           <h6 style={{ fontWeight: '500' }}>Payment Unique Code</h6>
                           <input className="form-control form-control-sm" type="number" value={configPayment.paymentUniqueCode} onChange={(e) => setConfigPayment({ ...configPayment, paymentUniqueCode: parseInt(e.target.value) })} />
                           <small className="form-text text-muted">*digit</small>
                           <button onClick={() => updateConfigPayment()} className="btn btn-sm btn-success mt-2">Update Code</button>
                        </div>
                        <div className="col-12 col-md-6">
                           <h6 style={{ fontWeight: '500' }}>Maximum Number First Digit</h6>
                           <input className="form-control form-control-sm" type="number" value={configPayment.firstDigit} onChange={(e) => setConfigPayment({ ...configPayment, firstDigit: parseInt(e.target.value) })} />
                           <small className="form-text text-muted">*first amount</small>
                           <button onClick={() => updateConfigPayment()} className="btn btn-sm btn-success mt-2">Update</button>
                        </div>
                     </div>

                     <div className="dash"></div>

                     <h6 style={{ fontWeight: '500' }}>Payment Method</h6>
                     <div className="d-flex align-items-center">
                        {
                           !configPayment.paymentMethodEWallet && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodEWallet: true })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        {
                           configPayment.paymentMethodEWallet && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodEWallet: false })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        <span className="ml-2" style={{ fontSize: '15px' }}>E-Wallet</span>
                        <span className="ml-2">
                           <i className="bi bi-arrow-up-right-square-fill"></i>
                        </span>
                     </div>

                     <div className="d-flex align-items-center">
                        {
                           !configPayment.paymentMethodVirtualAccount && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodVirtualAccount: true })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        {
                           configPayment.paymentMethodVirtualAccount && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodVirtualAccount: false })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        <span className="ml-2" style={{ fontSize: '15px' }}>Virtual Account (Midtrans)</span>
                        <span className="ml-2">
                           <i className="bi bi-arrow-up-right-square-fill"></i>
                        </span>
                     </div>
                     <div className="d-flex align-items-center">
                        {
                           !configPayment.paymentMethodBankTransfer && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodBankTransfer: true })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        {
                           configPayment.paymentMethodBankTransfer && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, paymentMethodBankTransfer: false })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        <span className="ml-2" style={{ fontSize: '15px' }}>Bank Transfer (Manual Verification)</span>
                        <span onClick={() => history.push('/website/bank')} className="ml-2">
                           <i className="bi bi-arrow-up-right-square-fill"></i>
                        </span>
                     </div>

                     <div className="d-flex align-items-center">
                        {
                           !configPayment.qris && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, qris: true })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        {
                           configPayment.qris && (
                              <svg onClick={() => {
                                 updateConfigPayment({ ...configPayment, qris: false })
                              }} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                           )
                        }
                        <span className="ml-2" style={{ fontSize: '15px' }}>QRIS</span>
                     </div>
                  </div>
               </div>
               <hr className="dash" />
               <div className="row justify-content-center">
                  <div className="col-12 col-md-5">
                     <h4>Broadcast WA</h4>
                  </div>
                  <div className="col-12 col-md-7">
                     {
                        broadcastWa.map((list, i) => {
                           return (
                              <div className="d-flex align-items-center mb-2" key={i} onClick={() => deleteEventList(list._id)}>
                                 <span style={{ cursor: 'pointer' }} className="mr-1">
                                    <i className="bi bi-trash-fill text-danger"></i>
                                 </span>
                                 <span className="ml-1" style={{ fontSize: '15px' }}>{list.value.label}</span>
                              </div>
                           )
                        })
                     }
                     <select className="form-control form-control-sm my-3" onChange={(e) => {
                        setEvent({
                           id: e.target.value,
                           label: e.target.options[e.target.selectedIndex].label
                        })
                     }}>
                        <option defaultValue="">-</option>
                        {events.map((e, i) => {
                           return <option value={e._id} key={i}>{e.title}</option>
                        })}
                     </select>
                     <button className="btn btn-sm btn-success" onClick={submitEventList}>
                        Add Event
                     </button>
                  </div>
               </div>
               <hr className="dash" />
               <div className="row justify-content-center">
                  <div className="col-12 col-md-5">
                     <h4>Event Participant Requirement </h4>
                  </div>
                  <div className="col-12 col-md-7">
                     {
                        listInputRequirement.map((list, i) => {
                           return (
                              <div className="d-flex align-items-center" key={i}>
                                 {
                                    !list.value.isFixed && (
                                       <span style={{ cursor: 'pointer' }} className="mr-3" onClick={() => deleteInput(list.value.label)}>
                                          <i className="bi bi-trash-fill text-danger"></i>
                                       </span>
                                    )
                                 }
                                 {
                                    list.value.status !== "active" && (
                                       <svg style={{ cursor: 'pointer' }} onClick={() => activeInput(list.value.label)} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                                    )
                                 }
                                 {
                                    !list.value.isFixed && list.value.status === "active" && (
                                       <svg style={{ cursor: 'pointer' }} onClick={() => inactiveInput(list.value.label)} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                                    )
                                 }
                                 {
                                    list.value.isFixed && list.value.status === "active" && (
                                       <svg style={{ cursor: 'pointer' }} onClick={() => alert('Required Input! Cannot remove, please contact Developer')} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                                    )
                                 }
                                 <span className="ml-2" style={{ fontSize: '15px' }}>{list.value.label} - <small>{list.value.type}</small></span>
                              </div>
                           )
                        })
                     }
                     <button className={`btn btn-sm btn-success ${listInputRequirement.length > 0 ? "mt-3" : ""}`} onClick={() => setModalInputRequirement(true)}>
                        Add Input Requirement
                     </button>
                  </div>
               </div>
               <hr className="dash" />
               <div className="row justify-content-center">
                  <div className="col-12 col-md-5">
                     <h4>Website Maintenance</h4>
                  </div>
                  <div className="col-12 col-md-7">

                     {maintenance.map((m, key) => {
                        return (
                           <>
                              <h6 style={{ fontWeight: '500' }}>{m.value.name}</h6>
                              <span className="mr-2" style={{ fontSize: '15px' }}>Off</span>
                              {!m.value.active && (
                                 <svg style={{ cursor: 'pointer' }} onClick={() => enableMaintenance(m)} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                              )}
                              {m.value.active && (
                                 <svg style={{ cursor: 'pointer' }} onClick={() => disableMaintenance(m)} className="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>
                              )}
                              <span className="ml-2" style={{ fontSize: '15px' }}>On</span>
                              <div className="dash"></div>
                           </>
                        )
                     })}

                     <button onClick={() => setModalMaintenance(true)} className="btn btn-sm btn-success">
                        Add Website
                     </button>
                  </div>
               </div>
            </div>
         </div>

         <Modal show={modalInputRequirement} onHide={handleCloseInputModal}>
            <form onSubmit={submitInput}>
               <Modal.Header closeButton>
                  <Modal.Title>Create Input</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label className="text-small">Label</label>
                     <input type="text" onChange={(e) => setLabel(e.target.value)
                     } className="form-control form-control-sm" required />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Type</label>
                     <select className="form-control form-control-sm" onChange={(e) => setType(e.target.value)} required>
                        <option value="" defaultChecked>-</option>
                        <option value="text">Text</option>
                        <option value="email">Email</option>
                        <option value="number">Number</option>
                        <option value="phone">Telephone</option>
                        <option value="currency">Currency</option>
                        <option value="password">Password</option>
                        <option value="radio">Option</option>
                        <option value="select">Select</option>
                     </select>
                  </div>
                  {
                     (type === "radio" || type === "select") && (
                        <div className="form-group">
                           <div className="d-flex justify-content-between align-items-center mb-2">
                              <label className="text-small mb-0">Options</label>
                              <button type="button" onClick={() => setListOption([...listOption, { name: "" }])} className="btn btn-sm btn-success" style={{ padding: '.1rem .5rem' }}>+</button>
                           </div>
                           {
                              listOption.map((list, i) => {
                                 return (
                                    <div className="d-flex justify-content-between mt-2" key={i}>
                                       <input type="text" onChange={(e) => handleChangeOption(i, e.target.value)} className="form-control form-control-sm mr-2" required />
                                       <button onClick={() => deleteOption(i)} type="button" className="btn btn-sm btn-danger" style={{ padding: '.1rem .5rem', width: '27px' }}>-</button>
                                    </div>
                                 )
                              })
                           }
                        </div>
                     )
                  }
                  <div className="form-group">
                     <label className="text-small">Required</label>
                     <select className="form-control form-control-sm" onChange={(e) => {
                        if (e.target.value === "yes") {
                           setIsFixed(true);
                        } else {
                           setIsFixed(false)
                        }
                     }} required>
                        <option value="no" defaultValue>No</option>
                        <option value="yes">Yes</option>
                     </select>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={handleCloseInputModal}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

         <Modal show={modalMaintenance} onHide={handleCloseInputModal}>
            <form onSubmit={submitWebMaintenance}>
               <Modal.Header closeButton>
                  <Modal.Title>Add Website</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label className="text-small">Website Name</label>
                     <input type="text" className="form-control form-control-sm" onChange={e => setWebName(e.target.value)} required />
                  </div>

                  <div className="form-group">
                     <label className="text-small">Website Domain/Subdomain</label>
                     <input type="text" className="form-control form-control-sm" onChange={e => setWebDomain(e.target.value)} required />
                  </div>

               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={handleCloseInputModal}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </BaseApp>
   )
}

export default Config
