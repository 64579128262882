import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Switch, Route, useRouteMatch, NavLink } from "react-router-dom";
import Accounts from '../components/account/Account';
import Customer from '../components/account/Customer';
import Admin from '../components/account/Admin';
import DetailAccount from '../components/account/DetailAccount';

function Account() {

   let { path, url } = useRouteMatch();

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   const [summary, setSummary] = useState({ account: 0, customer: 0, admin: 0 });
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/account')
            .then(resp => {
               // console.log(resp.data);
               setSummary(resp.data.summary);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <Row>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`${url}`} activeClassName="active" className="card-single">
                        <div>
                           <h1>{summary.account}</h1>
                           <span>Account</span>
                        </div>
                        <div>
                           <span><i className="bi bi-person-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`${url}/customer`} activeClassName="active" className="card-single">
                        <div>
                           <h1>{summary.customer}</h1>
                           <span>Customer</span>
                        </div>
                        <div>
                           <span><i className="bi bi-people-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <NavLink to={`${url}/admin`} activeClassName="active" className="card-single">
                        <div>
                           <h1>{summary.admin}</h1>
                           <span>User Admin</span>
                        </div>
                        <div>
                           <span><i className="bi bi-person-badge-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
         </Row>

         <Switch>
            <Route exact path={`${path}`}>
               <Accounts />
            </Route>
            <Route path={`${path}/customer`}>
               <Customer />
            </Route>
            <Route path={`${path}/admin`}>
               <Admin />
            </Route>
            <Route path={`${path}/:id`}>
               <DetailAccount />
            </Route>
         </Switch>

      </BaseApp>
   )
}

export default Account
