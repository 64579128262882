import React, { useState, useEffect } from 'react';
import { Table, Spinner, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import Swal from 'sweetalert2';

function Admin() {

   moment.locale('id');
   const [admin, setAdmin] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [updated, setUpdated] = useState(true);
   const [showModal, setShowModal] = useState(false);
   const [idUser, setIdUser] = useState(null);
   const [user, setUser] = useState([]);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/admin')
            .then(resp => {
               setAdmin(resp.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [updated]);

   const removeAdmin = (id) => {
      Swal.fire({
         title: 'Remove this Admin?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.put('/user/' + id, { role: 'user' })
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Admin Removed!',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdated(!updated);
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   };

   const handleCloseModal = () => {
      setShowModal(false);
      setIdUser(null);
   }

   const handleShowModal = async () => {
      setShowModal(true);
      setIdUser(null);
      setIsLoading(true);

      return await axios.get('/backoffice/user')
         .then(resp => {
            // console.log(resp.data);
            setUser(resp.data);
            setIsLoading(false);
         }).catch(err => {
            console.log(err);
         })

   }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (idUser === null) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Error',
            text: "User Required!",
            showConfirmButton: false,
            timer: 1500
         });
      } else {
         Swal.fire({
            title: 'Please Waiting..',
            allowOutsideClick: false,
            didOpen: () => {
               Swal.showLoading()
            }
         });
         axios.put('/user/' + idUser, { role: 'admin' })
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Admin Added!',
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
               setShowModal(false);
            })
            .catch(err => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Error',
                  text: err.mesage,
                  showConfirmButton: false,
                  timer: 1500
               });
               console.log(err)
            });
      }
   }

   return (
      <>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Admin Tiketseminar</h5>
               <div className="block-options">
                  <button onClick={handleShowModal} className="btn btn-sm btn-success"> <i className="bi bi-plus-circle-fill mr-2" />Add Admin </button>
               </div>
            </div>
            <div className="block-content">
               <div className="form-group">
                  <input type="text" className="form-control" placeholder="Search name here" />
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Created At</th>
                        <th className="text-center">Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        isLoading && (
                           <tr>
                              <td colSpan="8" className="text-center">
                                 <Spinner animation="border" />
                              </td>
                           </tr>
                        )
                     }

                     {
                        !isLoading && (
                           <>
                              {
                                 admin.length === 0 && (
                                    <tr>
                                       <td colSpan="8" className="text-center">Belum ada Admin</td>
                                    </tr>
                                 )
                              }

                              {
                                 admin.map((a, i) => {
                                    return (
                                       <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>{a.name}</td>
                                          <td>{a.username !== "" ? a.username : '-'}</td>
                                          <td>{a.email}</td>
                                          <td>{moment(a.createdAt).format('LLLL')}</td>
                                          <td className="text-center">
                                             <button onClick={() => removeAdmin(a._id)} className="btn btn-sm btn-danger"><i className="bi bi-trash-fill mr-2" />Remove Admin</button>
                                          </td>
                                       </tr>
                                    )
                                 })
                              }
                           </>
                        )
                     }


                  </tbody>
               </Table>
            </div>
         </div>
         <Modal show={showModal} onHide={handleCloseModal}>
            <form onSubmit={handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title>Add Admin</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {
                     isLoading && (
                        <div className="text-center">
                           <Spinner animation="border" />
                        </div>
                     )
                  }
                  {
                     !isLoading && (
                        <div className="form-group">
                           <label>Select User</label>
                           <select className="form-control form-control-sm" onChange={(e) => setIdUser(e.target.value)}>
                              <option defaultValue="">-</option>
                              {
                                 user.map((u, i) => {
                                    return <option key={i} value={u._id}>{u.email}</option>
                                 })
                              }
                           </select>
                        </div>
                     )
                  }
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" size="sm" onClick={handleCloseModal}>
                     Close
                  </Button>
                  <Button variant="primary" size="sm" type="submit">
                     Add Admin
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </>
   )
}

export default Admin
