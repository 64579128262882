import React from 'react';
import { NavLink } from "react-router-dom";

function Sidebar() {
   return (
      <>
         <input type="checkbox" id="nav-toggle" />
         <div className="sidebar">
            <div className="sidebar-brand">
               <h5 className="mb-0">TiketSeminar</h5>
            </div>
            <div className="sidebar-menu">
               <ul>
                  <li>
                     <NavLink to="/" exact activeClassName="active">
                        <span><i className="bi bi-grid-fill"></i></span>
                        <span>Dashboard</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/event" activeClassName="active">
                        <span><i className="bi bi-file-richtext-fill"></i></span>
                        <span>Event</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/order" activeClassName="active">
                        <span><i className="bi bi-cart-fill"></i></span>
                        <span>Order</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/payment" activeClassName="active">
                        <span><i className="bi bi-credit-card-2-front-fill"></i></span>
                        <span>Payment</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/website" activeClassName="active">
                        <span><i className="bi bi-layers-fill"></i></span>
                        <span>Website</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/account" activeClassName="active">
                        <span><i className="bi bi-person-fill"></i></span>
                        <span>Account</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/contact" activeClassName="active">
                        <span><i className="bi bi-people-fill"></i></span>
                        <span>Contact</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/voucher" activeClassName="active">
                        <span><i className="bi bi-tags-fill"></i></span>
                        <span>Voucher</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/reminder" activeClassName="active">
                        <span><i className="bi bi-clock-fill"></i></span>
                        <span>Reminder</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/certificate" activeClassName="active">
                        <span><i className="bi bi-file-text-fill"></i></span>
                        <span>Certificate</span>
                     </NavLink>
                  </li>
                  {/* <li>
                     <NavLink to="/broadcast" activeClassName="active">
                        <span><i className="bi bi-cursor-fill"></i></span>
                        <span>Broadcast</span>
                     </NavLink>
                  </li> */}
                  <li>
                     <NavLink to="/withdrawal" activeClassName="active">
                        <span><i className="bi bi-arrow-left-right"></i></span>
                        <span>Withdrawal</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/report" activeClassName="active">
                        <span><i className="bi bi-file-earmark-text-fill text-right"></i></span>
                        <span>Report</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/dictionary" activeClassName="active">
                        <span><i className="bi bi-book-half text-right"></i></span>
                        <span>Dictionary</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/log/email" activeClassName="active">
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-exclamation-fill" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697ZM12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5a.5.5 0 0 0-1 0v1.5a.5.5 0 0 0 1 0V11Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
                        </svg></span>
                        <span>Log Email</span>
                     </NavLink>
                  </li>
                  <li>
                     <NavLink to="/config" activeClassName="active">
                        <span><i className="bi bi-gear-fill"></i></span>
                        <span>Configuration</span>
                     </NavLink>
                  </li>
               </ul>
            </div>
         </div>
      </>
   )
}

export default Sidebar
