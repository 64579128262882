import React, { useState, useEffect } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';

function Customer() {

   moment.locale('id');
   const [customer, setCustomer] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/customer')
            .then(resp => {
               setCustomer(resp.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <div className="block mt-4">
         <div className="block-header">
            <h5 className="block-title mb-0">All Customer Tiketseminar</h5>
         </div>
         <div className="block-content">
            <div className="form-group">
               <input type="text" className="form-control" placeholder="Search name here" />
            </div>
            <Table bordered>
               <thead>
                  <tr>
                     <th style={{ width: '25px' }}>#</th>
                     <th>Name</th>
                     <th>Email</th>
                     <th>Phone</th>
                     <th>CreatedAt</th>
                  </tr>
               </thead>
               <tbody>
                  {
                     isLoading && (
                        <tr>
                           <td colSpan="5" className="text-center">
                              <Spinner animation="border" />
                           </td>
                        </tr>
                     )
                  }

                  {
                     !isLoading && (
                        <>
                           {
                              customer.length === 0 && (
                                 <tr>
                                    <td colSpan="8" className="text-center">Belum ada Customer</td>
                                 </tr>
                              )
                           }

                           {
                              customer.map((c, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{c.name}</td>
                                       <td>{c.email}</td>
                                       <td>{c.phone}</td>
                                       <td>{c.createdAt ? moment(c.createdAt).format('LLLL') : '-'}</td>
                                    </tr>
                                 )
                              })
                           }
                        </>
                     )
                  }


               </tbody>
            </Table>
         </div>
      </div>
   )
}

export default Customer
