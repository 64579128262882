import React, { useState, useEffect, useRef } from 'react';
import InputSchedule from '../components/InputSchedule';
import TinyTextarea from '../components/TinyTextarea';
import InputTicket from '../components/InputTicket';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import slug from 'slug';
import Select from 'react-select';

function EditEvent() {

   let { id } = useParams();
   const [data, setData] = useState({});
   const [isLoading, setIsLoading] = useState(true);
   const [totalticket, setTotalTicket] = useState(1);
   const [previewImage, setpreviewImage] = useState('');
   const [changeImage, setChangeImage] = useState(false);
   const [urlImage, setUrlImage] = useState(null);
   const [urlLogo, setUrlLogo] = useState(null);
   const [authorName, setAuthorName] = useState('');

   const [isOnline, setIsOnline] = useState(false);
   const [isOffline, setIsOffline] = useState(false);
   const [schedules, setSchedule] = useState([]);
   const [tickets, setTicket] = useState([]);
   const description = useRef({});
   const termcondition = useRef({});

   const [optionSelect, setOptionSelect] = useState([]);
   const [requirements, setrequirements] = useState([]);
   const [errorLink, setErrorLink] = useState(false);
   const [successLink, setSuccessLink] = useState(false);
   const [loadingButton, setLoadingButton] = useState(false);
   const [categories, setCategories] = useState([]);
   const [skpPoints, setSkpPoints] = useState([]);
   const [valueCategories, setValueCategories] = useState([]);
   const [listCategory, setListCategory] = useState([]);
   const [platformFee, setPlatformFee] = useState(true);
   const [multipleOrder, setMultipleOrder] = useState(true);
   const [limit, setLimit] = useState(0);
   const [minimum, setMinimum] = useState(1);
   const [contact, setContact] = useState({ name: "", phone: "", level: "", event: "" });

   let APP_URL;
   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         APP_URL = "https://tiketseminar.com/";
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
         break;
      case 'sandbox':
         APP_URL = "https://sandbox.tiketseminar.com/";
         break;
      default:
         APP_URL = "http://localhost:3000/";
         break;
   }


   const handleChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

   const handleChangeSchedule = (e, i) => {
      const { name, value } = e.target;
      const schedule = [...schedules];
      schedule[i][name] = value;
      setSchedule(schedule);
   }
   const handleChangeTicket = (e, type, i) => {
      if (type === "price") {
         const ticket = [...tickets];
         ticket[i][type] = parseInt(e);
         setTicket(ticket);
      } else if (type === "hidden") {
         const ticket = [...tickets];
         ticket[i][type] = e;
         setTicket(ticket);
      } else {
         const { name, value } = e.target;
         const ticket = [...tickets];
         if (name === "quota" || name === "selectSubCategory" || name === "minimum" || name === "maximum" || name === "buy" || name === "get") {
            ticket[i][name] = parseInt(value);
         } else {
            ticket[i][name] = value;
         }
         setTicket(ticket);
      }
   }

   const addSchedule = (e) => {
      if (isOnline) {
         setSchedule([...schedules, { date: '', startTime: '', endTime: '', label: '', link: '', links: [] }])
      } else {
         setSchedule([...schedules, { date: '', startTime: '', endTime: '' }])
      }
   }

   const addTicket = (e) => {
      setTotalTicket(totalticket + 1);
      setTicket([...tickets, { id: totalticket + 1, name: '', price: '', quota: '', description: '', saleStartDate: '', saleEndDate: '', minimum: 1, maximum: 20, buy: 0, get: 0, forStudent: '', forDoctor: false, generalDoctor: false, specialistDoctor: false, selectSubCategory: 0, category: [], hidden: false }])
   }

   const deleteSchedule = (i) => {
      const schedule = [...schedules];
      schedule.splice(i, 1);
      setSchedule(schedule);
   }

   const deleteTicket = (i) => {
      const ticket = [...tickets];
      ticket.splice(i, 1);
      setTicket(ticket);
   }

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      const field = event.target.name;
      const AcceptAbleExtention = ["png", "jpeg", "jpg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            if (field === "image") {
               setUrlImage(resp.data.code);
            } else if (field === "authorLogo") {
               setUrlLogo(resp.data.code);
            }

         }).catch(error => {
            console.log(error.response)
         })

      setChangeImage(true);

      if (field === "image") {
         let image = URL.createObjectURL(file);
         setpreviewImage(image);
      }
   }

   const addSubCategoryTicket = (i) => {
      let ticket = tickets[i];
      if (!ticket.category) {
         ticket.category = [];
      }
      ticket.category.push({ id: ticket.category.length + 1, name: "", quota: 0 });
      let dataTickets = [...tickets];
      dataTickets[i] = ticket;
      setTicket(dataTickets);
   }

   const deleteSubCategoryTicket = (t, i) => {
      let ticket = tickets[t];
      let category = [...ticket.category];
      category.splice(i, 1);
      ticket.category = category;
      let dataTickets = [...tickets];
      setTicket(dataTickets);
   }

   const handleChangeSubCategory = (e, t, i) => {
      const { name, value, type } = e.target;
      let ticket = tickets[t];
      let category = [...ticket.category];
      type === "number" ? category[i][name] = parseInt(value) : category[i][name] = value;
      ticket.category = category;
      let dataTickets = [...tickets];
      setTicket(dataTickets);
   }

   const addLink = (i) => {
      let schedule = schedules[i];
      schedule.links.push({ label: "", url: "" });
      let dataSchedules = [...schedules];
      dataSchedules[i] = schedule;
      setSchedule(dataSchedules);
   }

   const handleChangeLink = (e, l, i) => {
      let schedule = schedules[l];
      let links = [...schedule.links];
      if (e.target.name === "label") {
         links[i].label = e.target.value;
      } else if (e.target.name === "url") {
         links[i].url = e.target.value;
      }
      schedule.links = links;
      let dataSchedules = [...schedules];
      dataSchedules[l] = schedule;
      setSchedule(dataSchedules);
   }

   const deleteLink = (s, i) => {
      let schedule = schedules[s];
      let links = [...schedule.links];
      links.splice(i, 1);
      schedule.links = links;
      let dataSchedules = [...schedules];
      dataSchedules[s] = schedule;
      setSchedule(dataSchedules);
   }

   useEffect(() => {
      const getData = async (id) => {
         return await axios.get('/event/' + id + '/edit')
            .then(resp => {
               // console.log(resp.data);
               setData(resp.data);
               setSchedule(resp.data.schedules);
               setTicket(resp.data.tickets);
               setTotalTicket(resp.data.tickets.length);
               setpreviewImage(resp.data.image);
               setIsOnline(resp.data.isOnline);
               setIsOffline(resp.data.isOffline);
               setrequirements(resp.data.requirements);
               setUrlLogo(resp.data.author.logo);
               setAuthorName(resp.data.author.name);
               setUrlImage(resp.data.image);
               setSkpPoints(resp.data.skpPoint);
               let listCategories = []; let defaultCategories = [];
               resp.data.categories.forEach(element => {
                  listCategories.push({ value: element, label: element });
                  defaultCategories.push(element);
               })
               setValueCategories(listCategories);
               setCategories(defaultCategories);
               setPlatformFee(resp.data.platformFee);
               setMultipleOrder(resp.data.multipleOrder);
               setLimit(resp.data.limit);
               setContact(resp.data.contact);
               setMinimum(resp.data.minimum);
               setIsLoading(false);
            }).catch(err => {
               console.log(err.response);
               window.history.back();
            })
      }

      const getRequirement = () => {
         axios.get('/event/requirement')
            .then(resp => {
               let list = [];
               resp.data.forEach(element => {
                  list.push({ value: element.value.label, label: element.value.label, type: element.value.type, isFixed: element.value.isFixed, name: element.value.name, option: element.value.option });
               });
               setOptionSelect(list);
            }).catch(err => console.log(err.response))
      }

      const getCategory = () => {
         axios.get('/category')
            .then(resp => {
               let listCategory = [];
               resp.data.forEach(element => {
                  listCategory.push({ value: element.name, label: element.name });
               })
               setListCategory(listCategory);
            }).catch(err => console.log(err.response.data))
      }
      if (id === "" || id === null || id === undefined) {
         window.history.back();
      } else {
         getData(id);
      }

      getRequirement();
      getCategory();

   }, [id]);

   const handleSubmit = () => {
      // setIsLoading(true);

      let error = false;
      for (let index = 0; index < schedules.length; index++) {
         if (schedules[index].date === "") {
            error = true;
            break;
         }

         if (schedules[index].startTime === "") {
            error = true;
            break;
         }

         if (schedules[index].endTime === "") {
            error = true;
            break;
         }

      }

      let isFree = false;
      for (let index = 0; index < tickets.length; index++) {
         if (tickets[index].name === "") {
            error = true;
            break;
         }

         if (tickets[index].price === "") {
            tickets[index].price = 0;
         }

         if (tickets[index].quota === "") {
            tickets[index].quota = 0;
         }

         if (tickets[index].saleStartDate === "") {
            error = true;
            break;
         }

         if (tickets[index].saleEndDate === "") {
            error = true;
            break;
         }

         if (parseInt(tickets[index].price) === 0) {
            isFree = true;
         }
      }

      if (error) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Please fill all data',
            showConfirmButton: false,
            timer: 1500
         });
      } else {

         setIsLoading(true);

         Swal.fire({
            title: 'Loading',
            allowOutsideClick: false,
            didOpen: () => {
               Swal.showLoading()
            }
         })

         let update;
         const contentDescription = description.current.getContent();
         const contentTermCondition = termcondition.current.getContent();

         let editAuthor = {
            name: authorName,
            logo: urlLogo
         }

         if (changeImage) {
            update = {
               ...data,
               schedules: schedules, contact,
               tickets: tickets, categories,
               author: editAuthor, skpPoint: skpPoints,
               description: contentDescription,
               termcondition: contentTermCondition,
               image: urlImage, isOnline, isOffline, isFree,
               uid: data.author.id, requirements,
               slug: slug(data.title), isAdmin: true, platformFee, multipleOrder, limit, minimum
            }
         } else {
            update = {
               ...data,
               schedules: schedules, contact,
               tickets: tickets, categories,
               author: editAuthor, skpPoint: skpPoints,
               description: contentDescription,
               termcondition: contentTermCondition,
               isOnline, isOffline, isFree, uid: data.author.id,
               slug: slug(data.title), requirements, isAdmin: true, platformFee, multipleOrder, limit, minimum
            }
         }

         axios.put('/event/' + id, update)
            .then((resp) => {
               setIsLoading(false);
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Event Updated',
                  showConfirmButton: false,
                  timer: 1500
               });
               window.history.back();
            })
            .catch(err => {
               console.log(err);
               setIsLoading(false);

               if (err.response.status === 401 || err.response.status === 403) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('profile');
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: 'Session Expired',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  document.location = '/login';
               } else {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
               }
            });
      }
   }

   const styles = {
      multiValue: (base, state) => {
         return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
      },
      multiValueLabel: (base, state) => {
         return state.data.isFixed
            ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
            : base;
      },
      multiValueRemove: (base, state) => {
         return state.data.isFixed ? { ...base, display: "none" } : base;
      }
   };

   const orderOptions = values => {
      return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
   };

   const onChangeSelect = (value, { action, removedValue }) => {
      switch (action) {
         case 'remove-value':
         case 'pop-value':
            if (removedValue.isFixed) {
               return;
            }
            break;
         case 'clear':
            value = optionSelect.filter(v => v.isFixed);
            break;
      }
      value = orderOptions(value);
      setrequirements(value);
   }

   const checkShortLink = () => {
      if (data.shortLink !== "") {
         setLoadingButton(true);
         axios.get('/event/link/' + data.shortLink)
            .then(resp => {
               if (resp.data === null || resp.data.id === id) {
                  setErrorLink(false);
                  setSuccessLink(true);
               } else {
                  setSuccessLink(false)
                  setErrorLink(true);
               }
               setLoadingButton(false);
            })
            .catch(error => {
               console.log(error)
               setLoadingButton(false);
            });
      }
   }

   const handleChangeShortLink = (value) => {
      let convert = value.replace(/ /g, '-')
         .replace(/[^\w-]+/g, '');
      setData({ ...data, shortLink: convert })
   }

   const removeSkp = (id) => {
      let listPoint = [...skpPoints];
      listPoint.splice(id, 1);
      setSkpPoints(listPoint);
   }

   const handleSkpPoint = (i, name, value) => {
      const skpPoint = [...skpPoints];
      skpPoint[i][name] = value;
      setSkpPoints(skpPoint);
   }

   const handleDoctorCategory = (i, name, value) => {
      const ticket = [...tickets];
      ticket[i][name] = value;
      setTicket(ticket);
   }

   const handleChangeScheduleRelation = (i, value) => {
      const ticket = [...tickets];
      ticket[i]['schedule'] = value;
      setTicket(ticket);
   }

   return (
      <div>
         {
            !isLoading && (
               <Container className="mt-4 mb-4">
                  <div className="card dash py-2 px-2 mb-3">
                     <div className="row align-items-center">
                        <div className="col-lg-6">
                           <h3 className="mb-0">Edit Event</h3>
                        </div>
                        <div className="col-lg-6 text-right">
                           <button onClick={() => window.history.back()} className="btn btn-sm btn-outline-secondary">Kembali</button>
                        </div>
                     </div>
                  </div>
                  <div className="card dash py-3 px-3">
                     <div className="row">
                        <div className="col-12 col-md-5">

                           <div className="h-100">
                              {
                                 previewImage !== "" ?

                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                       <img src={(previewImage.includes('firebase') || previewImage.includes('blob')) ? previewImage : process.env.REACT_APP_CDN_URL + '/get/' + previewImage} alt="" style={{ width: '350px', height: '450px', objectFit: 'cover' }} />
                                       <input type="file" name="image" className="form-control form-control-sm mt-2" style={{ width: '50%' }} onChange={(e) => handleImageChange(e)} required />
                                    </div> :
                                    <div className="card dash d-flex flex-column justify-content-center align-items-center h-100 px-3 py-3">
                                       <h4>Upload Gambar</h4>
                                       <input type="file" name="image" className="form-control form-control-sm mt-2" style={{ width: '50%' }} onChange={(e) => handleImageChange(e)} required />
                                    </div>
                              }

                           </div>
                        </div>
                        <div className="col-12 col-md-7">
                           <div className="form-group">
                              <h6>Judul Event</h6>
                              <input className="form-control form-control-sm" name="title" onChange={handleChange} defaultValue={data.title} type="text" required />
                           </div>
                           <div className="row">
                              <div className="col-12 col-md-8">
                                 <div className="form-group">
                                    <h6>Kategori</h6>
                                    <Select
                                       closeMenuOnSelect={false}
                                       isMulti
                                       value={valueCategories}
                                       options={listCategory}
                                       placeholder="Pilih Kategori"
                                       onChange={(value) => {
                                          let arrCategory = [];
                                          value.forEach(element => {
                                             arrCategory.push(element.value);
                                          })
                                          setValueCategories(value);
                                          setCategories(arrCategory);
                                       }}
                                       classNamePrefix="select"
                                    />
                                 </div>
                              </div>
                              <div className="col-12 col-md-4 align-self-center">
                                 <div className="form-group">
                                    <h6>Pelaksanaan Event</h6>
                                    <div className="form-check form-check-inline">
                                       <input className="form-check-input" name="typeEvent" type="checkbox" id="inlineRadio1" defaultChecked={data.isOnline} value="online" onClick={() => setIsOnline(!isOnline)} />
                                       <label className="form-check-label" style={{ fontSize: '13px' }} htmlFor="inlineRadio1">Online</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                       <input className="form-check-input" name="typeEvent" type="checkbox" id="inlineRadio2" value="offline" defaultChecked={data.isOffline} onClick={() => setIsOffline(!isOffline)} />
                                       <label className="form-check-label" style={{ fontSize: '13px' }} htmlFor="inlineRadio2">Offline</label>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-6">
                                 <div className="form-group">
                                    <h6>Nama Penyelenggara</h6>
                                    <input className="form-control form-control-sm" onChange={(e) => setAuthorName(e.target.value)} type="text" defaultValue={authorName} required />
                                 </div>
                              </div>
                              <div className="col-6">
                                 <div className="form-group">
                                    <h6>Logo</h6>
                                    <input className="form-control form-control-sm" type="file" name="authorLogo" onChange={(e) => handleImageChange(e)} />
                                 </div>
                              </div>
                           </div>
                           <div className="form-group">
                              <label>Short Link</label>
                              <div className="row">
                                 <div className="col-12 col-md-10">
                                    <div className="input-group input-group-sm mb-2">
                                       <div className="input-group-prepend">
                                          <div className="input-group-text">{APP_URL}</div>
                                       </div>
                                       <input type="text" className={`form-control ${errorLink ? 'is-invalid' : ''} ${successLink ? 'is-valid' : ''}`} onChange={(e) => handleChangeShortLink(e.target.value)} value={data.shortLink} />
                                       {errorLink && (
                                          <div className="invalid-feedback">
                                             Short Link sudah digunakan
                                          </div>
                                       )}

                                       {successLink && (
                                          <div className="valid-feedback">
                                             Short Link bisa digunakan
                                          </div>
                                       )}
                                    </div>
                                 </div>
                                 <div className="col-12 col-md-2">
                                    <button type="button" onClick={checkShortLink} className="btn btn-sm btn-block btn-info" disabled={loadingButton}>Cek URL</button>
                                 </div>
                              </div>
                           </div>
                           {skpPoints.length > 0 && (
                              <div className="form-group">
                                 <h6>SKP Event</h6>
                                 {skpPoints.map((skp, key) => {
                                    return (
                                       <div className="row mb-3" key={key}>
                                          <div className="col-12 col-md-7">
                                             <input className="form-control form-control-sm" type="text" placeholder="IDI / PPNI / IAI / IBI" onChange={(e) => handleSkpPoint(key, 'name', e.target.value)} defaultValue={skp.name} required />
                                          </div>
                                          <div className="col-12 col-md-3">
                                             <input className="form-control form-control-sm" defaultValue={skp.amount} onChange={(e) => handleSkpPoint(key, 'amount', e.target.value)} type="number" required />
                                          </div>
                                          <div className="col-12 col-md-2 text-right">
                                             <button className="btn btn-sm btn-danger" type="button" onClick={() => removeSkp(key)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" fill="rgba(255,255,255,1)" /></svg>
                                             </button>
                                          </div>
                                       </div>
                                    )
                                 })}
                              </div>
                           )}
                           <div className="form-group mt-2 mb-4">
                              <button className="btn btn-secondary btn-sm btn-block" onClick={() => setSkpPoints([...skpPoints, { name: '', amount: 0 }])}>
                                 <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="#fff" /></svg>
                                 Inputan SKP
                              </button>
                           </div>
                           <div className="row">
                              <div className="col-12 col-md-6">
                                 <div className="form-group">
                                    <h6>Platform Fee</h6>
                                    <div className="d-flex align-items-center">
                                       {!platformFee && <svg onClick={() => setPlatformFee(true)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                       {platformFee && <svg onClick={() => setPlatformFee(false)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                       <span className="ml-2" style={{ fontSize: '15px' }}>
                                          {platformFee ? 'On' : 'Off'}
                                       </span>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12 col-md-6">
                                 <div className="form-group">
                                    <h6>Multiple Order</h6>
                                    <div className="d-flex align-items-center">
                                       {!multipleOrder && <svg onClick={() => setMultipleOrder(true)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                       {multipleOrder && <svg onClick={() => setMultipleOrder(false)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                       <span className="ml-2" style={{ fontSize: '15px' }}>
                                          {multipleOrder ? 'On' : 'Off'}
                                       </span>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-12 col-md-6">
                                 <div className="form-group">
                                    <h6>Minimal Pembelian</h6>
                                    <input className="form-control form-control-sm" onChange={(e) => setMinimum(e.target.value)} type="number" defaultValue={minimum} required />
                                 </div>
                              </div>
                              <div className="col-12 col-md-6">
                                 <div className="form-group">
                                    <h6>Limit Pembelian</h6>
                                    <input className="form-control form-control-sm" onChange={(e) => setLimit(e.target.value)} type="number" defaultValue={limit} required />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <h6 className="mt-4">Deskripsi Event</h6>
                     <TinyTextarea editorRef={description} value={data.description} />
                     <h6 className="mt-4">Syarat & Ketentuan <small>*optional</small></h6>
                     <TinyTextarea editorRef={termcondition} value={data.termcondition} />
                     <h6 className="mt-4">Input Data Peserta</h6>
                     <Select
                        value={requirements}
                        closeMenuOnSelect={false}
                        isMulti
                        options={optionSelect}
                        placeholder="Pilih Inputan"
                        onChange={onChangeSelect}
                        classNamePrefix="select"
                        styles={styles}
                     />
                     <h6 className="mt-4">No Telepon/WA CP Event</h6>
                     <input className="form-control form-control-sm" onChange={(e) => setContact({ ...contact, event: e.target.value })} type="number" defaultValue={contact.event} required />
                     <small className="form-text text-muted mb-4">*Mohon diisi nomor contact person yang bisa dihubungi peserta terkait dengan event</small>
                     <div className="row mt-4">
                        <div className="col-12 col-md-6">
                           <div className="d-flex justify-content-between mb-4">
                              <h6>Jadwal Event</h6>
                              <button type="button" onClick={addSchedule} className="btn btn-sm btn-secondary">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="rgba(255,255,255,1)" /></svg>
                                 <span className="ml-1">Tambah Hari</span>
                              </button>
                           </div>
                           <InputSchedule remove={deleteSchedule} handle={handleChangeSchedule} schedules={schedules} offline={isOffline} online={isOnline} addLink={addLink} handleChangeLink={handleChangeLink} deleteLink={deleteLink} />
                        </div>
                        <div className="col-12 col-md-6">
                           <div className="d-flex justify-content-between mb-4">
                              <h6>Kategori Ticket</h6>
                              <button type="button" onClick={addTicket} className="btn btn-sm btn-secondary">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="rgba(255,255,255,1)" /></svg>
                                 <span className="ml-1">Tambah Ticket</span>
                              </button>
                           </div>
                           <InputTicket schedules={schedules} tickets={tickets} handle={handleChangeTicket} remove={deleteTicket} addSubCategoryTicket={addSubCategoryTicket} deleteSubCategoryTicket={deleteSubCategoryTicket} handleChangeSubCategory={handleChangeSubCategory} handleDoctorCategory={handleDoctorCategory} handleChangeScheduleRelation={handleChangeScheduleRelation} />
                        </div>
                     </div>
                     <div className="row mt-4">
                        <div className="col-12">
                           <div className="text-right">
                              {
                                 isLoading ? (
                                    <button className="btn btn-sm btn-success" disabled>
                                       <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                       Loading...
                                    </button>
                                 ) : (
                                    <button onClick={handleSubmit} type="button" className="btn btn-sm btn-success">
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" fill="rgba(255,255,255,1)" /></svg>
                                       <span className="ml-2">Update Event</span>
                                    </button>
                                 )
                              }

                           </div>
                        </div>
                     </div>
                  </div>
               </Container>
            )
         }
      </div>
   )
}

export default EditEvent
