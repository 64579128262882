import React from 'react';
import avatar from '../assets/avatar.png'

function Header() {

   const profile = JSON.parse(localStorage.getItem('profile'));
   if (profile === null || profile === undefined || profile === "") {
      document.location = "/login";
   }

   const logout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('profile');
      document.location = "/login";
   }

   return (
      <header>
         <h4 className="mb-0">
            <label htmlFor="nav-toggle">
               <span><i className="bi bi-list"></i></span>
            </label>
            Backoffice
         </h4>
         <div className="header-user">
            <div className="user-wrapper">
               {
                  profile.avatar !== "" ? (
                     <img src={profile.avatar} alt="" width="40px" height="40px" />
                  ) : <img src={avatar} alt="" width="40px" height="40px" />
               }
               <div>
                  <h6 className="mb-0">{profile.name}</h6>
                  <span className="text-muted">Administrator</span>
               </div>
            </div>
            <div className="dropdown-wrapper">
               <ul>
                  <li>Profile</li>
                  <li onClick={logout}>Logout</li>
               </ul>
            </div>
         </div>
      </header>
   )
}

export default Header
