import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, } from 'react-bootstrap';
import { useParams, useHistory, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import Ticket from '../components/Ticket';
import axios from 'axios';
import parse from "html-react-parser";
import moment from 'moment/min/moment-with-locales';

function EventDetail() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   moment.locale('id');
   let { slug } = useParams();
   const history = useHistory();
   const [event, setEvent] = useState({});
   const linkEvent = window.location.href;
   const [isLoading, setIsLoading] = useState(true);
   const [updated, setUpdated] = useState(false);

   useEffect(() => {
      const getDataEvent = async (slug) => {
         return await axios.get(`/backoffice/event/${slug}`)
            .then(resp => {
               if (resp.data === null) {
                  history.push('/');
               }
               // console.log(resp.data);
               setEvent(resp.data)
               setIsLoading(false);
            })
            .catch(err => console.log(err));
      }
      getDataEvent(slug);
   }, [slug, history, updated]);

   const [key, setKey] = useState('desc');

   const increment = (ticket) => {
      let counter = document.getElementById('counter-' + ticket.id);
      counter.innerHTML = parseInt(counter.textContent) + 1;
   }

   const decrement = (ticket) => {
      let counter = document.getElementById('counter-' + ticket.id);
      if (parseInt(counter.textContent) === 1) {
         counter.innerHTML = 0;
      } else if (parseInt(counter.textContent) > 0) {
         counter.innerHTML = parseInt(counter.textContent) - 1;
      }
   }

   const approve = (id) => {
      setIsLoading(true);
      Swal.fire({
         title: 'Please Waiting..',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });
      axios.put('/event/' + id, { status: 'approve' })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Approve Success!',
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(err => {
            console.log(err.response.data);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Approve Error!',
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const approvePublish = (id) => {
      setIsLoading(true);
      Swal.fire({
         title: 'Please Waiting..',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });
      axios.put('/event/' + id, { status: 'approve', isPublish: true })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Approve Success!',
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(err => {
            console.log(err.response.data);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Approve Error!',
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const reject = (id) => {
      Swal.fire({
         title: 'Masukkan Catatan',
         input: 'text',
         inputAttributes: {
            autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (text) => {
            setIsLoading(true);
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.put('/event/' + id, {
               status: 'reject',
               isPublish: false,
               rejectNote: text
            }).then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Reject Success!',
                  showConfirmButton: false,
                  timer: 1500
               });
               setUpdated(!updated);
            }).catch(err => {
               console.log(err.response.data);
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Reject Error!',
                  showConfirmButton: false,
                  timer: 1500
               });
            })
         },
         allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: `${result.value.login}'s avatar`,
               imageUrl: result.value.avatar_url
            })
         }
      })
   }

   const publish = (id) => {
      setIsLoading(true);
      Swal.fire({
         title: 'Please Waiting..',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });
      axios.put('/event/' + id, { isPublish: true })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Publish Success!',
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(err => {
            console.log(err.response.data);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Publish Error!',
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const unpublish = (id) => {
      setIsLoading(true);
      Swal.fire({
         title: 'Please Waiting..',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });
      axios.put('/event/' + id, { isPublish: false })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Unpublish Success!',
               showConfirmButton: false,
               timer: 1500
            });
            setUpdated(!updated);
         }).catch(err => {
            console.log(err.response.data);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Unpublish Error!',
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const getStatus = (status) => {
      if (status === "approve") {
         return <span className="badge badge-success">Aktif</span>
      }

      if (status === "draft") {
         return <span className="badge badge-info">Draft</span>
      }

      if (status === "request") {
         return <span className="badge badge-warning">Menunggu</span>
      }

      if (status === "reject") {
         return <span className="badge badge-danger">Ditolak</span>
      }
   }

   return (
      <Container className="py-5">
         <div className="row align-items-center">
            <div className="col-lg-6">
               <button onClick={() => history.goBack()} className="btn btn-sm btn-outline-dark">
                  <i className="bi bi-arrow-left-circle-fill mr-2"></i>
                  Back to Backoffice
               </button>
            </div>
            {
               isLoading && (
                  <div className="col-lg-6">
                     <div className="loading" style={{ width: '100%', padding: '15px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  </div>
               )
            }
            {
               !isLoading && (
                  <div className="col-lg-6 text-right">
                     <NavLink to={`/event/edit/${event._id}`} className="btn btn-sm btn-dark mr-2">
                        <i className="bi bi-pencil-fill mr-2"></i>
                        Edit
                     </NavLink>
                     {
                        event.status === "request" && (
                           <>
                              <button onClick={() => approve(event._id)} className="btn btn-sm btn-success mr-2">
                                 <i className="bi bi-check-circle-fill mr-2"></i>
                                 Approve</button>
                              <button onClick={() => approvePublish(event._id)} className="btn btn-sm btn-info mr-2">
                                 <i className="bi bi-cursor-fill mr-2"></i>
                                 Approve & Publish
                              </button>
                              <button onClick={() => reject(event._id)} className="btn btn-sm btn-danger">
                                 <i className="bi bi-x-circle-fill mr-2"></i>
                                 Reject
                              </button>
                           </>
                        )
                     }

                     {
                        event.status === "approve" && (
                           <>
                              {
                                 event.isPublish && (
                                    <button onClick={() => unpublish(event._id)} className="btn btn-sm btn-warning mr-2">
                                       <i className="bi bi-cursor-fill mr-2"></i>
                                       Arsipkan
                                    </button>
                                 )
                              }

                              {
                                 !event.isPublish && (
                                    <button onClick={() => publish(event._id)} className="btn btn-sm btn-success mr-2">
                                       <i className="bi bi-cursor-fill mr-2"></i>
                                       Publish
                                    </button>
                                 )
                              }
                              <button onClick={() => reject(event._id)} className="btn btn-sm btn-danger">
                                 <i className="bi bi-x-circle-fill mr-2"></i>
                                 Reject
                              </button>
                           </>
                        )
                     }

                     {
                        event.status === "reject" && (
                           <>
                              <button onClick={() => approve(event._id)} className="btn btn-sm btn-success mr-2">
                                 <i className="bi bi-check-circle-fill mr-2"></i>
                                 Approve</button>
                              <button onClick={() => approvePublish(event._id)} className="btn btn-sm btn-info mr-2">
                                 <i className="bi bi-cursor-fill"></i>
                                 Approve & Publish
                              </button>
                           </>
                        )
                     }
                  </div>
               )
            }
         </div>
         {!isLoading && <h1 className="event-title mt-4">{event.title}</h1>}
         <div className="row mt-4">
            <div className="col-12 col-md-4">
               {
                  !isLoading && (
                     <div className="media-renderer">
                        <img src={event.image.includes('firebase') ? event.image : process.env.REACT_APP_CDN_URL + '/get/' + event.image} className="image card-event-image" alt="" />
                     </div>
                  )
               }

               <div className="hr-text"><span>Bagikan Event</span></div>

               {
                  !isLoading && (
                     <div className="d-flex justify-content-between">
                        <a href={`https://wa.me/?text=Ayo ikuti event ini ${linkEvent}`} className="item-category" style={{ padding: '5px', marginRight: '0', color: '#000' }}>
                           <div className="icon-category" style={{ marginRight: '0' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308a.961.961 0 0 0-.371.1 1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.729 2.729 0 0 0 6.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.971 2.742.214.213.423.427.648.626a9.448 9.448 0 0 0 3.84 2.046l.569.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.401-.621a.498.498 0 0 0-.177-.041.482.482 0 0 0-.378.127v-.002c-.005 0-.072.057-.795.933a.35.35 0 0 1-.368.13 1.416 1.416 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.109l-.005-.002a6.01 6.01 0 0 1-1.57-1c-.126-.11-.243-.23-.363-.346a6.296 6.296 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41a4.38 4.38 0 0 0 .263-.373c.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004z" fill="rgba(47,204,113,1)" /></svg>
                           </div>
                           <span style={{ fontSize: '13px' }}>Whatsapp</span>
                        </a>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${linkEvent}`} className="item-category" style={{ padding: '5px', marginRight: '0', color: '#000' }}>
                           <div className="icon-category" style={{ marginRight: '0' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" fill="rgba(50,152,219,1)" /></svg>
                           </div>
                           <span style={{ fontSize: '13px' }}>Facebook</span>
                        </a>
                        <button className="item-category" style={{ padding: '5px', marginRight: '0', color: '#000' }}>
                           <div className="icon-category" style={{ marginRight: '0' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z" /></svg>
                           </div>
                           <span style={{ fontSize: '13px' }}>Copy Link</span>
                        </button>

                     </div>
                  )
               }

               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '15px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
            </div>
            <div className="col-12 col-md-8">
               <div className="event-top">
                  {
                     isLoading && (
                        <div className="loading" style={{ width: '100%', padding: '15px' }}>
                           <div className="loading-title"></div>
                           <div className="loading-animation-wrapper">
                              <div className="loading-animation"></div>
                           </div>
                        </div>
                     )
                  }
                  <div className="row">
                     <div className="col-12 col-md-6">
                        <h6>Penyelenggara</h6>
                        {
                           !isLoading && (
                              <span href="/" className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }}>
                                 <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                    <img src={event.author.logo.includes('firebase') ? event.author.logo : process.env.REACT_APP_CDN_URL + '/get/' + event.author.logo} alt="" className="radius" />
                                 </div>
                                 <span>{event.author.name}</span>
                              </span>
                           )
                        }
                        {
                           isLoading && (
                              <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                 <div className="loading-title"></div>
                                 <div className="loading-animation-wrapper">
                                    <div className="loading-animation"></div>
                                 </div>
                              </div>
                           )
                        }
                        <h6 className="mt-3">Tipe Event</h6>
                        {
                           !isLoading && (
                              <>
                                 {(event.isOnline && event.isOffline) && (
                                    <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }}>
                                       <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" fill="rgba(1,74,115,1)" /></svg>
                                       </div>
                                       <span>Event Online & Offline</span>
                                    </span>
                                 )}

                                 {(event.isOnline && !event.isOffline) && (
                                    <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }}>
                                       <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" fill="rgba(1,74,115,1)" /></svg>
                                       </div>
                                       <span>Event Online</span>
                                    </span>
                                 )}

                                 {(!event.isOnline && event.isOffline) && (
                                    <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }}>
                                       <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" fill="rgba(1,74,115,1)" /></svg>
                                       </div>
                                       <span>Event Offline</span>
                                    </span>
                                 )}
                              </>
                           )
                        }

                        {
                           isLoading && (
                              <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                 <div className="loading-title"></div>
                                 <div className="loading-animation-wrapper">
                                    <div className="loading-animation"></div>
                                 </div>
                              </div>
                           )
                        }

                        <h6 className="mt-3">Status Event</h6>
                        {
                           isLoading && (
                              <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                 <div className="loading-title"></div>
                                 <div className="loading-animation-wrapper">
                                    <div className="loading-animation"></div>
                                 </div>
                              </div>
                           )
                        }

                        {!isLoading && getStatus(event.status)}
                        <span className="ml-1 mr-1">-</span>
                        {event.status === "approve" && (
                           <>
                              {event.isPublish ? <span className="badge badge-success">Event Publish</span> : <span className="badge badge-danger">Event Diarsipkan</span>}
                           </>
                        )}

                     </div>
                     <div className="col-12 col-md-6">
                        <h6>Waktu Penyelenggaraan</h6>
                        {
                           !isLoading && (
                              <>
                                 {
                                    event.schedules.map((s, i) => {
                                       return (
                                          <div className="mb-2" key={i}>
                                             <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px', marginRight: '10px' }}>
                                                <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                                      <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm0 4h2v2H6v-2zm4-4h8v2h-8v-2zm0 4h5v2h-5v-2z" fill="rgba(1,74,115,1)"></path>
                                                   </svg>
                                                </div>
                                                <span>{moment(s.date).format('LL')}</span>
                                             </span>
                                             <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }}>
                                                <div className="icon-category" style={{ width: '18px', height: '18px' }}>
                                                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                                                      <path fill="none" d="M0 0h24v24H0z"></path>
                                                      <path d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zM4 9v10h16V9H4zm2 2h2v2H6v-2zm0 4h2v2H6v-2zm4-4h8v2h-8v-2zm0 4h5v2h-5v-2z" fill="rgba(1,74,115,1)"></path>
                                                   </svg>
                                                </div>
                                                <span>{s.startTime} - {s.endTime}</span>
                                             </span>
                                          </div>
                                       )
                                    })
                                 }
                              </>
                           )
                        }

                        {
                           isLoading && (
                              <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                 <div className="loading-title"></div>
                                 <div className="loading-animation-wrapper">
                                    <div className="loading-animation"></div>
                                 </div>
                              </div>
                           )
                        }

                        <h6 className="mt-3">Kategori Event</h6>
                        {
                           isLoading && (
                              <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                 <div className="loading-title"></div>
                                 <div className="loading-animation-wrapper">
                                    <div className="loading-animation"></div>
                                 </div>
                              </div>
                           )
                        }

                        {
                           !isLoading && (
                              <>
                                 {event.categories.length === 0 && "-"}
                                 {event.categories.map((category, key) => {
                                    return (
                                       <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '12px', padding: '5px' }} key={key}>
                                          <span>{category}</span>
                                       </span>
                                    )
                                 })}
                              </>
                           )
                        }

                     </div>
                  </div>
               </div>
               <hr />
               <div className="item-3">
                  <Tabs
                     activeKey={key}
                     onSelect={(k) => setKey(k)}
                     className="item-3 mt-4"
                  >
                     <Tab eventKey="desc" title="Detail Event">
                        <div className="event-box">
                           <div className="event-desc mt-2 mb-4">
                              <h2 className="heading-title mb-3">Deskripsi</h2>
                              {!isLoading && parse(event.description)}
                              {
                                 isLoading && (
                                    <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                       <div className="loading-card-content">
                                          <div className="loading-title"></div>
                                          <div className="loading-title-small mt-4"></div>
                                          <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                          <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                          <div className="loading-title-small mt-2" style={{ width: '100%' }}></div>
                                       </div>
                                       <div className="loading-animation-wrapper">
                                          <div className="loading-animation"></div>
                                       </div>
                                    </div>
                                 )
                              }
                           </div>
                           <div className="event-desc mt-2">
                              {!isLoading && (
                                 <>
                                    {
                                       event.termcondition !== "" && (
                                          <>
                                             <h2 className="heading-title mb-3">Syarat dan Ketentuan</h2>
                                             {parse(event.termcondition)}
                                          </>
                                       )
                                    }

                                 </>
                              )}
                              {
                                 isLoading && (
                                    <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                       <div className="loading-card-content">
                                          <div className="loading-title"></div>
                                          <div className="loading-title-small mt-4"></div>
                                          <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                          <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                          <div className="loading-title-small mt-2" style={{ width: '100%' }}></div>
                                       </div>
                                       <div className="loading-animation-wrapper">
                                          <div className="loading-animation"></div>
                                       </div>
                                    </div>
                                 )
                              }
                           </div>
                           <div className="event-desc mt-2">
                              <h2 className="heading-title mb-3">SKP Event</h2>
                              {!isLoading && (
                                 <>
                                    {event.skpPoint.map((skp, key) => {
                                       return (
                                          <span className="item-category" style={{ textDecoration: 'none', color: '#000', fontSize: '15px', padding: '5px' }} key={key}>
                                             <span>SKP {skp.name} - {skp.amount !== 0 ? skp.amount : 0}</span>
                                          </span>
                                       )
                                    })}
                                 </>
                              )}
                           </div>
                        </div>
                     </Tab>
                     <Tab eventKey="ticket" title="Kategori Tiket">
                        <div className="event-box">
                           {!isLoading && <Ticket tickets={event.tickets} increment={increment} decrement={decrement} />}
                           {
                              isLoading && (
                                 <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                    <div className="loading-card-content">
                                       <div className="loading-title"></div>
                                       <div className="loading-title-small mt-2"></div>
                                       <div className="loading-title mt-4" style={{ width: '25%' }}></div>
                                       <div className="loading-title-small mt-2" style={{ width: '15%' }}></div>
                                    </div>
                                    <div className="loading-animation-wrapper">
                                       <div className="loading-animation"></div>
                                    </div>
                                 </div>
                              )
                           }
                        </div>
                     </Tab>
                     <Tab eventKey="profile" title="Informasi Data Diri">
                        <div className="event-box">
                           {
                              isLoading && (
                                 <div className="loading" style={{ width: '100%', padding: '10px' }}>
                                    <div className="loading-card-content">
                                       <div className="loading-title"></div>
                                       <div className="loading-title-small mt-4"></div>
                                       <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                       <div className="loading-title-small mt-2" style={{ width: '75%' }}></div>
                                       <div className="loading-title-small mt-2" style={{ width: '100%' }}></div>
                                    </div>
                                    <div className="loading-animation-wrapper">
                                       <div className="loading-animation"></div>
                                    </div>
                                 </div>
                              )
                           }
                           {!isLoading && (
                              <>
                                 {event.contact !== undefined && (
                                    <div className="row">
                                       <div className="col-6 col-md-4">
                                          <h6>Nama Lengkap</h6>
                                          <p className="text-muted mb-0">
                                             <small>{event.contact.name}</small>
                                          </p>
                                       </div>
                                       <div className="col-6 col-md-4">
                                          <h6>No WA/Telepon</h6>
                                          <p className="text-muted mb-0">
                                             <small>{event.contact.phone}</small>
                                          </p>
                                       </div>
                                       <div className="col-6 col-md-4">
                                          <h6>Jabatan Kepanitiaan</h6>
                                          <p className="text-muted mb-0">
                                             <small>{event.contact.level}</small>
                                          </p>
                                       </div>
                                    </div>
                                 )}
                              </>
                           )}
                        </div>
                     </Tab>
                  </Tabs>
               </div>
            </div>
         </div>
      </Container>
   )
}

export default EventDetail
