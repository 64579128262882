import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Row, Table } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

function DetailAccount() {
   moment.locale('id');
   let { id } = useParams();
   const [account, setAccount] = useState({});
   const [banks, setBanks] = useState([]);
   const [events, setEvents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [previewImage, setpreviewImage] = useState('');

   useEffect(() => {
      const getData = async (id) => {
         return await axios.get('/user/' + id)
            .then(resp => {
               console.log(resp.data);
               setAccount(resp.data.user);
               setBanks(resp.data.banks);
               setEvents(resp.data.events);
               setIsLoading(true);
            }).catch(err => {
               console.log(err.response);
            })
      }
      getData(id);
   }, [id])

   return (
      <>
         <div className="block mt-4">
            <div className="block-content">
               <h4>Personal Information</h4>
               <div className="dash"></div>
               <Row className="align-items-center">
                  <div className="col-12 col-md-4">
                     <div className="mt-2 mb-4 text-center">
                        {account.avatar !== "" && <img src={account.avatar} alt="" className="radius" width="200" />}

                        <p style={{ fontSize: '13px', marginBottom: '0', marginTop: '10px', fontWeight: 'bold' }}>Joined since</p>
                        <p style={{ fontSize: '13px', marginBottom: '0' }}>{moment(account.createdAt).format('LLLL')}</p>
                     </div>
                  </div>
                  <div className="col-12 col-md-8">
                     <div className="row">
                        <div className="col-12 col-md-6">
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Full Name</h6>
                              <span style={{ fontSize: '15px' }}>{account.name !== "" ? account.name : '-'}</span>
                           </div>
                           <div className="dash"></div>
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Username</h6>
                              <span style={{ fontSize: '15px' }}>{account.username !== "" ? account.username : '-'}</span>
                           </div>
                           <div className="dash"></div>
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Nomor Telepon</h6>
                              <span style={{ fontSize: '15px' }}>{account.phone !== "" ? account.phone : '-'}</span>
                           </div>
                        </div>
                        <div className="col-12 col-md-6">
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Email</h6>
                              <span style={{ fontSize: '15px' }}>{account.email !== "" ? account.email : '-'}</span>
                           </div>
                           <div className="dash"></div>
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Platform</h6>
                              <span style={{ fontSize: '15px' }}>{account.platform !== "" ? account.platform : '-'}</span>
                           </div>
                           <div className="dash"></div>
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Verified</h6>
                              {account.isVerified && <span style={{ fontSize: '15px' }} className="badge badge-success">Yes</span>}
                              {!account.isVerified && <span style={{ fontSize: '15px' }} className="badge badge-danger">No</span>}
                           </div>
                        </div>
                     </div>
                     <div className="dash"></div>
                     <div className="row">
                        <div className="col-12">
                           <div className="form-group">
                              <h6 style={{ fontWeight: '600' }}>Address</h6>
                              <span style={{ fontSize: '15px' }}>{account.address !== "" ? account.address : '-'}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </Row>
            </div>
         </div>
         <div className="block mt-4">
            <div className="block-content">
               <h4>Bank Account Information</h4>
               <div className="dash"></div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Bank Name</th>
                        <th>Account Number</th>
                        <th>Account Name</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        banks.length === 0 && (
                           <tr>
                              <td colSpan="5" className="text-center">
                                 User don't have Bank Account
                              </td>
                           </tr>
                        )
                     }
                     {
                        banks.map((b, i) => {
                           return (
                              <tr key={i}>
                                 <td>{i + 1}</td>
                                 <td>{b.bankName}</td>
                                 <td>{b.accountNumber}</td>
                                 <td>{b.accountName}</td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </Table>
            </div>
         </div>
         <div className="block mt-4">
            <div className="block-content">
               <h4>Event Information</h4>
               <div className="dash"></div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Title Event</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th className="text-center" style={{ width: '150px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {events.length === 0 && (
                        <tr>
                           <td colSpan="5" className="text-center">User don't have Event</td>
                        </tr>
                     )}
                     {
                        events.map((e, i) => {
                           return (
                              <tr key={i}>
                                 <td>{i + 1}</td>
                                 <td>{e.title}</td>
                                 <td>{e.category}</td>
                                 <td className="text-center">
                                    <span className={`badge ${e.status === "request" ? "badge-warning" : ''} ${e.status === "approve" ? "badge-success" : ''} ${e.status === "reject" ? "badge-danger" : ''} `}>{e.status}</span>
                                 </td>
                                 <td>{moment(e.createdAt).format('LLLL')}</td>
                                 <td className="text-center">
                                    <NavLink to={`/event/${e.eid}/${e.slug}`} className="btn btn-sm btn-dark text-white">Detail Event</NavLink>
                                 </td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </Table>
            </div>
         </div>
      </>
   )
}

export default DetailAccount
