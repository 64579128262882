import React from 'react'
import Router from './router/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

let API_URL;
switch (process.env.REACT_APP_ENV) {
  case 'production':
    API_URL = process.env.REACT_APP_API_URL;
    break;
  case 'sandbox':
    API_URL = process.env.REACT_APP_API_URL_SANDBOX;
    break;
  default:
    API_URL = process.env.REACT_APP_API_URL_DEV;
    break;
}

axios.defaults.baseURL = API_URL;
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios.get('/session')
    .then().catch(err => {
      localStorage.removeItem('token');
      localStorage.removeItem('profile');
      document.location = "/login";
    })
}

function App() {
  return (
    <Router />
  );
}

export default App;
