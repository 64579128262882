import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import BaseApp from '../components/BaseApp'

function LogEmail() {

   const [data, setData] = useState([]);

   useEffect(() => {
      async function getData() {
         await axios.get('/log/email/?provider=sendinblue')
            .then(resp => {
               console.log(resp.data);
               setData(resp.data);
            }).catch(error => console.log(error));
      }
      getData();
   }, []);

   const getStatus = (status) => {
      if (status === "delivered") {
         return <div className="badge badge-success">{status}</div>
      } else if (status === "unsubscribed" || status === "complained" || status === "blocked" || status === "spam") {
         return <div className="badge badge-warning">{status}</div>
      } else if (status === "clicked") {
         return <div className="badge badge-primary">{status}</div>
      } else if (status === "opened") {
         return <div className="badge badge-info">{status}</div>
      } else if (status === "failed" || status === "soft_bounce" || status === "hard_bounce" || status === "invalid_email" || status === "error" || status === "deferred") {
         return <div className="badge badge-danger">{status}</div>
      } else {
         return status;
      }
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <Table bordered>
                  <thead>
                     <tr>
                        <th>Email</th>
                        <th>Subject</th>
                        <th className="text-center" style={{ width: '150px' }}>Status</th>
                        <th style={{ width: '300px' }}>Created At</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data.length === 0 && (
                        <tr>
                           <td colSpan="5" className="text-center">Empty Log Email</td>
                        </tr>
                     )}
                     {data.map(email => {
                        return (
                           <tr key={email._id}>
                              <td>{email.data.email}</td>
                              <td>{email.data.subject !== null ? email.data.subject : '-'}</td>
                              <td className="text-center" >{getStatus(email.data.status)}</td>
                              <td>{moment(email.createdAt).format('LLLL')}</td>
                           </tr>
                        )
                     })}
                  </tbody>
               </Table>
            </div>
         </div>
      </BaseApp>
   )
}

export default LogEmail
