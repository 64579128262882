import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp';
import { Table, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import { NavLink } from 'react-router-dom'

function Voucher() {

   const [voucher, setVoucher] = useState([]);
   const [modalAddVoucher, setModalAddVoucher] = useState(false);
   const [code, setCode] = useState(null);
   const [desc, setDesc] = useState(null);
   const [type, setType] = useState(null);
   const [value, setValue] = useState(null);
   const [period, setPeriod] = useState(null);
   const [start, setStart] = useState(null);
   const [end, setEnd] = useState(null);
   const [isActive, setIsActive] = useState(false);
   const [updated, setUpdated] = useState(false);
   const [quota, setQuota] = useState(0);

   useEffect(() => {
      const getData = () => {
         return axios.get('/voucher')
            .then(resp => setVoucher(resp.data))
            .catch(err => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  text: err.response.data.message,
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      }
      getData();
   }, [updated])

   const submitVoucher = (e) => {
      e.preventDefault();
      Swal.fire({
         title: 'Loading',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      })

      const data = {
         code, desc, type, value, quota,
         period, start, end, isActive
      }

      axios.post('/voucher', data)
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Voucher Created',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setTimeout(() => {
               window.location.reload();
            }, 1000);
         }).catch(err => {
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: err.response.data.message,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   const deleteVoucher = (id) => {
      Swal.fire({
         title: 'Delete this Voucher?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.delete('/voucher/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Voucher Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdated(!updated);
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.response.data.message,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center">
                  <h4>Voucher Tiketseminar</h4>
                  <button onClick={() => setModalAddVoucher(true)} className="btn btn-sm btn-success">Add Voucher</button>
               </div>
            </div>
         </div>
         <div className="block mt-2">
            <div className="block-content">
               <Table bordered>
                  <thead>
                     <tr>
                        <th>Code Voucher</th>
                        <th>Description</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Value</th>
                        <th className="text-center">Period</th>
                        <th className="text-center">Start</th>
                        <th className="text-center">End</th>
                        <th className="text-center">Quota</th>
                        <th className="text-center">Status</th>
                        <th className="text-center" style={{ width: '150px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        voucher.length === 0 && (
                           <tr>
                              <td colSpan="8" className="text-center">
                                 No Available Voucher
                              </td>
                           </tr>
                        )
                     }
                     {
                        voucher.map((item, i) => {
                           return (
                              <tr key={i}>
                                 <td>{item.code}</td>
                                 <td>{item.desc}</td>
                                 <td className="text-center">{item.type}</td>
                                 <td className="text-center">{item.value}</td>
                                 <td className="text-center">{item.period}</td>
                                 <td className="text-center">{item.start !== null ? moment(item.start).format('DD/MM/YYYY') : '-'}</td>
                                 <td className="text-center">{item.end !== null ? moment(item.end).format('DD/MM/YYYY') : '-'}</td>
                                 <td className="text-center">{item.quota}</td>
                                 <td className="text-center">
                                    {
                                       item.isActive && <span className="badge badge-success">Active</span>
                                    }
                                    {
                                       !item.isActive && <span className="badge badge-danger">Inactive</span>
                                    }
                                 </td>
                                 <td className="text-center">
                                    <NavLink to={'/voucher/' + item._id} className="btn btn-sm btn-dark text-white mr-2">
                                       <i className="bi bi-list"></i>
                                    </NavLink>
                                    <NavLink to={'/voucher/' + item._id + '/edit'} className="btn btn-sm btn-info text-white mr-2">
                                       <i className="bi bi-pencil"></i>
                                    </NavLink>
                                    <button onClick={() => deleteVoucher(item._id)} className="btn btn-sm btn-danger">
                                       <i className="bi bi-trash"></i>
                                    </button>
                                 </td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </Table>
            </div>
         </div>

         <Modal show={modalAddVoucher} onHide={() => setModalAddVoucher(false)}>
            <form onSubmit={submitVoucher}>
               <Modal.Header closeButton>
                  <Modal.Title>Create Voucher</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label className="text-small">Code Voucher</label>
                     <input type="text" className="form-control form-control-sm" onChange={(e) => setCode(e.target.value)} required />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Description</label>
                     <input type="text" className="form-control form-control-sm" onChange={(e) => setDesc(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Type</label>
                     <select onChange={(e) => setType(e.target.value)} className="form-control form-control-sm" required>
                        <option value="">-</option>
                        <option value="percent">Percent</option>
                        <option value="nominal">Nominal</option>
                     </select>
                  </div>
                  <div className="form-group">
                     <label className="text-small">Value</label>
                     <input type="number" className="form-control form-control-sm" onChange={(e) => setValue(e.target.value)} required />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Voucher Period</label>
                     <select onChange={(e) => setPeriod(e.target.value)} className="form-control form-control-sm" required>
                        <option value="">-</option>
                        <option value="lifetime">Lifetime</option>
                        <option value="range">On Range</option>
                     </select>
                  </div>
                  {
                     period === "range" && (
                        <div className="row">
                           <div className="col-12 col-md-6">
                              <div className="form-group">
                                 <label className="text-small">From</label>
                                 <input onChange={(e) => setStart(e.target.value)} type="date" className="form-control form-control-sm" required />
                              </div>
                           </div>
                           <div className="col-12 col-md-6">
                              <div className="form-group">
                                 <label className="text-small">To</label>
                                 <input onChange={(e) => setEnd(e.target.value)} type="date" className="form-control form-control-sm" required />
                              </div>
                           </div>
                        </div>
                     )
                  }
                  <div className="form-group">
                     <label className="text-small">Quota</label>
                     <input type="number" className="form-control form-control-sm" onChange={(e) => setQuota(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Status</label>
                     <select onChange={(e) => {
                        if (e.target.value === "true") {
                           setIsActive(true);
                        } else {
                           setIsActive(false);
                        }
                     }} className="form-control form-control-sm" required>
                        <option value="">-</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                     </select>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setModalAddVoucher(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </BaseApp>
   )
}

export default Voucher
