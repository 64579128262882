import React, { useEffect, useState } from 'react';
import BaseApp from '../components/BaseApp';
import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import Rupiah from '../components/Rupiah';
import Swal from 'sweetalert2';
import { NavLink } from 'react-router-dom';

function Event() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   moment.locale('id');
   const [payments, setPayments] = useState([]);
   const [totalApprove, setTotalApprove] = useState(0);
   const [totalReject, setTotalReject] = useState(0);
   const [isLoading, setIsLoading] = useState(true);
   const [updated, setUpdated] = useState(false);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/payment')
            .then(resp => {
               // console.log(resp.data);
               setPayments(resp.data.waiting);
               setTotalApprove(resp.data.approve);
               setTotalReject(resp.data.reject);
               setIsLoading(false);
            }).catch(err => console.log(err.response));
      }
      getData();
   }, [updated]);

   const showImage = (url) => {
      Swal.fire({
         imageUrl: url.includes('firebase') ? url : process.env.REACT_APP_CDN_URL + '/get/' + url,
         imageWidth: 300,
         imageAlt: 'prof of payment',
      })
   }

   const approve = (id) => {
      Swal.fire({
         title: 'Approve this Payment?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/backoffice/payment/' + id + '/approve')
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Order Paid',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setIsLoading(true);
                  setUpdated(!updated);
               }).catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     toast: true,
                     text: err.message,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const reject = (id) => {
      Swal.fire({
         title: 'Reject this Payment?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/backoffice/payment/' + id + '/reject')
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Payment Reject',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setIsLoading(true);
                  setUpdated(!updated);
               }).catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     toast: true,
                     text: err.message,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   return (
      <BaseApp>
         <Row>
            <Col>
               <div className="card-single">
                  <div>
                     <h1>{totalApprove}</h1>
                     <span>Complete Payment</span>
                  </div>
                  <div>
                     <span className="text-success"><i className="bi bi-check-circle-fill" /></span>
                  </div>
               </div>
            </Col>
            <Col>
               <NavLink to="payment/reject" className="card-single">
                  <div>
                     <h1>{totalReject}</h1>
                     <span>Reject Payment</span>
                  </div>
                  <div>
                     <span className="text-danger"><i className="bi bi-x-circle-fill" /></span>
                  </div>
               </NavLink>
            </Col>
         </Row>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Waiting Approval Payment</h5>
            </div>
            <div className="block-content">

               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <Table bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              {/* <th>Invoice</th> */}
                              <th>Name</th>
                              <th>Event Name</th>
                              <th>Payment Type</th>
                              <th className="text-center" style={{ width: '120px' }}>Amount</th>
                              <th>Image</th>
                              <th className="text-center" style={{ width: '250px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {payments.length === 0 && (
                              <tr>
                                 <td colSpan="7" className="text-center">Belum ada Pembayaran</td>
                              </tr>
                           )}

                           {
                              payments.map((p, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       {/* <td>{p.oid}</td> */}
                                       <td>{p.contact.name}</td>
                                       <td>{p.event.title}</td>
                                       <td>
                                          {p.transaction.paymentType === "bank_transfer" ? `${p.transaction.bankName} - ${p.transaction.accountName}` : ''}

                                          {p.transaction.paymentType === "qris" ? `QRIS` : ''}
                                       </td>
                                       <td>Rp. <Rupiah value={p.payment.total} /></td>
                                       <td className="text-center">
                                          <button onClick={() => {
                                             showImage(p.profOfPayment);
                                          }} className="btn btn-sm btn-dark">
                                             <i className="bi bi-image"></i>
                                          </button>
                                       </td>
                                       <td className="text-center">
                                          <NavLink to={`/payment/detail/${p._id}`} className="btn btn-sm btn-secondary text-white mr-2">Detail</NavLink>
                                          <button onClick={() => {
                                             approve(p._id)
                                          }} className="btn btn-sm btn-success mr-2">Approve</button>
                                          <button onClick={() => {
                                             reject(p._id)
                                          }} className="btn btn-sm btn-danger">Reject</button>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>
      </BaseApp>
   )
}

export default Event
