import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import BaseApp from '../BaseApp'
import Rupiah from '../Rupiah';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function Sales() {

   const [sales, setSales] = useState({ transaction: 0, income: 0, balance: 0, withdrawal: 0, platformFee: 0, uniqueCode: 0 });
   const [event, setEvent] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [disabledDownload, setDisabledDownload] = useState(false)

   useEffect(() => {
      async function getData() {
         await axios.get('/backoffice/report/sales')
            .then(resp => {
               // console.log(resp.data);
               setSales(resp.data);
               setEvent(resp.data.event);
               setIsLoading(false);
            }).catch(error => console.log(error));
      }
      getData();
   }, []);

   const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx";

   const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
   };

   const downloadReport = (id) => {
      setDisabledDownload(true);
      axios.get('/backoffice/report/order/' + id)
         .then(resp => {
            console.log(resp.data);
            exportToCSV(resp.data, "Order_" + new Date().getTime())
            setDisabledDownload(false);
         }).catch(error => {
            console.log(error)
            setDisabledDownload(false);
         });
   }

   return (
      <BaseApp>
         <div className="row mb-5">
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.transaction} /></h3>
                        <span>Total Transaksi</span>
                     </div>
                     <div>
                        <span><i className="bi bi-cash-stack"></i></span>
                     </div>
                  </div>
               )}
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.income} /></h3>
                        <span>Total Pendapatan Bersih</span>
                     </div>
                     <div>
                        <span><i className="bi bi-cash"></i></span>
                     </div>
                  </div>
               )}
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.balance} /></h3>
                        <span>Total Dana Yang Belum Ditartik</span>
                     </div>
                     <div>
                        <span><i className="bi bi-arrow-left-right"></i></span>
                     </div>
                  </div>
               )}
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.withdrawal} /></h3>
                        <span>Total Dana Yang Sudah Ditarik</span>
                     </div>
                     <div>
                        <span><i className="bi bi-arrow-left-right"></i></span>
                     </div>
                  </div>
               )}
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.platformFee} /></h3>
                        <span>Total Platform Fee</span>
                     </div>
                     <div>
                        <span><i className="bi bi-cash"></i></span>
                     </div>
                  </div>
               )}
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {!isLoading && (
                  <div className="card-single">
                     <div>
                        <h3>Rp. <Rupiah value={sales.uniqueCode} /></h3>
                        <span>Total Kode Unik</span>
                     </div>
                     <div>
                        <span><i className="bi bi-cash"></i></span>
                     </div>
                  </div>
               )}
            </div>
         </div>

         <Table className="table-small" bordered>
            <thead>
               <tr>
                  <th className="text-center" style={{ width: '25px' }}>#</th>
                  <th>Event Name</th>
                  <th className="text-center">Total Transaksi</th>
                  <th className="text-center">Pendapatan</th>
                  <th className="text-center">Platform Fee</th>
                  <th className="text-center">Kode Unik</th>
                  <th className="text-center">Voucher</th>
                  <th className="text-center">Penarikan Dana</th>
                  <th className="text-center">Action</th>
               </tr>
            </thead>
            <tbody>
               {
                  isLoading && (
                     <tr>
                        <td className="text-center" colSpan="9">
                           <div className="spinner-border" role="status">
                              {/* <span className="visually-hidden">Loading...</span> */}
                           </div>
                        </td>
                     </tr>
                  )
               }

               {!isLoading && (
                  <>
                     {event.map((e, key) => {
                        return (
                           <tr key={key}>
                              <td className="text-center">{key + 1}</td>
                              <td>{e.name.length > 50 ? e.name.substr(0, 50) + '...' : e.name}</td>
                              <td className="text-center">Rp. <Rupiah value={e.total} /></td>
                              <td className="text-center">Rp. <Rupiah value={e.income} /></td>
                              <td className="text-center">Rp. <Rupiah value={e.fee} /></td>
                              <td className="text-center">Rp. <Rupiah value={e.uniqueCode} /></td>
                              <td className="text-center">Rp. <Rupiah value={e.voucher} /></td>
                              <td className="text-center">Rp. <Rupiah value={e.withdrawal} /></td>
                              <td className="text-center">
                                 <button onClick={() => downloadReport(e._id)} className="btn btn-sm btn-success" disabled={disabledDownload}>
                                    <i className="bi bi-download"></i>
                                 </button>
                              </td>
                           </tr>
                        )
                     })}
                  </>
               )}
            </tbody>
         </Table>
      </BaseApp>
   )
}

export default Sales
