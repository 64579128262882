import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Modal, Table } from 'react-bootstrap'
import BaseApp from '../components/BaseApp'
import Swal from 'sweetalert2';

function Withdrawal() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [modalDetail, setModalDetail] = useState(false);
   const [dataDetail, setDataDetail] = useState(null);

   useEffect(() => {
      async function getData() {
         return axios.get('/withdrawal')
            .then(resp => {
               console.log(resp.data)
               setData(resp.data);
               setIsLoading(false);
            }).catch(error => console.log(error));
      }
      getData();
   }, []);

   const approve = (id) => {
      Swal.fire({
         title: 'Approve this Request?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/withdrawal/approve/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Approve Success',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const reject = (id) => {
      Swal.fire({
         title: 'Reject this Request?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.put('/withdrawal/reject/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Reject Success',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  console.log(err.response)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <h3 className="mb-0">Withdrawal</h3>
               <div className="form-group mt-3">
                  <input type="text" class="form-control form-control-sm" />
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Event Name</th>
                        <th>Bank - Account</th>
                        <th className="text-center">Amount</th>
                        <th>Created By</th>
                        <th className="text-center">Status</th>
                        <th className="text-center" style={{ width: '150px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        !isLoading && (
                           <>
                              {
                                 data.map((value, index) => {
                                    return (
                                       <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{value.event.title}</td>
                                          <td>{value.bankName} - {value.accountName}</td>
                                          <td>{value.amount}</td>
                                          <td>{value.user.name}</td>
                                          <td className="text-center">
                                             {value.status === "waiting" ? <span className="badge badge-warning">Waiting</span> : ''}
                                             {value.status === "approve" ? <span className="badge badge-success">Approve</span> : ''}
                                             {value.status === "reject" ? <span className="badge badge-danger">Reject</span> : ''}
                                          </td>
                                          <td className="text-center">
                                             {
                                                value.status === "waiting" && (
                                                   <>
                                                      <button onClick={() => approve(value._id)} className="btn btn-sm btn-success mr-2">
                                                         <i className="bi bi-check-circle-fill" />
                                                      </button>
                                                      <button onClick={() => reject(value._id)} className="btn btn-sm btn-danger mr-2">
                                                         <i className="bi bi-x-circle-fill" />
                                                      </button>
                                                   </>
                                                )
                                             }
                                             <button onClick={() => {
                                                setDataDetail(value);
                                                setModalDetail(true);
                                             }} className="btn btn-sm btn-secondary">
                                                <i className="bi bi-justify" />
                                             </button>
                                          </td>
                                       </tr>
                                    )
                                 })
                              }
                              {
                                 data.length === 0 && (
                                    <tr>
                                       <td colSpan="6" className="text-center">Withdrawal Not Available</td>
                                    </tr>
                                 )
                              }
                           </>
                        )
                     }
                  </tbody>
               </Table>
            </div>
         </div>

         <Modal show={modalDetail} onHide={() => setModalDetail(false)}>
            <Modal.Header closeButton>
               <Modal.Title>Detail Withdrawal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {
                  dataDetail !== null && (
                     <>
                        <div className="form-group">
                           <label><b>Event Name</b></label>
                           <p style={{ fontSize: '14px' }}>{dataDetail.event.title}</p>
                        </div>
                        <div className="form-group">
                           <label><b>Bank Name</b></label>
                           <p style={{ fontSize: '14px' }}>{dataDetail.bankName}</p>
                        </div>
                        <div className="form-group">
                           <label><b>Account Name</b></label>
                           <p style={{ fontSize: '14px' }}>{dataDetail.accountName}</p>
                        </div>
                        <div className="form-group">
                           <label><b>Account Number</b></label>
                           <p style={{ fontSize: '14px' }}>{dataDetail.accountNumber}</p>
                        </div>
                        <div className="form-group">
                           <label><b>Amount</b></label>
                           <p style={{ fontSize: '14px' }}>Rp. {dataDetail.amount}</p>
                        </div>
                        <div className="form-group">
                           <label><b>Created By</b></label>
                           <p style={{ fontSize: '14px' }}>{dataDetail.user.name}</p>
                        </div>
                     </>
                  )
               }
            </Modal.Body>
         </Modal>
      </BaseApp>
   )
}

export default Withdrawal
