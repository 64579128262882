import React, { useEffect, useState } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import data from '../../config/data';

function Bank() {

   const [banks, setBanks] = useState([]);
   const [updated, setUpdated] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [showModal, setShowModal] = useState(false);

   const [bankName, setBankName] = useState("");
   const [accountNumber, setAccountNumber] = useState("");
   const [accountName, setAccountName] = useState("");
   const [alias, setAlias] = useState("");
   const [icon, setIcon] = useState("");
   const [previewIcon, setPreviewIcon] = useState("");

   const [bank, setBank] = useState({});
   const [modalEdit, setModalEdit] = useState(false);

   let APP_URL;
   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         APP_URL = "https://tiketseminar.com/";
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
         break;
      case 'sandbox':
         APP_URL = "https://sandbox.tiketseminar.com/";
         break;
      default:
         APP_URL = "http://localhost:3000/";
         break;
   }

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/bank/tiketseminar')
            .then(resp => {
               // console.log(resp.data);
               setBanks(resp.data);
               setIsLoading(false);
            })
      }
      getData();
   }, [updated]);

   const handleIconChange = (event) => {
      const file = event.target.files[0];
      const AcceptAbleExtention = ["png", "jpeg", "jpg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            setIcon(resp.data.code)
         }).catch(error => {
            console.log(error.response)
         })

      let icon = URL.createObjectURL(file);
      setPreviewIcon(icon);
      setIcon(file);
   }

   const handleCloseModal = () => {
      setShowModal(false);
      setPreviewIcon("");
      setIcon("");
      setBankName("");
      setAccountName("");
      setAccountNumber("");
      setAlias("");
   }

   const handleShowModal = async () => {
      setShowModal(true);

   }

   const handleSubmit = (e) => {
      e.preventDefault();

      Swal.fire({
         title: 'Please Waiting...',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });

      axios.post('/bank', { bankName, accountNumber, accountName, alias, icon, owner: 'tiketseminar' })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Bank Created',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            handleCloseModal(false);
            setUpdated(!updated);
         })
         .catch(err => {
            console.log(err)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               toast: true,
               text: err.mesage,
               showConfirmButton: false,
               timer: 1500
            });
            setShowModal(false);
         });
   }

   const deleteBank = (id) => {
      Swal.fire({
         title: 'Remove this Bank?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Mohon Tunggu Sebentar',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/bank/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Bank Deleted',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdated(!updated);
               }).catch(err => {
                  console.log(err)
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     toast: true,
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })

   }

   const handleSubmitEdit = (e) => {
      e.preventDefault();

      Swal.fire({
         title: 'Please Waiting...',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });

      axios.put('/bank/' + bank._id, { ...bank })
         .then(resp => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'Bank Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });
            setModalEdit(false);
            setBank({});
            setUpdated(!updated);
         })
         .catch(err => {
            console.log(err)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               toast: true,
               text: err.mesage,
               showConfirmButton: false,
               timer: 1500
            });
            setShowModal(false);
         });
   }


   return (
      <>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Bank Account</h5>
               <div className="block-options">
                  <Button onClick={handleShowModal} size="sm" variant="success"><i className="bi bi-plus-circle-fill text-white mr-2"></i> Add Bank</Button>
               </div>
            </div>
            <div className="block-content">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <Table bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              <th>Bank Name</th>
                              <th>Account Name</th>
                              <th>Account Number</th>
                              <th className="text-center">Mutasibank</th>
                              <th className="text-center" style={{ width: '150px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {
                              banks.length === 0 && (
                                 <tr>
                                    <td colSpan="5" className="text-center">
                                       Bank Not Available
                                    </td>
                                 </tr>
                              )
                           }

                           {
                              banks.map((b, i) => {
                                 return (
                                    <tr>
                                       <td>{i + 1}</td>
                                       <td>{b.bankName}</td>
                                       <td>{b.accountName}</td>
                                       <td>{b.accountNumber}</td>
                                       <td className="text-center">{b.isMutasibankActive ? <span className="badge badge-success">Enable</span> : <span className="badge badge-danger">Disable</span>}</td>
                                       <td className="text-center">
                                          <Button onClick={() => {
                                             setBank(b);
                                             setModalEdit(true);
                                          }} variant="dark" size="sm" className="mr-2">Edit</Button>
                                          <Button onClick={() => deleteBank(b._id)} variant="danger" size="sm">Delete</Button>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>

         <Modal show={showModal} onHide={handleCloseModal}>
            <form onSubmit={handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title>Add Bank</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>Bank Name</label>
                     <select className="form-control form-control-sm" onChange={(e) => setBankName(e.target.value)} >
                        <option defaultValue=""></option>
                        {
                           data.bank.map((b, i) => {
                              return <option value={b.label} key={i}>{b.label}</option>
                           })
                        }
                     </select>
                  </div>
                  <div className="form-group">
                     <label>Alias Name</label>
                     <input className="form-control form-control-sm" type="text" onChange={(e) => setAlias(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label>Account Name</label>
                     <input className="form-control form-control-sm" type="text" onChange={(e) => setAccountName(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label>Account Number</label>
                     <input className="form-control form-control-sm" type="number" onChange={(e) => setAccountNumber(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Logo Bank</label>
                     <input type="file" className="form-control form-control-sm" onChange={(e) => handleIconChange(e)} />
                  </div>
                  {
                     (previewIcon !== "" && <div className="form-group mt-2">
                        <img src={previewIcon} alt="" style={{ height: '70px', objectFit: 'cover' }} />
                     </div>)
                  }
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" size="sm" onClick={handleCloseModal}>
                     Close
                  </Button>
                  <Button variant="primary" size="sm" type="submit">
                     Add Bank
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

         <Modal show={modalEdit} onHide={() => {
            setBank({});
            setModalEdit(false)
         }}>
            <form onSubmit={handleSubmitEdit}>
               <Modal.Header closeButton>
                  <Modal.Title>Add Bank</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>Alias Name</label>
                     <input className="form-control form-control-sm" type="text" defaultValue={bank.alias} onChange={(e) => {
                        let data = { ...bank };
                        data.alias = e.target.value;
                        setBank(data);
                     }} />
                  </div>
                  <div className="form-group">
                     <label>Account Name</label>
                     <input className="form-control form-control-sm" type="text" defaultValue={bank.accountName} onChange={(e) => {
                        let data = { ...bank };
                        data.accountName = e.target.value;
                        setBank(data);
                     }} />
                  </div>
                  <div className="form-group">
                     <label>Account Number</label>
                     <input className="form-control form-control-sm" type="number" defaultValue={bank.accountNumber} onChange={(e) => {
                        let data = { ...bank };
                        data.accountNumber = e.target.value;
                        setBank(data);
                     }} />
                  </div>
                  <div className="form-group">
                     <label>Mutasibank</label>
                     <select className="form-control form-control-sm" defaultValue={bank.isMutasibankActive} onChange={(e) => {
                        let data = { ...bank };
                        data.isMutasibankActive = e.target.value == "false" ? false : true;
                        setBank(data);
                     }}>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                     </select>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" size="sm" onClick={() => {
                     setBank({});
                     setModalEdit(false)
                  }}>
                     Close
                  </Button>
                  <Button variant="primary" size="sm" type="submit">
                     Update Bank
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </>
   )
}

export default Bank
