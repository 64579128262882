import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Rupiah from '../Rupiah';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';

function DetailOrder() {

   let { id } = useParams();
   const [order, setOrder] = useState({});
   const [event, setEvent] = useState({});
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async (id) => {
         return await axios.get('/order/detail/' + id)
            .then(resp => {
               setOrder(resp.data.order);
               setEvent(resp.data.event);
               setIsLoading(false);
            }).catch(error => console.log(error.response))
      }
      getData(id);
   }, [id]);

   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
      default:
         break;
   }

   const handleIconChange = (event) => {

      const file = event.target.files[0];
      const AcceptAbleExtention = ["png", "jpeg", "jpg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            Swal.fire({
               imageUrl: process.env.REACT_APP_CDN_URL + '/get/' + resp.data.code,
               imageWidth: 300,
               imageAlt: 'prof of payment',
               showCancelButton: true,
               confirmButtonText: 'Upload',
            }).then((result) => {
               if (result.isConfirmed) {
                  axios.put('/web/upload-payment/' + id, { status: 'payment_verification', profOfPayment: resp.data.code })
                     .then(result => {
                        Swal.fire('Uploaded!', '', 'success')
                        setTimeout(() => {
                           document.location = '/payment';
                        }, 1000);
                     }).catch(err => {
                        Swal.fire('Error While Uploading', '', 'error')
                        console.log(err.response);
                     })

               } else {
                  Swal.fire('Changes are not saved', '', 'info')
               }
            })

         }).catch(error => {
            console.log(error.response)
         })
   }

   return (
      <>
         {
            !isLoading && (
               <>
                  <div className="block mt-4">
                     <div className="block-content">
                        <h4>Order Information</h4>
                        <div className="dash"></div>
                        <div className="row">
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>No Invoice</h6>
                              <span style={{ fontSize: '15px' }}>{order.oid}</span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Total Payment</h6>
                              {(order.status === "complete" || order.status === "payment_verification" || order.status === "reject") ? <NavLink to={`/payment/detail/${order._id}`} style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline', color: 'blue' }}>Rp. <Rupiah value={order.payment.total} /></NavLink> : <span style={{ fontSize: '15px' }}>Rp. <Rupiah value={order.payment.total} /></span>}
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Payment Method</h6>
                              <span style={{ fontSize: '15px' }}>{order.payment.type !== undefined ? order.payment.type : '-'}</span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Status Transaction</h6>
                              <span style={{ fontSize: '15px' }}><span className={`badge ${order.status === "pending" ? "badge-warning" : ''} ${order.status === "complete" ? "badge-success" : ''} ${order.status === "expired" ? "badge-danger" : ''} ${order.status === "payment_verification" ? "badge-info" : ''} `}>{order.status}</span></span>
                           </div>
                        </div>
                        <div className="row mt-4">
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Contact Name</h6>
                              <span style={{ fontSize: '15px' }}>{order.contact.name}</span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Contact Email</h6>
                              <span style={{ fontSize: '15px' }}>{order.contact.email}</span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Contact Phone</h6>
                              <span style={{ fontSize: '15px' }}>{order.contact.phone}</span>
                           </div>
                           {(order.status === "pending" || order.status === "expired") && (
                              <div className="col-6 col-md-3">
                                 <h6 style={{ fontWeight: '600' }}>Upload Bukti Transfer</h6>
                                 {/* <img src={process.env.REACT_APP_CDN_URL + '/get/' + "dev-0f0d0f0c100c0d100c05120c05130c130f0f13584b585349104958504d515c4958134d535a"} alt="" width="100%" /> */}
                                 {/* {image !== null && } */}
                                 <input onChange={e => handleIconChange(e)} type="file" className="form-control form-control-sm" />
                              </div>
                           )}
                        </div>
                        <div className="dash"></div>
                        <div className="row">
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Event Name</h6>
                              <a href={`/event/${event.slug}`} className="text-link" style={{ fontSize: '15px' }}>{event.title}</a>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Event Start</h6>
                              <span style={{ fontSize: '15px' }}>{moment(event.schedules[0].date).format('LL')}</span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Event Type</h6>
                              <span style={{ fontSize: '15px' }}>
                                 {(event.isOnline && event.isOffline) && 'Online, Offline'}
                                 {(event.isOnline && !event.isOffline) && 'Online'}
                                 {(!event.isOnline && event.isOffline) && 'Offline'}
                              </span>
                           </div>
                           <div className="col-6 col-md-3">
                              <h6 style={{ fontWeight: '600' }}>Author</h6>
                              <span style={{ fontSize: '15px' }}>{event.author.name}</span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="block mt-4">
                     <div className="block-content">
                        <h4>Participant List</h4>
                        <Table bordered>
                           <thead>
                              <tr>
                                 <th style={{ width: '25px' }}>#</th>
                                 <th>Name</th>
                                 <th>Email</th>
                                 <th>Phone</th>
                                 <th>Created At</th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 order.participants.map((item, i) => {
                                    return (
                                       <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item.email}</td>
                                          <td>{item.phone}</td>
                                          <td>{moment(item.createdAt).format('LLLL')}</td>
                                       </tr>
                                    )
                                 })
                              }
                           </tbody>
                        </Table>
                     </div>
                  </div>
               </>
            )
         }
      </>
   )
}

export default DetailOrder
