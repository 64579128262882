import React, { useState, useEffect } from 'react'
import BaseApp from '../BaseApp'
import { Table, Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Broadcast() {

   const [broadcast, setBroadcast] = useState([]);

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">Broadcast</h3>
                  <NavLink to="/broadcast/create" className="btn btn-sm btn-success text-white">New Broadcast</NavLink>
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th>Subject</th>
                        <th className="text-center">Type Broadcast</th>
                        <th className="text-center" style={{ width: '200px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {broadcast.length === 0 && (
                        <tr>
                           <td className="text-center" colSpan={3}>Empty Data Broadcast</td>
                        </tr>
                     )}
                  </tbody>
               </Table>
            </div>
         </div>
      </BaseApp>
   )
}

export default Broadcast
