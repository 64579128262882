import React, { useState, useEffect } from 'react'
import BaseApp from '../BaseApp'
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { ExportToExcel } from '../ExportToExcel';

function Usage() {
   let { id } = useParams();
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      function getData(id) {
         axios.get('/voucher/usage/' + id)
            .then(resp => {
               let list = [];
               resp.data.forEach(element => {
                  list.push({
                     _id: element._id,
                     order: element.order.oid,
                     event: element.event.title,
                     name: element.order.contact.name,
                     amount: element.amount,
                     createdAt: element.createdAt
                  })
               })
               setData(list);
               setIsLoading(false);
            })
            .catch(error => console.log(error));
      }
      getData(id);
   }, [id])

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="row align-items-center mb-3">
                  <div className="col">
                     <h4 className="mb-0">List Usage Voucher</h4>
                  </div>
                  <div className="col text-right">
                     <ExportToExcel apiData={data} fileName={`Voucher_log_${new Date().getTime()}`} />
                  </div>
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>No Order</th>
                        <th>Event</th>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Created At</th>
                     </tr>
                  </thead>
                  <tbody>
                     {isLoading && (
                        <tr>
                           <td className="text-center" colSpan="6">
                              <div className="spinner-border" role="status">
                                 {/* <span className="visually-hidden">Loading...</span> */}
                              </div>
                           </td>
                        </tr>
                     )}

                     {(!isLoading && data.length === 0) && (
                        <tr>
                           <td className="text-center" colSpan="6">
                              Empty Voucher Log
                           </td>
                        </tr>
                     )}

                     {data.map((d, i) => {
                        return (
                           <tr key={d._id}>
                              <td>{i + 1}</td>
                              <td>{d.order}</td>
                              <td>{d.event}</td>
                              <td>{d.name}</td>
                              <td>{d.amount}</td>
                              <td>{moment(d.createdAt).format('lll')}</td>
                           </tr>
                        )
                     })}
                  </tbody>
               </Table>
            </div>
         </div>
      </BaseApp>
   )
}

export default Usage
