import React from 'react';
import { Editor } from "@tinymce/tinymce-react";

function TinyTextarea({ value = "", editorRef = {} }) {
   return (
      <Editor
         onInit={(evt, editor) => (editorRef.current = editor)}
         apiKey="n9gj7n0c9psupy4xdvhd91y7h640yaqlyuns9v7pp2d7gds3"
         initialValue={value}
         init={{
            height: 200,
            menubar: false,
            plugins: [
               "advlist autolink lists link image",
               "charmap print preview anchor help",
               "searchreplace visualblocks code",
               "insertdatetime media table paste wordcount ",
            ],
            toolbar: `undo redo | formatselect | bold italic link | alignleft aligncenter alignright | bullist numlist outdent indent | help`,
         }}
      //  onEditorChange={(content) => {
      //     console.log(content);
      //     onEditorChange(content);
      //  }}
      />
   )
}

export default TinyTextarea
