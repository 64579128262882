import React, { useState } from 'react';
import logo from '../assets/logo.png';
import axios from 'axios';
import Swal from 'sweetalert2';

function Login() {

   const [email, setEmail] = useState(null);
   const [password, setPassword] = useState(null);

   const token = localStorage.getItem('token');
   if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      document.location = "/";
   }

   const handleSubmit = (e) => {
      e.preventDefault();

      if (email !== null && password !== null) {
         Swal.fire({
            title: 'Please Waiting..',
            allowOutsideClick: false,
            didOpen: () => {
               Swal.showLoading()
            }
         });
         axios.post('/auth/backoffice', { email, password })
            .then(resp => {
               console.log(resp.data)
               if (resp.data.isSuccess) {
                  localStorage.setItem('token', resp.data.token);
                  localStorage.setItem('profile', JSON.stringify(resp.data.data));
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Login Success',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location = "/";
               } else {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: resp.data.message,
                     showConfirmButton: false,
                     timer: 1500
                  })
               }
            }).catch(err => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Login Error',
                  showConfirmButton: false,
                  timer: 1500
               })
               console.log(err);
            })
      }
   }

   return (
      <div className="limiter">
         <div className="container-login">
            <div className="wrap-login">
               <div className="login-pic">
                  <img src={logo} alt="IMG" />
               </div>
               <form className="login-form validate-form" onSubmit={handleSubmit}>
                  <h2 className="text-center mb-5">Backoffice Login</h2>
                  <div className="form-group">
                     <label htmlFor="email">Email</label>
                     <input className="form-control" onChange={(e) => setEmail(e.target.value)} id="email" type="email" />
                  </div>
                  <div className="form-group mt-2">
                     <label htmlFor="password">Password</label>
                     <input className="form-control" onChange={(e) => setPassword(e.target.value)} id="password" type="password" />
                  </div>
                  <div className="form-group mt-4">
                     <button type="submit" className="btn btn-success btn-block">Login</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

export default Login
