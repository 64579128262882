import axios from 'axios';
import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp'
import { ExportToExcel } from '../components/ExportToExcel';

function Contact() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const getData = async () => {
         await axios.get('/backoffice/contact')
            .then(resp => {
               console.log(resp.data);
               setData(resp.data);
               setIsLoading(false);
            }).catch(error => console.log(error))
      }
      getData();
   }, [])


   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between">
                  <h3 className="mb-0">Contact</h3>
                  {!isLoading && <ExportToExcel apiData={data} fileName="Contact" />}
               </div>
            </div>
         </div>
      </BaseApp>
   )
}

export default Contact
