import React, { useState, useEffect } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { NavLink } from 'react-router-dom'

function List() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = () => {
         return axios.get('/certificate')
            .then(resp => {
               console.log(resp.data)
               setIsLoading(false);
               setData(resp.data)
            })
            .catch(err => console.log(err.message));
      }
      getData();
   }, []);

   const deleteCertificate = (id) => {

      Swal.fire({
         title: 'Delete this Certificate Event?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/certificate/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const deleteExternalCertificate = (title) => {

      Swal.fire({
         title: 'Delete this Certificate Event?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/certificate/external/' + title)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  })
                  document.location.reload();
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     showConfirmButton: false,
                     timer: 1500
                  })
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   return (
      <>
         <div className="form-group mt-3 mb-3">
            <input type="text" className="form-control" />
         </div>
         <Table bordered>
            <thead>
               <tr>
                  <th style={{ width: '25px' }}>#</th>
                  <th>Event Name</th>
                  <th>Author</th>
                  <th className="text-center" style={{ width: '250px' }}>Actions</th>
               </tr>
            </thead>
            <tbody>

               {
                  isLoading && (
                     <tr>
                        <td colSpan="5" className="text-center">
                           <Spinner animation="border" />
                        </td>
                     </tr>
                  )
               }

               {
                  !isLoading && (
                     <>
                        {
                           data.length === 0 && (
                              <tr>
                                 <td colSpan="5" className="text-center">Certificate Event not available</td>
                              </tr>
                           )
                        }
                        {
                           data.map((item, key) => {
                              return (
                                 <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{item.event !== null ? item.event.title : item.external.title}</td>
                                    <td>{item.event !== null ? item.event.author.name : '-'}</td>
                                    <td className="text-center">
                                       {item.event !== null ? (
                                          <>
                                             <NavLink to={`/certificate/${item.event._id}`} className="btn btn-sm btn-dark mr-3 text-white">List Participant</NavLink>
                                             <button onClick={() => deleteCertificate(item.event._id)} className="btn btn-sm btn-danger">Delete</button>
                                          </>
                                       ) : (
                                          <>
                                             <NavLink to={`/certificate/external/${item.external.title}`} className="btn btn-sm btn-dark mr-3 text-white">List Participant</NavLink>
                                             <button onClick={() => deleteExternalCertificate(item.external.title)} className="btn btn-sm btn-danger">Delete</button>
                                          </>
                                       )}
                                    </td>
                                 </tr>
                              )
                           })
                        }
                     </>
                  )
               }
            </tbody>
         </Table>
      </>
   )
}

export default List
