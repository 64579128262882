import React from 'react'
import Rupiah from './Rupiah';
import moment from 'moment';

function Ticket({ tickets, increment, decrement }) {

   return (
      <div className="ticket-list">
         {
            tickets.map((item, i) => {
               return <div className="ticket-card" key={i}>
                  <div className="ticket-header d-flex align-items-center">
                     <h5 className="ticket-card-title flex-grow-1">{item.name}</h5>
                  </div>
                  <div className="ticket-desc d-flex justify-content-between">
                     <span>{item.description}</span>
                     <span>Sisa Kuota {item.quota}</span>
                  </div>
                  <div className="ticket-footer mt-2">
                     <span style={{ fontSize: '12px', textAlign: 'right' }}>{moment(item.saleStartDate).format('LLL')} - {moment(item.saleEndDate).format('LLL')}</span>
                     <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                           <div className="ticket-price">
                              <h5 className="mb-0">Harga</h5>
                              <span className="price">Rp. <Rupiah value={item.price === '' ? 0 : item.price} /></span>
                           </div>
                        </div>
                        <div className="ticket-buy d-flex align-items-center">
                           {
                              (moment().format() >= moment(item.saleStartDate).format() && moment().format() <= moment(item.saleEndDate).format()) ? (
                                 <div className="counter">
                                    <button className="counter-btn" onClick={() => { decrement(item) }}>-</button>
                                    <span id={`counter-${i + 1}`}>0</span>
                                    <button className="counter-btn" id="increment1" onClick={() => { increment(item) }}>+</button>
                                 </div>
                              ) : <span className="badge badge-danger" style={{ padding: '8px' }}>Penjualan Tiket Tutup</span>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            })
         }
      </div>
   )
}

export default Ticket
