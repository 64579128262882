import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Account from '../pages/Account';
import Dashboard from '../pages/Dashboard';
import Event from '../pages/Event';
import Order from '../pages/Order';
import Website from '../pages/Website';
import DetailEvent from '../pages/DetailEvent';
import WaitingEvent from '../components/event/WaitingEvent';
import ApproveEvent from '../components/event/ApproveEvent';
import RejectEvent from '../components/event/RejectEvent';
import AllEvent from '../components/event/AllEvent';
import Login from '../pages/Login';
import EditEvent from '../pages/EditEvent';
import Payment from '../pages/Payment';
import Config from '../pages/Config';
import Participant from '../components/event/Participant';
import Voucher from '../pages/Voucher';
import Reminder from '../pages/Reminder';
import Certificate from '../pages/Certificate';
import Withdrawal from '../pages/Withdrawal';
import Reject from '../components/payment/Reject';
import Report from '../pages/Report';
import Broadcast from '../pages/Broadcast';
import DetailPayment from '../components/payment/Detail';
import Sales from '../components/report/Sales';
import ReportEvent from "../components/report/Event";
import Contact from '../pages/Contact';
import LogEmail from '../pages/LogEmail';
import Dictionary from '../pages/Dictionary';
import Usage from '../components/voucher/Usage';
import Edit from '../components/voucher/Edit';

function index() {
   return (
      <BrowserRouter>
         <Switch>
            <Route exact path="/">
               <Dashboard />
            </Route>
            <Route exact path="/contact">
               <Contact />
            </Route>
            <Route exact path="/event">
               <Event />
            </Route>
            <Route exact path="/event/edit/:id">
               <EditEvent />
            </Route>
            <Route exact path="/event/all">
               <AllEvent />
            </Route>
            <Route exact path="/event/waiting">
               <WaitingEvent />
            </Route>
            <Route exact path="/event/approve">
               <ApproveEvent />
            </Route>
            <Route exact path="/event/reject">
               <RejectEvent />
            </Route>
            <Route exact path="/event/participant/:id">
               <Participant />
            </Route>
            <Route exact path="/event/:slug">
               <DetailEvent />
            </Route>
            <Route path="/order">
               <Order />
            </Route>
            <Route exact path="/payment">
               <Payment />
            </Route>
            <Route exact path="/payment/detail/:id">
               <DetailPayment />
            </Route>
            <Route exact path="/payment/reject">
               <Reject />
            </Route>
            <Route exact path="/config">
               <Config />
            </Route>
            <Route path="/website">
               <Website />
            </Route>
            <Route path="/account">
               <Account />
            </Route>
            <Route exact path="/voucher">
               <Voucher />
            </Route>
            <Route exact path="/voucher/:id">
               <Usage />
            </Route>
            <Route exact path="/voucher/:id/edit">
               <Edit />
            </Route>
            <Route excat path="/reminder">
               <Reminder />
            </Route>
            <Route excat path="/withdrawal">
               <Withdrawal />
            </Route>
            <Route path="/certificate">
               <Certificate />
            </Route>
            <Route path="/broadcast">
               <Broadcast />
            </Route>
            <Route exact path="/report">
               <Report />
            </Route>
            <Route exact path="/report/sales">
               <Sales />
            </Route>
            <Route exact path="/report/event">
               <ReportEvent />
            </Route>
            <Route exact path="/log/email">
               <LogEmail />
            </Route>
            <Route exact path="/dictionary">
               <Dictionary />
            </Route>
            <Route exact path="/login">
               <Login />
            </Route>
         </Switch>
      </BrowserRouter>
   )
}

export default index
