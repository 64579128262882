import React, { useState, useEffect } from 'react'
import { Modal, Table, Button } from 'react-bootstrap'
import BaseApp from '../components/BaseApp'
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import axios from 'axios';
import Swal from 'sweetalert2';

function Dictionary() {

   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [modal, setModal] = useState(false);
   const [name, setName] = useState("");
   const [value, setValue] = useState([]);
   const [id, setId] = useState(null);
   const [editedName, setEditedName] = useState(true);

   useEffect(() => {
      function getData() {
         axios.get('/dictionary')
            .then(resp => {
               setData(resp.data);
               setIsLoading(false);
            }).catch(error => {
               console.log(error);
               alert('error')
            })
      }
      getData();
   }, [])

   const handleClose = () => {
      setId(null);
      setName("");
      setValue([]);
      setModal(false);
   }

   const create = () => {
      if (name !== "" && value.length > 0) {
         axios.post('/dictionary', { name, value })
            .then(resp => {
               alert('success');
               window.location.reload();
            }).catch(error => {
               console.log(error);
               alert('error')
            })
      } else {
         alert('Please fill name & value')
      }
   }

   const update = () => {
      if (name !== "" && value.length > 0) {
         axios.patch('/dictionary/' + id, { name, value })
            .then(resp => {
               alert('success');
               window.location.reload();
            }).catch(error => {
               console.log(error);
               alert('error')
            })
      } else {
         alert('Please fill name & value')
      }
   }

   const detele = (id) => {
      Swal.fire({
         title: 'Delete Dictionary?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/dictionary/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Dictionary Deleted',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setTimeout(() => {
                     window.location.reload();
                  }, 1000);
               }).catch(err => {
                  // console.log(err);
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Error Deleting Dictionary',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between mb-4">
                  <h4 className="mb-0">List Dictionary</h4>
                  <button onClick={() => setModal(true)} className="btn btn-sm btn-success">Create Dictionary</button>
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '200px' }}>Name</th>
                        <th>Value</th>
                        <th className="text-center" style={{ width: '100px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {isLoading && (
                        <tr>
                           <td className="text-center" colSpan="3">
                              <div className="spinner-border" role="status">
                              </div>
                           </td>
                        </tr>
                     )}

                     {!isLoading && (
                        <>
                           {data.map(dict => {
                              return (
                                 <tr key={dict._id}>
                                    <td>{dict.name}</td>
                                    <td>{dict.value.join(', ')}</td>
                                    <td className="text-center">
                                       <button onClick={() => {
                                          setId(dict._id);
                                          setName(dict.name);
                                          setValue(dict.value);
                                          setEditedName(dict.delete);
                                          setModal(true);
                                       }} className="btn btn-sm btn-info">
                                          <i className="bi bi-pencil-square"></i>
                                       </button>
                                       {dict.delete && <button onClick={() => detele(dict._id)} className="btn btn-sm btn-danger ml-3">
                                          <i className="bi bi-trash-fill"></i>
                                       </button>}
                                    </td>
                                 </tr>
                              )
                           })}

                           {data.length === 0 && (
                              <tr>
                                 <td className="text-center" colSpan="3">
                                    Empty Dictionary
                                 </td>
                              </tr>
                           )}
                        </>
                     )}
                  </tbody>
               </Table>
            </div>
         </div>

         <Modal
            show={modal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
         >
            <Modal.Header closeButton>
               <Modal.Title>{id === null ? 'Create Dictionary' : 'Edit Dictionary'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="form-group">
                  <label>Name</label>
                  {editedName ? <input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} /> : <input className="form-control" type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} disabled />}
               </div>
               <div className="form-group">
                  <label>Value</label>
                  <ReactTagInput
                     tags={value}
                     onChange={(newTags) => setValue(newTags)} />
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Close
               </Button>
               {id === null ? <Button onClick={create} variant="primary">Submit</Button> : <Button onClick={update} variant="primary">Update</Button>}
            </Modal.Footer>
         </Modal>
      </BaseApp>
   )
}

export default Dictionary
