import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

function InputTicket({ schedules, tickets, remove, handle, addSubCategoryTicket, deleteSubCategoryTicket, handleChangeSubCategory, handleDoctorCategory, handleChangeScheduleRelation }) {

   let listSchedule = [];
   schedules.forEach((element, key) => {
      listSchedule.push({ id: key, value: element.date, label: element.date });
   });

   return (
      tickets.map((t, i) => {
         return (
            <div className="card dash py-2 px-2 mt-3" key={i}>
               <button className="btn btn-sm btn-danger btn-floating-right" type="button" onClick={() => remove(i)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" fill="rgba(255,255,255,1)" /></svg>
               </button>
               <input type="hidden" name="id" defaultValue={t.id} />
               <div className="row mt-1">
                  <div className="col-12 col-md-5">
                     <div className="form-group mb-0">
                        <label className="text-small">Nama Kategori Tiket</label>
                        <input name="name" defaultValue={t.name} onChange={(e) => handle(e, 'name', i)} className="form-control form-control-sm" type="text" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="form-group mb-0">
                        <label className="text-small">Kuota</label>
                        <input name="quota" defaultValue={t.quota} onChange={(e) => handle(e, 'quota', i)} className="form-control form-control-sm" type="number" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-4">
                     <div className="form-group mb-0">
                        <label className="text-small">Harga</label>
                        <CurrencyInput
                           prefix="Rp. "
                           name="price"
                           allowDecimals={false}
                           allowNegativeValue={false}
                           className="form-control form-control-sm"
                           defaultValue={parseInt(t.price)}
                           onValueChange={(value, name) => handle(value, name, i)}
                        />
                     </div>
                  </div>
               </div>
               <div className="form-group mt-1 mb-1">
                  <label className="text-small">Deskripsi</label>
                  <input name="description" defaultValue={t.description} onChange={(e) => handle(e, 'desc', i)} className="form-control form-control-sm" type="text" required />
               </div>
               <div className="row mt-1">
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Tanggal Mulai Penjualan</label>
                        <input name="saleStartDate" value={t.saleStartDate} onChange={(e) => handle(e, 'saleStartDate', i)} className="form-control form-control-sm" type="datetime-local" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Tanggal Selesai Penjualan</label>
                        <input name="saleEndDate" value={t.saleEndDate} onChange={(e) => handle(e, 'saleEndDate', i)} className="form-control form-control-sm" type="datetime-local" required />
                     </div>
                  </div>
               </div>

               <div className="row mt-3 align-items-center">
                  <div className="col-6">
                     <label className="text-small">Sub Kategori</label>
                  </div>
                  <div className="col-6 text-right">
                     <span className="item-category" style={{ marginRight: '0', padding: '5px' }} onClick={() => addSubCategoryTicket(i)}>
                        <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="#6c757d " /></svg>
                     </span>
                  </div>
               </div>

               {
                  t.category !== undefined && (
                     <>
                        {
                           t.category.map((cat, index) => {
                              return (
                                 <div className="d-flex justify-space-between mt-2" key={index}>
                                    <button className="btn btn-sm btn-danger mr-1" onClick={() => deleteSubCategoryTicket(i, index)}>
                                       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" fill="rgba(255,255,255,1)" /></svg>
                                    </button>
                                    <input className="form-control form-control-sm mr-2" type="text" onChange={(e) => handleChangeSubCategory(e, i, index)} name="name" defaultValue={`${cat.name !== "" ? cat.name : ""}`} />
                                    <input type="number" className="form-control form-control-sm" name="quota" placeholder="quota" onChange={(e) => handleChangeSubCategory(e, i, index)} defaultValue={cat.quota !== undefined ? cat.quota : 0} />
                                 </div>
                              )
                           })
                        }
                     </>
                  )
               }
               {(t.category.length > 1 && t.selectSubCategory !== undefined) && (
                  <div className="form-group mt-1 mb-1">
                     <label className="text-small">Jumlah Sub Kategori Yang Wajib Dipilih</label>
                     <input name="selectSubCategory" defaultValue={t.selectSubCategory} onChange={(e) => handle(e, 'selectSubCategory', i)} className="form-control form-control-sm" type="text" required />
                  </div>
               )}
               <div className="row mt-3">
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Minimal Pembelian</label>
                        <input name="minimum" value={t.minimum ? t.minimum : 1} onChange={(e) => handle(e, 'minimum', i)} className="form-control form-control-sm" type="number" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Maksimal Pembelian</label>
                        <input name="maximum" value={t.maximum ? t.maximum : 20} onChange={(e) => handle(e, 'maximum', i)} className="form-control form-control-sm" type="number" required />
                     </div>
                  </div>
               </div>
               <div className="row mt-3">
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Beli</label>
                        <input name="buy" value={t.buy ? t.buy : 0} onChange={(e) => handle(e, 'buy', i)} className="form-control form-control-sm" type="number" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Gratis</label>
                        <input name="get" value={t.get ? t.get : 0} onChange={(e) => handle(e, 'get', i)} className="form-control form-control-sm" type="number" required />
                     </div>
                  </div>
               </div>
               {(t.forDoctor !== undefined && t.forStudent !== undefined) && (
                  <div className="row mt-3 align-items-center">
                     <div className="col-12">
                        <label className="text-small">Pengaturan Tambahan</label>
                     </div>
                     <div className="col-12">
                        <div className='d-flex justify-content-between'>
                           <div>
                              {!t.forDoctor && (
                                 <>
                                    {!t.forStudent && <svg onClick={() => handleDoctorCategory(i, 'forStudent', true)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                    {t.forStudent && <svg onClick={() => handleDoctorCategory(i, 'forStudent', false)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                    <span className="ml-2" style={{ fontSize: '13px' }}>Khusus Mahasiswa</span>
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="col-12">
                        <div className='d-flex justify-content-between align-items-center'>
                           <div>
                              {!t.forStudent && (
                                 <>
                                    {!t.forDoctor && <svg onClick={() => handleDoctorCategory(i, 'forDoctor', true)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                    {t.forDoctor && <svg onClick={() => handleDoctorCategory(i, 'forDoctor', false)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>}

                                    <span className="ml-2" style={{ fontSize: '13px' }}>Khusus Dokter</span>
                                 </>
                              )}

                           </div>
                           {t.forDoctor && (
                              <div>
                                 <div className="form-check form-check-inline">
                                    <input className="form-check-input" onChange={(e) => handleDoctorCategory(i, 'generalDoctor', e.target.checked)} type="checkbox" id="general" defaultChecked={t.generalDoctor} value="general" />
                                    <label className="form-check-label" style={{ fontSize: '13px' }} htmlFor="general">Dokter Umum</label>
                                 </div>
                                 <div className="form-check form-check-inline">
                                    <input className="form-check-input" onChange={(e) => handleDoctorCategory(i, 'specialistDoctor', e.target.checked)} type="checkbox" id="specialist" value="spesialist" defaultChecked={t.specialistDoctor} />
                                    <label className="form-check-label" style={{ fontSize: '13px' }} htmlFor="specialist">Dokter Spesialis</label>
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               )}
               <hr style={{ marginBottom: '5px' }} />

               <div className="form-group mb-0">
                  {(t.hidden !== undefined && t.hidden) ? (
                     <svg onClick={() => handle(false, 'hidden', i)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 5h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="rgba(11,31,58,1)" /></svg>

                  ) : (
                     <svg onClick={() => handle(true, 'hidden', i)} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M8 7a5 5 0 1 0 0 10h8a5 5 0 0 0 0-10H8zm0-2h8a7 7 0 0 1 0 14H8A7 7 0 0 1 8 5zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" fill="rgba(11,31,58,1)" /></svg>
                  )}
                  <span className="ml-2" style={{ fontSize: '13px' }}>Nonaktif Kategori</span>
               </div>
               <hr className="mt-1 mb-1" />
               <div className="form-group mt-2 mb-1">
                  <label className="text-small">Relasi Jadwal Event</label>
                  <Select
                     closeMenuOnSelect={false}
                     isMulti
                     value={t.schedule}
                     options={listSchedule}
                     placeholder="Pilih Jadwal"
                     onChange={(value) => {
                        handleChangeScheduleRelation(i, value)
                     }}
                     classNamePrefix="select"
                  />
               </div>
            </div>
         )
      })
   )
}

export default InputTicket
