import axios from 'axios';
import React, { useState, useEffect } from 'react'
import BaseApp from '../BaseApp';
import { useParams, NavLink } from 'react-router-dom';
import { Table, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ExportToExcel } from '../ExportToExcel'

function Participant() {

   let { id } = useParams();
   const [participants, setParticipants] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [updated, setUpdated] = useState(false);
   const [modalEdit, setModalEdit] = useState(false);
   const [dataEdit, setDataEdit] = useState({});
   const [totalSold, setTotalSold] = useState(0);
   const [totalQuota, setTotalQuota] = useState(0);
   const [category, setCategory] = useState([]);
   const [dataFilter, setDataFilter] = useState([]);
   const [modalTesting, setModalTesting] = useState(false);
   const [nameTesting, setNameTesting] = useState(null);
   const [emailTesting, setEmailTesting] = useState(null);
   const [dataExcel, setDataExcel] = useState([]);
   const [transaction, setTransaction] = useState(0);

   const handleCloseInputModal = () => {
      setModalEdit(false);
   }

   useEffect(() => {
      const getData = async (id) => {
         return await axios.get('/backoffice/event/' + id + '/participant')
            .then(resp => {
               // console.log(resp.data);
               setParticipants(resp.data.tickets);
               setTotalSold(resp.data.tickets.length);
               setDataFilter(resp.data.tickets);
               setTotalQuota(resp.data.quota);
               setCategory(resp.data.category);
               setTransaction(resp.data.transaction);
               let list = [];
               resp.data.tickets.forEach(element => {
                  list.push({ ...element.additional, category: element.category, sub_category: element.subCategory.join(',') });
               });
               console.log(list)
               setDataExcel(list);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData(id);
   }, [id, updated]);

   const sendEticket = (id) => {
      Swal.fire({
         title: 'Resend Eticket?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.get('/ticket/send/' + id)
               .then(resp => {
                  // console.log(resp.data);
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'E-Ticket Resend',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
               }).catch(err => {
                  // console.log(err);
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Error Sending E-ticket',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const deleteTicket = (id) => {
      Swal.fire({
         title: 'Delete Eticket?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting...',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });

            axios.delete('/ticket/' + id)
               .then(resp => {
                  setUpdated(!updated);
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'E-Ticket Deleted',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
               }).catch(err => {
                  // console.log(err);
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     text: 'Error Deleting E-ticket',
                     toast: true,
                     showConfirmButton: false,
                     timer: 1500
                  });
               })
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const handleEditData = (e) => {
      e.preventDefault();
      axios.put('/ticket/' + dataEdit._id, { ...dataEdit, subCategory: dataEdit.subCategory.split("|") })
         .then(resp => {
            setModalEdit(false);
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               text: 'E-Ticket Updated',
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });

            setTimeout(() => {
               window.location.reload();
            }, 1000);
         }).catch(error => {
            setModalEdit(false);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               text: error.response.data.message,
               toast: true,
               showConfirmButton: false,
               timer: 1500
            });

            console.log(error.response)
         })
   }

   const search = (value) => {
      const data = dataFilter.filter(item => {
         return item.name.toLowerCase().includes(value.toLowerCase()) || item.email.toLowerCase().includes(value.toLowerCase()) || item.phone.toLowerCase().includes(value)
      });
      if (value === "") {
         setDataFilter(participants);
      } else {
         setDataFilter(data);
      }
   }

   const submitTesting = (e) => {
      e.preventDefault();
      if (nameTesting !== null && emailTesting !== null) {
         Swal.fire({
            title: 'Please Waiting...',
            allowOutsideClick: false,
            didOpen: () => {
               Swal.showLoading()
            }
         });

         axios.post('/ticket/send/' + id, { name: nameTesting, email: emailTesting })
            .then(resp => {
               // console.log(resp.data);
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'E-Ticket Test Send',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            }).catch(err => {
               // console.log(err);
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  text: 'Error Sending E-ticket',
                  toast: true,
                  showConfirmButton: false,
                  timer: 1500
               });
            })
      }
   }

   return (
      <BaseApp>
         <div className="row mb-3">
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h1>{transaction}</h1>
                     <span>Total Transaksi Berhasil</span>
                  </div>
                  <div>
                     <span><i className="bi bi-people-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h1>{totalSold}</h1>
                     <span>Total Peserta Event</span>
                  </div>
                  <div>
                     <span><i className="bi bi-people-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h1>{totalQuota}</h1>
                     <span>Total Sisa Kuota Keseluruhan</span>
                  </div>
                  <div>
                     <span><i className="bi bi-graph-down"></i></span>
                  </div>
               </div>
            </div>
         </div>
         <div className="block mb-3">
            <div className="block-content">
               <h5 className="mb-3">Total Tiket Per Kategori</h5>
               {category.map((cat, key) => {
                  return (
                     <div className="d-flex justify-content-between divider" key={key}>
                        <p className="mb-0 text-bold">{cat.name}</p>
                        <span className="text-muted">{cat.sold}/{cat.quota}</span>
                     </div>
                  )
               })}
            </div>
         </div>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center">
                  <div className="form-group mb-3">
                     <input className="form-control form-control-sm" type="text" onChange={e => search(e.target.value)} />
                  </div>
                  <div className="align-item-center">
                     <button className="btn btn-sm btn-dark mr-2">Test E-Ticket</button>
                     <ExportToExcel apiData={dataExcel} fileName={`List_Peserta_${new Date().getTime()}`} />
                  </div>
               </div>
               <Table bordered>
                  <thead>
                     <tr>
                        <th style={{ width: '25px' }}>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Category</th>
                        <th>Created At</th>
                        <th className="text-center" style={{ width: '250px' }}>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        !isLoading && (
                           <>
                              {
                                 participants.length === 0 && (
                                    <tr>
                                       <td colSpan="6" className="text-center">Participant Not Available</td>
                                    </tr>
                                 )
                              }
                              {
                                 dataFilter.map((item, i) => {
                                    return (
                                       <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>{item.name}</td>
                                          <td>{item.email}</td>
                                          <td>{item.phone}</td>
                                          <td>{item.category}</td>
                                          <td>{moment(item.createdAt).format('LL')}</td>
                                          <td className="text-center">
                                             <button onClick={() => {
                                                setModalEdit(true);
                                                setDataEdit(item);
                                             }} className="btn btn-sm btn-info mr-3"> <i className="bi bi-pencil"></i></button>
                                             <button onClick={() => sendEticket(item._id)} className="btn btn-sm btn-dark mr-3">
                                                <i className="bi bi-cursor"></i>
                                             </button>
                                             <NavLink to={`/order/${item.oid}/detail`} className="btn btn-sm btn-warning mr-3">
                                                <i className="bi bi-list"></i>
                                             </NavLink>
                                             <button onClick={() => deleteTicket(item._id)} className="btn btn-sm btn-danger">
                                                <i className="bi bi-trash-fill"></i>
                                             </button>
                                          </td>
                                       </tr>
                                    )
                                 })
                              }
                           </>
                        )
                     }
                  </tbody>
               </Table>
            </div>
         </div>

         <Modal show={modalEdit} onHide={handleCloseInputModal}>
            <form onSubmit={handleEditData}>
               <Modal.Header closeButton>
                  <Modal.Title>Edit Data</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>Name</label>
                     <input onChange={(e) => {
                        let additionalData = dataEdit.additional;
                        additionalData.name = e.target.value;
                        setDataEdit({ ...dataEdit, name: e.target.value, additional: additionalData })
                     }} className="form-control form-control-sm" type="text" defaultValue={dataEdit.name} />
                  </div>
                  <div className="form-group">
                     <label>Email</label>
                     <input onChange={(e) => {
                        let additionalData = dataEdit.additional;
                        additionalData.email = e.target.value;
                        setDataEdit({ ...dataEdit, email: e.target.value, additional: additionalData })
                     }} className="form-control form-control-sm" type="email" defaultValue={dataEdit.email} />
                  </div>
                  <div className="form-group">
                     <label>Phone</label>
                     <input onChange={(e) => {
                        let additionalData = dataEdit.additional;
                        additionalData.phone = e.target.value;
                        setDataEdit({ ...dataEdit, phone: e.target.value, additional: additionalData })
                     }} className="form-control form-control-sm" type="text" defaultValue={dataEdit.phone} />
                  </div>
                  <div className="form-group">
                     <label>Category</label>
                     <input onChange={(e) => {
                        let additionalData = dataEdit.additional;
                        additionalData.category = e.target.value;
                        setDataEdit({ ...dataEdit, category: e.target.value, additional: additionalData })
                     }} className="form-control form-control-sm" type="text" defaultValue={dataEdit.category} />
                  </div>
                  <div className="form-group">
                     <label>Sub Category</label>
                     <input onChange={(e) => {
                        let additionalData = dataEdit.additional;
                        additionalData.subCategory = e.target.value;
                        setDataEdit({ ...dataEdit, subCategory: e.target.value, additional: additionalData })
                     }} className="form-control form-control-sm" type="text" defaultValue={Array.isArray(dataEdit.subCategory) ? dataEdit.subCategory.join('|') : ""} />
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={handleCloseInputModal}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Update
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

         <Modal show={modalTesting} onHide={() => setModalTesting(false)}>
            <form onSubmit={submitTesting}>
               <Modal.Header closeButton>
                  <Modal.Title>Testing Reminder</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>Name</label>
                     <input type="text" className="form-control" onChange={e => setNameTesting(e.target.value)} />
                  </div>
                  <div className="form-group">
                     <label>Email</label>
                     <input type="email" className="form-control" onChange={e => setEmailTesting(e.target.value)} />
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setModalTesting(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

      </BaseApp>
   )
}

export default Participant
