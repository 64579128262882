import React from 'react'

function InputSchedule({ schedules, remove, handle, online, offline, addLink, handleChangeLink, deleteLink }) {
   return (
      schedules.map((s, i) => {
         return (
            <div className="card dash py-2 px-2 mt-3" key={i}>
               <button className="btn btn-sm btn-danger btn-floating-right" type="button" onClick={() => remove(i)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" fill="rgba(255,255,255,1)" /></svg>
               </button>
               <div className="row mt-1">
                  <div className="col-12 col-md-6">
                     <div className="form-group mb-0">
                        <label className="text-small">Tanggal</label>
                        <input name="date" value={s.date} onChange={(e) => handle(e, i)} className="form-control form-control-sm" type="date" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="form-group mb-0">
                        <label className="text-small">Jam Mulai</label>
                        <input name="startTime" value={s.startTime} onChange={(e) => handle(e, i)} className="form-control form-control-sm" type="time" required />
                     </div>
                  </div>
                  <div className="col-12 col-md-3">
                     <div className="form-group mb-0">
                        <label className="text-small">Jam Selesai</label>
                        <input name="endTime" value={s.endTime} onChange={(e) => handle(e, i)} className="form-control form-control-sm" type="time" required />
                     </div>
                  </div>
               </div>
               {offline && (
                  <div className="form-group mt-2">
                     <label className="text-small">Lokasi Event Offline</label>
                     <input value={s.location} name="location" onChange={(e) => handle(e, i)} className="form-control form-control-sm" required />
                  </div>
               )}
               {
                  online && (
                     <>
                        {
                           s.links === undefined && (
                              <>
                                 <div className="row mt-1">
                                    <div className="col-12">
                                       <div className="form-group mb-0">
                                          <label className="text-small">Label</label>
                                          <input type="text" className="form-control form-control-sm" name="label" value={s.label} onChange={(e) => handle(e, i)} placeholder="zoom / youtube / google meet / yang lain" required />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="row mt-1">
                                    <div className="col-12">
                                       <div className="form-group mb-0">
                                          <label className="text-small">Link Event</label>
                                          <input type="text" className="form-control form-control-sm" name="link" value={s.link} onChange={(e) => handle(e, i)} required />
                                       </div>
                                    </div>
                                 </div>
                              </>
                           )
                        }
                        {
                           s.links && (
                              <>
                                 <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                                    <label className="text-small mb-0">Link Event</label>
                                    <span onClick={() => addLink(i)} className="item-category" style={{ marginRight: '0', padding: '5px', fontSize: '12px' }}>
                                       <svg style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z" /><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11H7v2h4v4h2v-4h4v-2h-4V7h-2v4z" fill="#6c757d " /></svg> Tambah Link
                                    </span>
                                 </div>
                                 {
                                    s.links.map((link, key) => {
                                       return (
                                          <div className="card dash py-2 px-2 mb-3" key={key}>
                                             <button onClick={() => deleteLink(i, key)} className="btn btn-sm btn-danger btn-floating-right" type="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none" d="M0 0h24v24H0z" /><path d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm6.414 8l1.768-1.768-1.414-1.414L12 12.586l-1.768-1.768-1.414 1.414L10.586 14l-1.768 1.768 1.414 1.414L12 15.414l1.768 1.768 1.414-1.414L13.414 14zM9 4v2h6V4H9z" fill="rgba(255,255,255,1)" /></svg>
                                             </button>
                                             <div className="row mt-1">
                                                <div className="col-12">
                                                   <div className="form-group mb-0">
                                                      <label className="text-small">Label - {key + 1}</label>
                                                      <input type="text" className="form-control form-control-sm" name="label" defaultValue={link.label} placeholder="zoom / youtube / google meet / yang lain" onChange={(e) => handleChangeLink(e, i, key)} required />
                                                   </div>
                                                </div>
                                             </div>
                                             <div className="row mt-1">
                                                <div className="col-12">
                                                   <div className="form-group mb-0">
                                                      <label className="text-small">Link Event - {key + 1}</label>
                                                      <input type="text" className="form-control form-control-sm" name="url" defaultValue={link.url} onChange={(e) => handleChangeLink(e, i, key)} required />
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       )
                                    })
                                 }
                              </>
                           )
                        }
                     </>
                  )
               }
            </div>
         )
      })
   )
}

export default InputSchedule