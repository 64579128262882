import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import BaseApp from '../components/BaseApp'
import Rupiah from '../components/Rupiah';

function Report() {

   const [data, setData] = useState({ order: 0, orderComplete: 0, orderPending: 0, transaction: 0, income: 0, withdrawal: 0, eventActive: 0, eventInactive: 0, visitor: 0 });

   useEffect(() => {
      const getData = async () => {
         await axios.get('/backoffice/report')
            .then(resp => setData(resp.data))
            .catch(error => console.log(error));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <div className="row mb-4">
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.order}</h3>
                     <span>Total Pembelian</span>
                  </div>
                  <div>
                     <span><i className="bi bi-cart-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.orderComplete}</h3>
                     <span>Total Pembelian Selesai</span>
                  </div>
                  <div>
                     <span><i className="bi bi-cart-check-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.orderPending}</h3>
                     <span>Total Pembelian Pending</span>
                  </div>
                  <div>
                     <span><i className="bi bi-cart-x-fill"></i></span>
                  </div>
               </div>
            </div>
         </div>
         <div className="row mb-4">
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>Rp. <Rupiah value={data.transaction} /></h3>
                     <span>Total Transaksi</span>
                  </div>
                  <div>
                     <span><i className="bi bi-cash-stack"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>Rp. <Rupiah value={data.income} /></h3>
                     <span>Total Pendapatan</span>
                  </div>
                  <div>
                     <span><i className="bi bi-cash"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>Rp. <Rupiah value={data.withdrawal} /></h3>
                     <span>Total Penarikan Dana</span>
                  </div>
                  <div>
                     <span><i className="bi bi-arrow-left-right"></i></span>
                  </div>
               </div>
            </div>
         </div>
         <div className="row mb-4">
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.eventActive}</h3>
                     <span>Total Event Aktif</span>
                  </div>
                  <div>
                     <span><i className="bi bi-bookmark-check-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.eventInactive}</h3>
                     <span>Total Event Nonaktif</span>
                  </div>
                  <div>
                     <span><i className="bi bi-bookmark-x-fill"></i></span>
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
               <div className="card-single">
                  <div>
                     <h3>{data.visitor}</h3>
                     <span>Total Pengunjung</span>
                  </div>
                  <div>
                     <span><i className="bi bi-hand-index-thumb-fill"></i></span>
                  </div>
               </div>
            </div>
         </div>
         <div className="row mt-5">
            <div className="col-12 col-md-6">
               <NavLink to="/report/event" className="card-single">
                  <div>
                     <h3>Report Detail Event</h3>
                  </div>
                  <div>
                     <span><i className="bi bi-file-earmark-medical"></i></span>
                  </div>
               </NavLink>
            </div>
            <div className="col-12 col-md-6">
               <NavLink to="/report/sales" className="card-single">
                  <div>
                     <h3>Report Detail Penjualan</h3>
                  </div>
                  <div>
                     <span><i className="bi bi-cart4"></i></span>
                  </div>
               </NavLink>
            </div>
         </div>
      </BaseApp>
   )
}

export default Report
