import React, { useState, useEffect } from 'react'
import BaseApp from '../BaseApp';
import { Table, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import { NavLink } from "react-router-dom";

function WaitingEvent() {

   const [events, setEvents] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/event/status/request')
            .then(resp => {
               // console.table(resp.data);
               setEvents(resp.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <input className="form-control" type="text" />
            </div>
         </div>
         <div className="block mt-3">
            <div className="block-header">
               <h5 className="block-title mb-0">Waiting Event</h5>
               <div className="block-options">
                  <select className="form-control form-control-sm">
                     <option defaultValue="">-</option>
                  </select>
               </div>
            </div>
            <div className="block-content">

               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <Table bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              <th>Title Event</th>
                              <th>Created By</th>
                              <th>Status</th>
                              <th>Created At</th>
                              <th className="text-center" style={{ width: '200px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {events.length === 0 && (
                              <tr>
                                 <td colSpan="5" className="text-center">Belum ada Event dengan status Waiting</td>
                              </tr>
                           )}
                           {
                              events.map((e, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{e.title}</td>
                                       <td>{e.author.name}</td>
                                       <td className="text-center">
                                          <span className={`badge ${e.status === "request" ? "badge-warning" : ''} ${e.status === "approve" ? "badge-success" : ''} ${e.status === "reject" ? "badge-danger" : ''} ${e.status === "draft" ? "badge-info" : ''} `}>{e.status}</span>
                                       </td>
                                       <td>{moment(e.createdAt).format('LLLL')}</td>
                                       <td className="text-center">
                                          <NavLink to={`/event/participant/${e._id}`} className="btn btn-sm btn-secondary text-white mr-2">Participants</NavLink>
                                          <NavLink to={`/event/${e.slug}`} className="btn btn-sm btn-dark text-white">Detail</NavLink>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>
      </BaseApp>
   )
}

export default WaitingEvent
