import React, { useEffect, useState } from 'react'
import BaseApp from '../components/BaseApp';
import { Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import { NavLink, useRouteMatch, Switch, Route } from "react-router-dom";
import Rupiah from '../components/Rupiah';
import AllOrder from '../components/order/AllOrder';
import WaitingOrder from '../components/order/WaitingOrder';
import CompleteOrder from '../components/order/CompleteOrder';
import ExpiredOrder from '../components/order/ExpiredOrder';
import DetailOrder from '../components/order/DetailOrder';


function Order() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   moment.locale('id');
   let { url, path } = useRouteMatch();
   const [summary, setSummary] = useState({ waiting: 0, complete: 0, expired: 0 });
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/order')
            .then(resp => {
               // console.log(resp.data);
               setSummary(resp.data.summary);
               setData(resp.data.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <Row>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`${url}/all`} className="card-single">
                        <div>
                           <h1>{summary.waiting + summary.complete + summary.expired}</h1>
                           <span>All Transaction</span>
                        </div>
                        <div>
                           <span className="text-info"><i className="bi bi-list-ul" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`${url}/waiting`} className="card-single">
                        <div>
                           <h1>{summary.waiting}</h1>
                           <span>Waiting Payment</span>
                        </div>
                        <div>
                           <span className="text-warning"><i className="bi bi-dash-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`${url}/complete`} className="card-single">
                        <div>
                           <h1>{summary.complete}</h1>
                           <span>Complate Order</span>
                        </div>
                        <div>
                           <span className="text-success"><i className="bi bi-check-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <NavLink to={`${url}/expired`} className="card-single">
                        <div>
                           <h1>{summary.expired}</h1>
                           <span>Expired Order</span>
                        </div>
                        <div>
                           <span className="text-danger"><i className="bi bi-x-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
         </Row>

         <Switch>
            <Route exact path={path}>
               <div className="block mt-4">
                  <div className="block-header">
                     <h5 className="block-title mb-0">Last Transaction Order</h5>
                     <div className="block-options">
                        <NavLink to={`${url}/all`} className="btn btn-sm btn-info text-white">See All</NavLink>
                     </div>
                  </div>
                  <div className="block-content">

                     {
                        isLoading && (
                           <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                              <div className="loading-title"></div>
                              <div className="loading-title-small mt-2"></div>
                              <div className="loading-title mt-3"></div>
                              <div className="loading-title-small mt-2"></div>
                              <div className="loading-animation-wrapper">
                                 <div className="loading-animation"></div>
                              </div>
                           </div>
                        )
                     }

                     {
                        !isLoading && (
                           <Table bordered>
                              <thead>
                                 <tr>
                                    <th style={{ width: '25px' }}>#</th>
                                    <th>Invoice</th>
                                    <th>Issued By</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                    <th className="text-center" style={{ width: '150px' }}>Actions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {data.length === 0 && (
                                    <tr>
                                       <td colSpan="7" className="text-center">Belum ada Order</td>
                                    </tr>
                                 )}
                                 {
                                    data.map((o, i) => {
                                       return (
                                          <tr key={i}>
                                             <td>{i + 1}</td>
                                             <td>{o.oid}</td>
                                             <td>{o.contact.name}</td>
                                             <td>Rp. <Rupiah value={o.payment.total} /></td>
                                             <td className="text-center">
                                                <span className={`badge ${o.status === "pending" ? "badge-warning" : ''} ${o.status === "complete" ? "badge-success" : ''} ${o.status === "expired" ? "badge-danger" : ''} ${o.status === "payment_verification" ? "badge-danger" : ''} `}>{o.status}</span>
                                             </td>
                                             <td>{moment(o.createdAt).format('LLLL')}</td>
                                             <td className="text-center">
                                                <NavLink to={`/order/${o._id}/detail`} className="btn btn-sm btn-dark text-white">Detail Order</NavLink>
                                             </td>
                                          </tr>
                                       )
                                    })
                                 }
                              </tbody>
                           </Table>
                        )
                     }
                  </div>
               </div>
            </Route>
            <Route exact path={`${path}/all`}>
               <AllOrder />
            </Route>
            <Route exact path={`${path}/waiting`}>
               <WaitingOrder />
            </Route>
            <Route exact path={`${path}/complete`}>
               <CompleteOrder />
            </Route>
            <Route exact path={`${path}/expired`}>
               <ExpiredOrder />
            </Route>
            <Route exact path={`${path}/:id/detail`}>
               <DetailOrder />
            </Route>
         </Switch>


      </BaseApp>
   )
}

export default Order
