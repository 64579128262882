import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { NavLink } from "react-router-dom";
import Rupiah from '../Rupiah';

function AllOrder() {

   const [order, setOrder] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/order')
            .then(resp => {
               // console.log(resp.data);
               setOrder(resp.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <div className="block mt-4">
         <div className="block-header">
            <h5 className="block-title mb-0">All Transaction</h5>
            <div className="block-options">
               Filter
            </div>
         </div>
         <div className="block-content">
            {
               isLoading && (
                  <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                     <div className="loading-title"></div>
                     <div className="loading-title-small mt-2"></div>
                     <div className="loading-title mt-3"></div>
                     <div className="loading-title-small mt-2"></div>
                     <div className="loading-animation-wrapper">
                        <div className="loading-animation"></div>
                     </div>
                  </div>
               )
            }

            {
               !isLoading && (
                  <Table bordered>
                     <thead>
                        <tr>
                           <th style={{ width: '25px' }}>#</th>
                           <th>Invoice</th>
                           <th>Issued By</th>
                           <th>Amount</th>
                           <th>Status</th>
                           <th>Created At</th>
                           <th className="text-center" style={{ width: '150px' }}>Actions</th>
                        </tr>
                     </thead>
                     <tbody>
                        {order.length === 0 && (
                           <tr>
                              <td colSpan="5" className="text-center">Belum ada Order</td>
                           </tr>
                        )}
                        {
                           order.map((o, i) => {
                              return (
                                 <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{o.oid}</td>
                                    <td>{o.contact.name}</td>
                                    <td>Rp. <Rupiah value={o.payment.total} /></td>
                                    <td className="text-center">
                                       <span className={`badge ${o.status === "pending" ? "badge-warning" : ''} ${o.status === "complete" ? "badge-success" : ''} ${o.status === "expired" ? "badge-danger" : ''} ${o.status === "payment_verification" ? "badge-danger" : ''}`}>{o.status}</span>
                                    </td>
                                    <td>{moment(o.createdAt).format('LLLL')}</td>
                                    <td className="text-center">
                                       <NavLink to={`/order/${o._id}/detail`} className="btn btn-sm btn-dark text-white">Detail Order</NavLink>
                                    </td>
                                 </tr>
                              )
                           })
                        }
                     </tbody>
                  </Table>
               )
            }
         </div>
      </div>
   )
}

export default AllOrder
