import React, { useState, useEffect } from 'react';
import { Table, Spinner, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

function Account() {

   moment.locale('id');
   const [account, setAccount] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [editModal, setEditModal] = useState(false);
   const [dataEdit, setDataEdit] = useState(null);
   const [idEdit, setIdEdit] = useState(null);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/user')
            .then(resp => {
               setAccount(resp.data);
               setIsLoading(false);
            })
      }
      getData();
   }, []);

   const verifyAccount = (id) => {
      Swal.fire({
         title: 'Verify this Account?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.put('/user/verify/' + id, { isVerified: true })
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'User Verified!',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  document.location.reload();
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const unverifyAccount = (id) => {
      Swal.fire({
         title: 'Unverify this Account?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.put('/user/' + id, { isVerified: false })
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'User Unverified!',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  document.location.reload();
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   const verifyTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
         Verify Account
      </Tooltip>
   );

   const unverifyTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
         Unverify Account
      </Tooltip>
   );

   const onSubmitUpdate = (e) => {
      e.preventDefault();
      if (idEdit !== null) {
         axios.put('/user/' + idEdit, { ...dataEdit })
            .then(resp => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'User Edited!',
                  showConfirmButton: false,
                  timer: 1500
               });
               document.location.reload();
            })
            .catch(err => {
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Error',
                  text: err.mesage,
                  showConfirmButton: false,
                  timer: 1500
               });
               console.log(err)
            });
      } else {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'ID User Null',
            showConfirmButton: false,
            timer: 1500
         });
      }

   }

   const managePayment = (a) => {
      if (a.isManagePayment !== undefined && a.isManagePayment === true) return <span className="badge badge-success"> <i className="bi bi-check-circle-fill" /> Yes</span>
      return <span className="badge badge-danger"> <i className="bi bi-x-circle-fill" /> No</span>
   }


   return (
      <div className="block mt-4">
         <div className="block-header">
            <h5 className="block-title mb-0">All Account Tiketseminar</h5>
         </div>
         <div className="block-content">
            <div className="form-group">
               <input type="text" className="form-control" placeholder="Search name here" />
            </div>
            <Table bordered>
               <thead>
                  <tr>
                     <th style={{ width: '25px' }}>#</th>
                     <th>Name</th>
                     <th>Username</th>
                     <th>Email</th>
                     <th>Status</th>
                     <th>Manage Payment</th>
                     <th>Created At</th>
                     <th className="text-center" style={{ width: '200px' }}>Actions</th>
                  </tr>
               </thead>
               <tbody>
                  {
                     isLoading && (
                        <tr>
                           <td colSpan="8" className="text-center">
                              <Spinner animation="border" />
                           </td>
                        </tr>
                     )
                  }

                  {
                     !isLoading && (
                        <>
                           {
                              account.length === 0 && (
                                 <tr>
                                    <td colSpan="8" className="text-center">Belum ada Account</td>
                                 </tr>
                              )
                           }

                           {
                              account.map((a, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{a.name}</td>
                                       <td>{a.username !== "" ? a.username : '-'}</td>
                                       <td>{a.email}</td>
                                       <td className="text-center">
                                          {a.isVerified && <span className="badge badge-success"> <i className="bi bi-check-circle-fill" /> Verified</span>}
                                          {!a.isVerified && <span className="badge badge-danger"> <i className="bi bi-exclamation-circle-fill" /> Unverified</span>}
                                       </td>
                                       <td className="text-center">
                                          {managePayment(a)}
                                       </td>
                                       <td>{moment(a.createdAt).format('LLLL')}</td>
                                       <td className="text-center">
                                          {!a.isVerified && (
                                             <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={verifyTooltip}
                                             >
                                                <button onClick={() => verifyAccount(a._id)} className="btn btn-sm btn-success"><i className="bi bi-check-circle-fill" /></button>
                                             </OverlayTrigger>
                                          )}
                                          {a.isVerified && (
                                             <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={unverifyTooltip}
                                             >
                                                <button onClick={() => unverifyAccount(a._id)} className="btn btn-sm btn-danger"><i className="bi bi-x-circle-fill" /></button>
                                             </OverlayTrigger>

                                          )}
                                          <button className="btn btn-sm btn-info ml-2" onClick={() => {
                                             setDataEdit({ name: a.name, username: a.username, email: a.email, isManagePayment: a.isManagePayment ? a.isManagePayment : false });
                                             setIdEdit(a._id);
                                             setEditModal(true);
                                          }}><i className="bi bi-pencil" /></button>
                                          <NavLink to={`/account/${a._id}`} className="btn btn-sm btn-dark text-white ml-2">Detail</NavLink>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </>
                     )
                  }


               </tbody>
            </Table>
         </div>

         <Modal show={editModal} onHide={() => setEditModal(false)}>
            <form onSubmit={onSubmitUpdate}>
               <Modal.Header closeButton>
                  <Modal.Title>Edit Account</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {dataEdit !== null && (
                     <>
                        <div className="form-group">
                           <label>Name</label>
                           <input type="text" className="form-control" onChange={(e) => setDataEdit({ ...dataEdit, name: e.target.value })} value={dataEdit.name} />
                        </div>
                        <div className="form-group">
                           <label>Username</label>
                           <input type="text" className="form-control" onChange={(e) => setDataEdit({ ...dataEdit, username: e.target.value })} value={dataEdit.username} />
                        </div>
                        <div className="form-group">
                           <label>Email</label>
                           <input type="email" className="form-control" onChange={(e) => setDataEdit({ ...dataEdit, email: e.target.value })} value={dataEdit.email} />
                        </div>
                        <div className="form-group">
                           <label>Manage Payment</label>
                           <select className="form-control" value={dataEdit.isManagePayment ? true : false} onChange={(e) => setDataEdit({ ...dataEdit, isManagePayment: e.target.value === "false" ? false : true })}>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                           </select>
                        </div>
                     </>
                  )}
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setEditModal(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Update
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </div>
   )
}

export default Account
