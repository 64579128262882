import firebase from 'firebase/app';
import 'firebase/storage';

const firebaseConfig = {
   apiKey: "AIzaSyByITsc95iYY7ikAY9gCio0oQMh6bG2LSk",
   authDomain: "devruangkuliah-c9e45.firebaseapp.com",
   projectId: "devruangkuliah-c9e45",
   storageBucket: "devruangkuliah-c9e45.appspot.com",
   messagingSenderId: "478828270271",
   appId: "1:478828270271:web:388eb8105615b95f826c0a"
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export default storage;