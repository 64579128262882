import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import storage from '../../config/firebase';
import axios from 'axios';

function Banner() {

   const [updated, setUpdated] = useState(true);
   const [banners, setBanners] = useState([]);
   const [showBanner, setShowBanner] = useState(false);
   const [image, setImage] = useState("");
   const [previewImage, setPreviewImage] = useState("");
   const [isLoading, setIsLoading] = useState(true);

   let APP_URL;
   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         APP_URL = "https://tiketseminar.com/";
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
         break;
      case 'sandbox':
         APP_URL = "https://sandbox.tiketseminar.com/";
         break;
      default:
         APP_URL = "http://localhost:3000/";
         break;
   }


   useEffect(() => {
      const getData = async () => {
         return await axios.get('/banner')
            .then(resp => {
               // console.log(resp.data);
               setBanners(resp.data);
               setIsLoading(false);
            }).catch(error => {
               console.log(error);
            })
      }
      getData();
   }, [updated]);

   const handleCloseBanner = () => {
      setShowBanner(false);
      reset();
   };
   const handleShowBanner = () => {
      setShowBanner(true)
      reset();
   };

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      const AcceptAbleExtention = ["png", "jpeg", "jpg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            setImage(resp.data.code)

         }).catch(error => {
            console.log(error.response)
         })

      let image = URL.createObjectURL(file);
      setPreviewImage(image);
   }

   const reset = () => {
      setImage("");
      setPreviewImage("");
   }

   const handleSubmit = (e) => {
      e.preventDefault();

      Swal.fire({
         title: 'Mohon Tunggu Sebentar',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });

      axios.post('/banner', { url: image })
         .then(resp => {
            setUpdated(!updated);
            reset();
            setShowBanner(false);
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Banner Created',
               showConfirmButton: false,
               timer: 1500
            });
         })
         .catch(err => {
            console.log(err)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Error',
               text: err.mesage,
               showConfirmButton: false,
               timer: 1500
            });
            setShowBanner(false);
         });
   }


   const handleDelete = (id) => {
      Swal.fire({
         title: 'Delete this Banner?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.delete('/banner/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Banner Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdated(!updated);
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }


   return (
      <>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Banner</h5>
               <div className="block-options">
                  <Button onClick={handleShowBanner} size="sm" variant="success"><i className="bi bi-plus-circle-fill text-white mr-2"></i> Add</Button>
               </div>
            </div>
            <div className="block-content">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     banners.map((b, i) => {
                        return (
                           <div className="card mx-2 my-3 py-1 px-1" key={i}>
                              <button onClick={() => handleDelete(b._id)} className="btn btn-sm btn-danger btn-floating-right" type="button">
                                 <i className="bi bi-trash-fill"></i>
                              </button>
                              <img src={b.url.includes('firebase') ? b.url : process.env.REACT_APP_CDN_URL + '/get/' + b.url} alt="" />
                           </div>
                        )
                     })
                  )
               }
            </div>
         </div>


         <Modal show={showBanner} onHide={handleCloseBanner}>
            <form onSubmit={handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title>Upload Banner</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <input type="file" className="form-control" onChange={(e) => handleImageChange(e)} required />
                     <small className="text-small">Pastikan resolusi gambar 1080 x 240 px</small>
                  </div>
                  {
                     (previewImage !== "" && <div className="form-group mt-2">
                        <img src={previewImage} alt="" style={{ width: '100%' }} />
                     </div>)
                  }
               </Modal.Body>
               <Modal.Footer>
                  <Button variant="secondary" size="sm" onClick={handleCloseBanner}>
                     Close
                  </Button>
                  <Button variant="primary" size="sm" type="submit">
                     Upload
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>

      </>
   )
}

export default Banner
