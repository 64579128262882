import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

function BaseApp({ children }) {
   return (
      <>
         <Sidebar />
         <div className="main-content">
            <Header />
            <main>
               {children}
            </main>
         </div>
      </>
   )
}

export default BaseApp
