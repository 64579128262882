import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';


function Category() {

   const [showCategory, setShowCategory] = useState(false);
   const [name, setName] = useState("");
   const [icon, setIcon] = useState("");
   const [previewIcon, setPreviewIcon] = useState("");
   const [category, setCategory] = useState([]);
   const [updated, setUpdate] = useState(false);
   const [isLoading, setIsLoading] = useState(true);

   let APP_URL;
   let CDN_TOKEN = "d0594aeb-628e-42d6-bbea-c305780d3fc2";
   switch (process.env.REACT_APP_ENV) {
      case 'production':
         APP_URL = "https://tiketseminar.com/";
         CDN_TOKEN = "9ad598fc-eb24-43fe-aa3a-cef92fcfa227";
         break;
      case 'sandbox':
         APP_URL = "https://sandbox.tiketseminar.com/";
         break;
      default:
         APP_URL = "http://localhost:3000/";
         break;
   }

   const handleCloseCategory = () => {
      setShowCategory(false);
      reset();
   };
   const handleShowCategory = () => {
      setShowCategory(true);
      reset();
   }

   const reset = () => {
      setName("");
      setIcon("");
      setPreviewIcon("");
   }

   const handleIconChange = (event) => {

      const file = event.target.files[0];
      const AcceptAbleExtention = ["png", "jpeg", "jpg", "svg"];
      let extention = file.name.split(".").pop();

      if (!AcceptAbleExtention.includes(extention.toLowerCase())) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Upload Gambar dengan Ekstensi File JPG/PNG/JPEG',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }
      if (file.size > 1048576) {
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Validation',
            text: 'Maksimal File 1 Mb',
            showConfirmButton: false,
            timer: 2500
         });
         return;
      }

      const formData = new FormData();
      formData.append('file', file);
      axios.post(process.env.REACT_APP_CDN_URL + '/upload', formData, {
         headers: {
            Authorization: 'Bearer ' + CDN_TOKEN
         }
      })
         .then(resp => {
            console.log(resp.data)
            setIcon(resp.data.code)

         }).catch(error => {
            console.log(error.response)
         })

      let icon = URL.createObjectURL(file);
      setPreviewIcon(icon);
      setIcon(file);
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      Swal.fire({
         title: 'Please Waiting..',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      });

      axios.post('/category', { name, icon })
         .then(resp => {
            setUpdate(!updated);
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Category Created',
               showConfirmButton: false,
               timer: 1500
            });
            setShowCategory(false);
         })
         .catch(err => {
            console.log(err)
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Error',
               text: err.mesage,
               showConfirmButton: false,
               timer: 1500
            });
            setShowCategory(false);
         });

   }

   const handleDelete = (id) => {
      Swal.fire({
         title: 'Delete this Category?',
         showCancelButton: true,
         confirmButtonText: `Yes`,
      }).then((result) => {
         if (result.isConfirmed) {
            Swal.fire({
               title: 'Please Waiting..',
               allowOutsideClick: false,
               didOpen: () => {
                  Swal.showLoading()
               }
            });
            axios.delete('/category/' + id)
               .then(resp => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'success',
                     title: 'Category Deleted',
                     showConfirmButton: false,
                     timer: 1500
                  });
                  setUpdate(!updated);
               })
               .catch(err => {
                  Swal.fire({
                     position: 'top-end',
                     icon: 'error',
                     title: 'Error',
                     text: err.mesage,
                     showConfirmButton: false,
                     timer: 1500
                  });
                  console.log(err)
               });
         } else {
            Swal.fire('Canceled', '', 'info')
         }
      })
   }

   useEffect(() => {
      const getCategory = async () => {
         return await axios.get('/category')
            .then(resp => {
               setCategory(resp.data);
               setIsLoading(false);
            })
            .catch(err => console.log(err));
      }
      getCategory();
   }, [updated])

   return (
      <>
         <div className="block mt-4">
            <div className="block-header">
               <h5 className="block-title mb-0">Category</h5>
               <div className="block-options">
                  <Button onClick={handleShowCategory} size="sm" variant="success"><i className="bi bi-plus-circle-fill text-white mr-2"></i> Add</Button>
               </div>
            </div>
            <div className="block-content">
               <div className="d-flex flex-wrap">

                  {
                     isLoading && (
                        <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                           <div className="loading-title"></div>
                           <div className="loading-title-small mt-2"></div>
                           <div className="loading-title mt-3"></div>
                           <div className="loading-title-small mt-2"></div>
                           <div className="loading-animation-wrapper">
                              <div className="loading-animation"></div>
                           </div>
                        </div>
                     )
                  }

                  {
                     category.map((c, i) => {
                        return <div className="item-category" style={{ position: 'relative', paddingRight: '25px', marginRight: '20px', marginBottom: '20px' }} key={i}>
                           <button onClick={() => handleDelete(c._id)} className="btn btn-sm btn-danger btn-floating-right" type="button">
                              <i className="bi bi-trash-fill"></i>
                           </button>
                           {
                              c.icon !== "" && (
                                 <div className="icon-category">
                                    <img className="fade" src={c.icon} title="" alt="icon-Kategori" />
                                 </div>
                              )
                           }
                           <span>{c.name}</span>
                        </div>
                     })
                  }
               </div>
            </div>
         </div>

         <Modal show={showCategory} onHide={handleCloseCategory}>
            <form onSubmit={handleSubmit}>
               <Modal.Header closeButton>
                  <Modal.Title>Upload Category</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label className="text-small">Nama Category</label>
                     <input type="text" name={name} onChange={(e) => setName(e.target.value)} className="form-control form-control-sm" required />
                  </div>
                  <div className="form-group">
                     <label className="text-small">Icon Category</label>
                     <input type="file" className="form-control form-control-sm" onChange={(e) => handleIconChange(e)} />
                  </div>
                  {
                     (previewIcon !== "" && <div className="form-group mt-2">
                        <img src={previewIcon} alt="" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
                     </div>)
                  }
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={handleCloseCategory}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </>
   )
}

export default Category
