import React, { useState, useEffect } from 'react'
import BaseApp from '../BaseApp'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';

function Edit() {
   let { id } = useParams();
   const [data, setData] = useState([]);
   const [event, setEvent] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      function getData(id) {
         axios.get('/voucher/' + id)
            .then(resp => {
               setData(resp.data);
               setIsLoading(false);
            })
            .catch(error => console.log(error));
      }

      function getEvent() {
         axios.get('/event')
            .then(resp => {
               setEvent(resp.data);
            })
            .catch(error => console.log(error));
      }
      getData(id);
      getEvent();
   }, [id]);

   const handelChange = (e) => {
      const { name, value } = e.target;
      if (name === "isActive") {
         setData({ ...data, [name]: value === "true" ? true : false })
      } else {
         setData({ ...data, [name]: value })
      }
   }

   const update = () => {
      Swal.fire({
         title: 'Loading',
         allowOutsideClick: false,
         didOpen: () => {
            Swal.showLoading()
         }
      })

      axios.put('/voucher/' + id, data)
         .then(response => {
            Swal.fire({
               position: 'top-end',
               icon: 'success',
               title: 'Code Updated',
               showConfirmButton: false,
               timer: 1500
            });
            window.history.back();
         }).catch(error => {
            console.log(error);
            Swal.fire({
               position: 'top-end',
               icon: 'error',
               title: 'Error!',
               showConfirmButton: false,
               timer: 1500
            });
         })
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between mb-3">
                  <h3 className="mb-0">Edit Event</h3>
                  <div>
                     <button className="btn btn-sm btn-secondary mr-2">Kembali</button>
                     <button onClick={update} className="btn btn-sm btn-success">Update Code</button>
                  </div>
               </div>
               {!isLoading && (
                  <div className="row mt-3">
                     <div className="col-12 col-md-6">
                        <div className="form-group">
                           <label>Event</label>
                           <select name='event' className="form-control form-control-sm" onChange={(e) => handelChange(e)} defaultValue={data.event}>
                              {event.map((e, i) => {
                                 return <option value={e._id} key={i}>{e.title}</option>
                              })}
                           </select>
                        </div>
                        <div className="form-group">
                           <label>Nama Kode Voucher/Promo</label>
                           <input name='code' className='form-control form-control-sm' onChange={(e) => handelChange(e)} defaultValue={data.code} />
                        </div>
                        <div className="form-group">
                           <label>Deskripsi</label>
                           <input name='desc' className='form-control form-control-sm' onChange={(e) => handelChange(e)} defaultValue={data.desc} />
                        </div>
                        <div className="form-group">
                           <label>Tipe Voucher</label>
                           <select name='type' className="form-control form-control-sm" onChange={(e) => handelChange(e)} defaultValue={data.type}>
                              <option value="percent">Presentase %</option>
                              <option value="nominal">Nominal Potongan</option>
                           </select>
                        </div>
                     </div>
                     <div className="col-12 col-md-6">
                        <div className='row'>
                           <div className='col-6'>
                              <div className="form-group">
                                 <label>Nominal / Persen</label>
                                 <input name='value' className='form-control form-control-sm' onChange={(e) => handelChange(e)} defaultValue={data.value} />
                              </div>
                           </div>
                           <div className='col-6'>
                              <div className="form-group">
                                 <label>Minimal Pembelian</label>
                                 <input name='requirement' className='form-control form-control-sm' onChange={(e) => handelChange(e)} defaultValue={data.requirement} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group">
                           <label>Periode</label>
                           <select name='period' className="form-control form-control-sm" onChange={(e) => handelChange(e)} defaultValue={data.period}>
                              <option value="lifetime">Tanpa Batas Waktu (lifetime)</option>
                              <option value="range">Waktu Tertentu (range)</option>
                           </select>
                        </div>
                        {data.period === "range" && (
                           <div className='row'>
                              <div className='col-6'>
                                 <div className="form-group">
                                    <label>Waktu Mulai</label>
                                    <input name='start' type="datetime-local" onChange={(e) => handelChange(e)} className='form-control form-control-sm' defaultValue={moment(data.start).format("yyyy-MM-DDThh:mm")} />
                                 </div>
                              </div>
                              <div className='col-6'>
                                 <div className="form-group">
                                    <label>Waktu Selesai</label>
                                    <input name='end' type="datetime-local" onChange={(e) => handelChange(e)} className='form-control form-control-sm' defaultValue={moment(data.end).format("yyyy-MM-DDThh:mm")} />
                                 </div>
                              </div>
                           </div>
                        )}
                        <div className='row'>
                           <div className='col-6'>
                              <div className="form-group">
                                 <label>Kuota</label>
                                 <input name='quota' className='form-control form-control-sm' onChange={(e) => handelChange(e)} defaultValue={data.quota} />
                              </div>
                           </div>
                           <div className='col-6'>
                              <div className="form-group">
                                 <label>Kode Aktif</label>
                                 <select name='isActive' className="form-control form-control-sm" onChange={(e) => handelChange(e)} defaultValue={data.isActive}>
                                    <option value={true}>Ya</option>
                                    <option value={false}>Tidak</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </div>
      </BaseApp>
   )
}

export default Edit
