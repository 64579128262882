import React, { useEffect, useState } from 'react';
import BaseApp from '../BaseApp';
import { Button, Row, Col, Table } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment/min/moment-with-locales';
import { NavLink, useRouteMatch } from "react-router-dom";

function Event() {

   const token = localStorage.getItem('token');
   if (token === undefined || token === null || token === "") {
      document.location = "/login";
   }

   moment.locale('id');
   let { url } = useRouteMatch();
   const [summary, setSummary] = useState({});
   const [data, setData] = useState({});
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const getData = async () => {
         return await axios.get('/backoffice/report/event')
            .then(resp => {
               console.log(resp.data);
               setSummary(resp.data.summary);
               setData(resp.data.data);
               setIsLoading(false);
            }).catch(err => console.log(err));
      }
      getData();
   }, [])

   return (
      <BaseApp>
         <Row>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`/event/draft`} className="card-single">
                        <div>
                           <h1>{summary.draft}</h1>
                           <span>Draft Event</span>
                        </div>
                        <div>
                           <span className="text-info"><i className="bi bi-bookmark-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`/event/waiting`} className="card-single">
                        <div>
                           <h1>{summary.waiting}</h1>
                           <span>Waiting Event</span>
                        </div>
                        <div>
                           <span className="text-warning"><i className="bi bi-dash-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <NavLink to={`/event/approve`} className="card-single">
                        <div>
                           <h1>{summary.approve}</h1>
                           <span>Approved Event</span>
                        </div>
                        <div>
                           <span className="text-success"><i className="bi bi-check-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
            <Col className="mb-2">
               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '70px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-3"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }
               {
                  !isLoading && (
                     <NavLink to={`/event/reject`} className="card-single">
                        <div>
                           <h1>{summary.reject}</h1>
                           <span>Rejected Event</span>
                        </div>
                        <div>
                           <span className="text-danger"><i className="bi bi-x-circle-fill" /></span>
                        </div>
                     </NavLink>
                  )
               }
            </Col>
         </Row>
         <div className="block mt-4">
            <div className="block-content">

               {
                  isLoading && (
                     <div className="loading" style={{ width: '100%', padding: '10px', height: '100px' }}>
                        <div className="loading-title"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-title mt-3"></div>
                        <div className="loading-title-small mt-2"></div>
                        <div className="loading-animation-wrapper">
                           <div className="loading-animation"></div>
                        </div>
                     </div>
                  )
               }

               {
                  !isLoading && (
                     <Table className="table-small" bordered>
                        <thead>
                           <tr>
                              <th style={{ width: '25px' }}>#</th>
                              <th>Judul Event</th>
                              <th className="text-center">Jadwal</th>
                              <th className="text-center">Tiket</th>
                              <th className="text-center">Peserta</th>
                              <th className="text-center">Sisa Kuota</th>
                              <th className="text-center">Pengunjung</th>
                              <th className="text-center">Status</th>
                              <th className="text-center">Publish</th>
                              <th className="text-center" style={{ width: '200px' }}>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                           {data.length === 0 && (
                              <tr>
                                 <td colSpan="5" className="text-center">Belum ada Event</td>
                              </tr>
                           )}
                           {
                              data.map((e, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{i + 1}</td>
                                       <td>{e.title.length > 50 ? e.title.substr(0, 50) + '...' : e.title}</td>
                                       <td className="text-center">{e.totalSchedule} Hari</td>
                                       <td className="text-center">{e.totalTicket} Kategori</td>
                                       <td className="text-center">{e.participant}</td>
                                       <td className="text-center">{e.totalQuota}</td>
                                       <td className="text-center">{e.visitor}</td>
                                       <td className="text-center">
                                          <span className={`badge ${e.status === "request" ? "badge-warning" : ''} ${e.status === "approve" ? "badge-success" : ''} ${e.status === "reject" ? "badge-danger" : ''} ${e.status === "draft" ? "badge-info" : ''} `}>{e.status}</span>
                                       </td>
                                       <td className="text-center">{e.status === "approve" ? (
                                          <>
                                             {e.isPublish ? <span className="badge badge-success">Publish</span> : <span className="badge badge-danger">Arsip</span>}
                                          </>
                                       ) : "-"}</td>
                                       <td className="text-center">
                                          <NavLink to={`/event/participant/${e._id}`} className="btn btn-sm btn-secondary text-white mr-2">Participants</NavLink>
                                          <NavLink to={`/event/${e.slug}`} className="btn btn-sm btn-dark text-white">Detail</NavLink>
                                       </td>
                                    </tr>
                                 )
                              })
                           }
                        </tbody>
                     </Table>
                  )
               }
            </div>
         </div>
      </BaseApp>
   )
}

export default Event
