import React, { useState, useEffect } from 'react'
import BaseApp from '../components/BaseApp';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import List from '../components/certificate/List';
import Participant from '../components/certificate/Participant'
import { Switch, Route, useRouteMatch, NavLink } from "react-router-dom";
import External from '../components/certificate/External';


function Certificate() {
   let { path } = useRouteMatch();
   const [file, setFile] = useState(null);
   const [modalAddCertificate, setModalAddCertificate] = useState(false);
   const [eventId, setEventId] = useState(null);
   const [events, setEvents] = useState([]);
   const [externalEvent, setExternalEvent] = useState(null);
   const [isExternalEvent, setIsExternalEvent] = useState(false)

   useEffect(() => {

      const getEvent = () => {
         return axios.get('/event/status/approve')
            .then(resp => {
               // console.log(resp.data);
               setEvents(resp.data)
            })
            .catch(err => console.log(err.message));
      }
      getEvent();
   }, []);

   const importCertificateList = (e) => {
      e.preventDefault();
      if (file !== null) {
         const formData = new FormData();
         formData.append(
            "file", file, file.name
         );
         if (isExternalEvent) {

            if (externalEvent !== null) {

               axios.post('/certificate/external/' + externalEvent, formData)
                  .then(resp => {
                     setModalAddCertificate(false);
                     Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Upload Success',
                        showConfirmButton: false,
                        timer: 1500
                     });
                     setInterval(() => {
                        window.location.reload();
                     }, 1500);
                  })
                  .catch(err => {
                     Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Upload Error',
                        showConfirmButton: false,
                        timer: 1500
                     })
                  });

            } else {
               Swal.fire({
                  position: 'top-end',
                  icon: 'info',
                  title: 'Input Event Title',
                  showConfirmButton: false,
                  timer: 1500
               })
            }


         } else {

            if (eventId !== null) {

               axios.post('/certificate/' + eventId + '/upload', formData)
                  .then(resp => {
                     setModalAddCertificate(false);
                     Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Upload Success',
                        showConfirmButton: false,
                        timer: 1500
                     });
                     setInterval(() => {
                        window.location.reload();
                     }, 1500);
                  })
                  .catch(err => {
                     Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Upload Error',
                        showConfirmButton: false,
                        timer: 1500
                     })
                  });
            } else {
               Swal.fire({
                  position: 'top-end',
                  icon: 'info',
                  title: 'Select Event',
                  showConfirmButton: false,
                  timer: 1500
               })
            }
         }

      } else {
         Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'File Excel',
            showConfirmButton: false,
            timer: 1500
         })
      }
   }

   return (
      <BaseApp>
         <div className="block">
            <div className="block-content">
               <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Certificate Event</h4>
                  <button onClick={() => setModalAddCertificate(true)} className="btn btn-sm btn-success">Upload Certificate List</button>
               </div>
               <Switch>
                  <Route exact path={path}>
                     <List />
                  </Route>
                  <Route path={`${path}/external/:event`}>
                     <External />
                  </Route>
                  <Route exact path={`${path}/:id`}>
                     <Participant />
                  </Route>
               </Switch>
            </div>
         </div>
         <Modal show={modalAddCertificate} onHide={() => setModalAddCertificate(false)}>
            <form onSubmit={importCertificateList}>
               <Modal.Header closeButton>
                  <Modal.Title>Create Certificate List</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <div className="form-group">
                     <label>External Event</label>
                     <div>
                        <div className="form-check form-check-inline">
                           <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="false" onChange={e => setIsExternalEvent(false)} defaultChecked />
                           <label className="form-check-label" htmlFor="inlineRadio1">No</label>
                        </div>
                        <div className="form-check form-check-inline">
                           <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="true" onChange={e => setIsExternalEvent(true)} />
                           <label className="form-check-label" htmlFor="inlineRadio2">Yes</label>
                        </div>
                     </div>
                  </div>
                  {!isExternalEvent && (
                     <div className="form-group">
                        <label>Select Event</label>
                        <select className="form-control" onChange={(e) => setEventId(e.target.value)} required>
                           <option value="">-</option>
                           {
                              events.map((event, i) => {
                                 return <option value={event._id} key={i}>{event.title}</option>
                              })
                           }
                        </select>
                     </div>
                  )}

                  {isExternalEvent && (
                     <div className="form-group">
                        <label>Event Title</label>
                        <input className="form-control form-control-sm" type="text" onChange={e => setExternalEvent(e.target.value)} />
                     </div>
                  )}
                  <div className="form-group">
                     <label>Upload List *excel</label>
                     <input onChange={(e) => setFile(e.target.files[0])} type="file" className="form-control" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" required />
                     <small>Please rename sheet with "data"</small>
                  </div>
               </Modal.Body>
               <Modal.Footer>
                  <Button type="button" variant="secondary" size="sm" onClick={() => setModalAddCertificate(false)}>
                     Close
                  </Button>
                  <Button type="submit" variant="primary" size="sm">
                     Submit
                  </Button>
               </Modal.Footer>
            </form>
         </Modal>
      </BaseApp>
   )
}

export default Certificate
